import { axiosClient } from "./axios";

export const getNotifications = async () => {
  try {
    const response = await axiosClient.get("/notification");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const readNotification = async ({ notificationId }) => {
  try {
    const response = await axiosClient.put(
      `/notification?_id=${notificationId}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const readAllNotifications = async () => {
  try {
    const response = await axiosClient.put(`/notification/read-all`);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const openAllNotifications = async () => {
  try {
    const response = await axiosClient.put(`/notification/open-all`);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
