import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmail as verifyEmailApi } from "../api/user";
import { AuthContext } from "../contexts/UserContext";

export default function EmailVerifictaion() {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    verifyEmailApi({
      token: params.confirmationToken,
      userId: params.userId,
    })
      .then((res) => {
        navigate("/#success");
      })
      .catch((err) => {
        navigate("/#failed");
      });
  }, []);

  return <div>EmailVerifictaion</div>;
}
