import React, { useContext, useEffect, useState } from "react";
import RemainingTime from "../components/dashboard/RemainingTime";
import Todolist from "../components/dashboard/Todolist";
import { AuthContext } from "../contexts/UserContext";
import { checkLogin, deleteUser } from "../api/user";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TodoAnimation from "../components/dashboard/TodoAnimation";
import { getAllTodos as getAllTodosApi } from "../api/todos";
import Message from "../components/Message";
import AddTodoInput from "../components/dashboard/AddTodoInput";
import TodoListMain from "../components/dashboard/TodoListMain";
import BubbleAnimation from "../components/dashboard/BubbleAnimation";
import { motion } from "framer-motion";

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [username, setusername] = useState(null);
  const [loading, setloading] = useState(true);
  const [greeting, setgreeting] = useState("morning");
  const [message, setmessage] = useState("");
  const [completion, setcompletion] = useState(0);
  const [tasks, settasks] = useState([]);
  const [addTaskApiCalled, setaddTaskApiCalled] = useState(false);
  const [completeTasksArray, setcompleteTasksArray] = useState([]);
  const [incompleteTasksArray, setincompleteTasksArray] = useState([]);
  const [showBubbles, setshowBubbles] = useState(false);

  useEffect(() => {
    if (showBubbles) {
      setTimeout(() => {
        setshowBubbles(false);
      }, 5000);
    }
  }, [showBubbles]);

  const getAllTasks = () => {
    getAllTodosApi()
      .then((res) => {
        res.forEach((task) => {
          task.checked = false;
          task.subTasks.forEach((subTask) => {
            subTask.checked = subTask.completed;
          });
        });
        setcompleteTasksArray(res.filter((task) => task.completed == true));
        setincompleteTasksArray(res.filter((task) => task.completed == false));
        settasks(res);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setloading(true);
    checkLogin()
      .then((res) => {
        if (res.plan.plan == null) {
          navigate("/upgrade-plan");
        }
        setusername(res.name.split(" ")[0]);
        setloading(false);
        setCurrentUser(res);
      })
      .catch((err) => {
        setloading(false);
        setCurrentUser(null);
        navigate("/user#signup");
      });
  }, []);

  useEffect(() => {
    const date = new Date();
    const hours = date.getHours();

    if (hours >= 5 && hours <= 11) {
      setgreeting("morning");
    } else if (hours >= 12 && hours <= 16) {
      setgreeting("afternoon");
    } else {
      setgreeting("evening");
    }
  }, []);

  useEffect(() => {
    if (location.hash.includes("#success")) {
      setmessage({ message: "Email verified successfully", success: true });
    } else if (location.hash.includes("#failed")) {
      setmessage({ message: "Email verification failed", success: false });
    }
  }, []);

  return (
    <div>
      {currentUser?.plan.plan == "expired" ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center gap-5"
          style={{ minHeight: "75vh" }}
        >
          <p className="h4 fw-bold text-white w-75 mx-auto">
            Your account is expired, please review your plan now to view and
            edit your data
          </p>
          <Link to="/upgrade-plan" style={{ textDecoration: "none" }}>
            <button className="small updated-btn border-0 h-auto px-3 py-2 d-flex align-items-center gap-2">
              <span className="fw-bold">RENEW NOW</span>
            </button>
          </Link>
        </div>
      ) : (
        <>
          {message && (
            <div>
              <Message message={message} setmessage={setmessage} />
            </div>
          )}
          {showBubbles && <BubbleAnimation />}
          {!loading && (
            <motion.div
              className="position-relative"
              initial={{ opacity: 0, top: "20vh" }}
              animate={{
                opacity: 1,
                top: "0vh",
              }}
              transition={{ duration: 0.3, delay: 0.1 }}
            >
              <div className="px-0 px-md-3 pt-5 text-md-start text-white">
                <p className="text-uppercase text-center text-md-start h4">
                  Good {greeting},
                  <span className="fw-bolder"> {username} !</span>
                </p>
                <p className="dashboard-main-sub-heading">
                  {tasks.length == 0
                    ? "Break your target in easy tasks and add them below..."
                    : completion < 100
                    ? "Have a look at your tasks and smash them..."
                    : "Brilliant ! You have no tasks to perform, want to add more?"}
                </p>
              </div>
              <div className="container d-flex flex-column justify-content-center py-5 position-relative">
                <div className="mb-4">
                  <AddTodoInput
                    setcompleteTasksArray={setcompleteTasksArray}
                    tasks={tasks}
                    setincompleteTasksArray={setincompleteTasksArray}
                    addTaskApiCalled={addTaskApiCalled}
                    setaddTaskApiCalled={setaddTaskApiCalled}
                    settasks={settasks}
                    setmessage={setmessage}
                    getAllTasks={getAllTasks}
                  />
                </div>
                <div className="divider position-absolute"></div>
                <div className="row">
                  <div className="col-12 col-md-6 order-2 order-md-1 todo-wrapper-left">
                    <div>
                      <TodoListMain
                        setcompleteTasksArray={setcompleteTasksArray}
                        setincompleteTasksArray={setincompleteTasksArray}
                        setcompletion={setcompletion}
                        tasks={tasks}
                        settasks={settasks}
                        addTaskApiCalled={addTaskApiCalled}
                        setaddTaskApiCalled={setaddTaskApiCalled}
                        getAllTasks={getAllTasks}
                        completeTasksArray={completeTasksArray}
                        incompleteTasksArray={incompleteTasksArray}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 order-1 order-md-2 mb-5 pb-5 mb-md-0 pb-md-0">
                    <div className="todo-right-wrapper d-flex flex-column align-items-center align-items-md-end justify-content-center mt-5 mt-md-0">
                      <div className="d-flex gap-3">
                        <TodoAnimation
                          completion={completion}
                          totalTasks={tasks.length}
                          showBubbles={showBubbles}
                          setshowBubbles={setshowBubbles}
                        />
                        <RemainingTime />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </>
      )}
    </div>
  );
}
