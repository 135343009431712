import React from "react";

export default function Refund() {
  return (
    <div className="container privacy-wrapper" style={{ textAlign: "justify" }}>
      <h2 className="text-center my-3">REFUND POLICY</h2>
      <div className="row">
        <div className="col-11 col-md-8 mx-auto">
          <div>
            <p className="small">
              Most of the services we offer fall under the services industry. As
              such, we do not offer any returns on any of our services. However,
              we do offer refunds and cancellations based on certain conditions
              if you request for the same within 3 Days of Payment.
            </p>
            <p className="small">
              If you wish to request a cancellation or a refund, please go
              through our Refunds and Cancellations policy carefully. Make sure
              to read this page thoroughly before registering for any of our
              services.
            </p>
          </div>
          <div>
            <p className="small">
              1. We do not offer any refunds for cancellations made after
              registration and payment have been made. However, in special cases
              such as factual errors, duplicate payments, mistaken
              denominations, and other inaccurate payment deductions where the
              payee faces unreasonable loss, we offer refunds.{" "}
            </p>
            <p className="small">
              2. We offer refunds only by facts regarding a specific case
              (between 3-7days of the transaction). We reserve the right to
              refuse refunds for any reason whatsoever, as we deem fit. Refund
              will be credited in your bank account within 5-7 days from the
              date of approval.
            </p>
            <p className="small">
              3. All refunds will only be made to the same account or card from
              which payments were made initially.
            </p>
            <p className="small">
              4. Refunds will only be processed after a thorough verification
              process related to the credentials of the account holder.
            </p>
            <p className="small">
              5. We do not entertain any cheque or cash refunds. We only offer
              online refunds.
            </p>
            <p className="small">
              6. Any extra charges borne by the customer such as bank
              transaction fees, service fees or processing fees will not be
              refunded.
            </p>
            <p className="small">
              7. We do not entertain multiple return requests for the same
              order. Customers requesting for multiple refunds will be blocked
              from accessing our website.
            </p>
            <p className="small">8. We do not pay any interest on refunds.</p>
            <p className="small">
              9. We will not be held responsible if the customer fails to
              receive our communication due to incomplete or incorrect contact
              details.
            </p>
            <p className="small">
              10. We do not entertain refunds for any registrations made during
              discounted or promotional campaigns.
            </p>
            <p className="small">
              11. Customers should produce valid documentation including a valid
              invoice to claim a refund.
            </p>
            <p className="small">
              12. While we have taken utmost care to ensure that our payment
              gateway is secure, we do not guarantee or represent that using our
              services will not result in unauthorized data use or theft of data
              online. We use the services of a third-party payment service
              provider for our online payment gateway.
            </p>
            <p className="small">
              13. We and our payment service provider do not assume any
              liability for any type of damage, monetary or otherwise that a
              customer may suffer due to any delay, interruption, corruption or
              failure of information transmission during payment.
            </p>
            <p className="small">
              14. Chargebacks will be made to the customer only if we fail to
              provide our services for any reason whatsoever after receiving
              payment.
            </p>
          </div>
          <p className="small">
            We reserve the right to update the contents of this Refunds and
            Cancellation policy from time to time without prior notice. Make
            sure to keep updated before you register for any of our services.
          </p>
          <p className="fw-bold" style={{ fontStyle: "italic" }}>
            If you have any questions or doubts related to our Refunds &
            Cancellations, please feel free to get in touch with us at
            hello@finbite.in
          </p>
        </div>
      </div>
    </div>
  );
}
