import { axiosClient } from "./axios";

export const getCoupons = async () => {
  try {
    const res = await axiosClient.get(`/coupon`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const createCoupon = async ({ data }) => {
  try {
    const res = await axiosClient.post(`/coupon`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const verifyCoupon = async ({ name }) => {
  try {
    const res = await axiosClient.post(`/coupon/verify/${name}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateCoupon = async ({ data, id }) => {
  try {
    const res = await axiosClient.put(`/coupon/${id}`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteCoupon = async ({ id }) => {
  try {
    const res = await axiosClient.delete(`/coupon/${id}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};
