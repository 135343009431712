import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import DropdownLight from "../DropdownLight";
import { createMindmap } from "../../api/mindmap";

export default function AddMindmapModal({
  open,
  onCancel,
  folders,
  setfolders,
  setactiveMindmap,
  setaddFolderModalOpen,
}) {
  const [mindmap, setmindmap] = useState("");
  const [folder, setfolder] = useState(null);
  const [apiCalled, setapiCalled] = useState(false);

  const handleAddMindmap = () => {
    setapiCalled(true);
    const data = {
      nodes: [
        {
          data: { label: "", alignment: "center" },
          height: 60,
          width: 200,
          id: "root",
          position: {
            x: 0,
            y: 0,
          },
          type: "mindmap",
        },
      ],
      edges: [],
    };

    createMindmap({ mindmapName: mindmap, folderId: folder, data: data })
      .then((res) => {
        setfolders(
          folders.map((folder) => {
            if (folder._id == res.folder) {
              folder.mindmaps.push(res);
            }
            return folder;
          })
        );
        setactiveMindmap(res._id);
        setapiCalled(false);
        onCancel();
      })
      .catch((err) => {
        setapiCalled(false);
      });
  };

  const changeAction = (id) => {
    setfolder(id);
  };

  const hanldeOpenFolderModal = () => {
    setaddFolderModalOpen(true);
  };

  return (
    <Modal centered isOpen={open} className="add-mindmap-modal">
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center w-100 px-2 mt-2 mb-5">
          <p className="h4 fw-bold text-uppercase text-white">Add a Mindmap</p>
          <p className="fw-bold text-white" role="button" onClick={onCancel}>
            x
          </p>
        </div>
        <input
          type="text"
          className="form-control my-3 primary-input modal-input"
          placeholder="Name your Mindmap..."
          onChange={(e) => {
            setmindmap(e.target.value);
          }}
          onKeyDown={(e) => handleEnterKeyPress(e, handleAddMindmap)}
        />
        <DropdownLight
          mainItems={folders}
          changeAction={changeAction}
          defaultValue={{ name: "Select Folder" }}
          secondValue={{
            name: "Create New Folder",
            onClick: hanldeOpenFolderModal,
          }}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className={`position-relative btn secondary-btn w-100`}
          style={{ height: "50px" }}
          onClick={!apiCalled ? handleAddMindmap : null}
          disabled={!folder || !mindmap}
        >
          <span className={`fw-bolder loading-text ${apiCalled ? "hide" : ""}`}>
            Create Mindmap
          </span>
          {apiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
}
