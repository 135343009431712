import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import {
  getUsers as getUsersApi,
  updateUser as updateUserApi,
} from "../api/admin";
import Search from "../components/Search";
import Moment from "react-moment";
import UpdateStatusModal from "../components/modals/UpdateStatusModal";
import { motion } from "framer-motion";

export default function ManageUsers() {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  const [users, setusers] = useState([]);
  const [usersCopy, setusersCopy] = useState([]);
  const [loading, setloading] = useState(false);
  const [updatePlanApiCalled, setupdatePlanApiCalled] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [updateUserId, setupdateUserId] = useState(null);

  const getUsers = () => {
    setloading(true);
    getUsersApi()
      .then((res) => {
        res = res.map((r) => {
          return {
            ...r,
            plan: {
              ...r.plan,
              plan: r.plan.plan ? r.plan.plan : "free",
            },
          };
        });
        setusers(res);
        setloading(false);
        setusersCopy(res);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const handleUpdateUser = ({ userId }) => {
    setupdateUserId(userId);
    setmodalOpen(true);
  };

  const updateUser = () => {
    setupdatePlanApiCalled(true);
    updateUserApi({ userId: updateUserId })
      .then((res) => {
        setupdatePlanApiCalled(false);
        setmodalOpen(false);
        getUsers();
      })
      .catch((err) => {
        setmodalOpen(false);
        setupdatePlanApiCalled(false);
      });
  };

  const filterQuestions = (currentSearchValue) => {
    setusers(
      usersCopy.filter(
        (user) =>
          user.email.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          user.username
            .toLowerCase()
            .includes(currentSearchValue.toLowerCase()) ||
          user.plan.plan
            .toLowerCase()
            .includes(currentSearchValue.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (!currentUser?.isAdmin) {
      navigate("/dashboard");
    } else {
      getUsers();
    }
  }, []);

  return (
    <div>
      {modalOpen && (
        <UpdateStatusModal
          open={true}
          onCancel={() => setmodalOpen(false)}
          onConfirm={updateUser}
          updatePlanApiCalled={updatePlanApiCalled}
        />
      )}
      <motion.div
        className="mt-5 position-relative"
        initial={{ opacity: 0, top: "20vh" }}
        animate={{
          opacity: 1,
          top: "0vh",
        }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <div className="d-flex align-items-center mx-3 mx-md-5 gap-3 gap-md-0 justify-content-center">
          <div className="database-search-main-wrapper">
            <Search
              filterQuestions={filterQuestions}
              placeholder={" SEARCH USERS..."}
            />
          </div>
        </div>

        {loading ? (
          <div className="mt-5 pt-5">
            <div className="spinner-border text-secondary" role="status"></div>
          </div>
        ) : (
          <div className="w-100 m-auto px-md-5 mt-5">
            {users.length > 0 ? (
              <table
                className={`table table-bordered table-responsive text-white manage-users-table`}
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Registered On</th>
                    <th scope="col">Payment Date</th>
                    <th scope="col">Plan</th>
                    <th scope="col">Expires At</th>
                    <th scope="col">Last Activity</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={index}>
                      <td>
                        <p className="text-start text-wrap text-break">
                          {user.username}
                        </p>
                      </td>
                      <td>
                        <p className="text-start text-wrap text-break">
                          {user.email}
                        </p>
                      </td>
                      <td>
                        <p className="text-start text-wrap">
                          <Moment format="DD MMM, YYYY">
                            {user.registeredOn}
                          </Moment>
                        </p>
                      </td>
                      <td>
                        <p className="text-start text-wrap">
                          {user.plan.paymentDate ? (
                            <Moment format="DD MMM, YYYY">
                              {user.plan.paymentDate}
                            </Moment>
                          ) : (
                            "-"
                          )}
                        </p>
                      </td>
                      <td>
                        <p className="text-start">{user.plan.plan}</p>
                      </td>
                      <td>
                        <p className="text-start text-wrap">
                          {user.plan.expires ? (
                            <Moment format="DD MMM, YYYY">
                              {user.plan.expires}
                            </Moment>
                          ) : (
                            "-"
                          )}
                        </p>
                      </td>

                      <td>
                        <p className="text-start text-wrap">
                          <Moment fromNow>{user.lastActive}</Moment>
                        </p>
                      </td>

                      <td>
                        <button
                          className={`btn btn-outline-light-mode btn-outline-primary btn-sm text-white`}
                          disabled={
                            // (user.plan.plan == "paid" && !user.plan.test) ||
                            updatePlanApiCalled
                          }
                          onClick={() =>
                            handleUpdateUser({
                              userId: user._id,
                            })
                          }
                        >
                          Update Plan
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="mt-5">
                <h6 className="text-uppercase text-secondary">
                  No users available
                </h6>
              </div>
            )}
          </div>
        )}
      </motion.div>
    </div>
  );
}
