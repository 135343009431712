import React, { useEffect, useState } from "react";
import {
  getSections as getSectionsApi,
  deleteSection as deleteSectionApi,
  updateSection as updateSectionApi,
} from "../../api/questionBank";
import AddSectionModal from "../modals/AddSectionModal";
import AddButton from "./AddButton";
import NavigateButton from "./NavigateButton";

export default function SelectSection({
  setcurrStep,
  loading,
  setloading,
  subject,
  chapter,
  setsection,
  revision,
  setmessage,
}) {
  useEffect(() => {
    if (!subject || !chapter) {
      setcurrStep(2);
    }
  }, []);

  const [sections, setsections] = useState([]);
  const [addSectionModalOpen, setaddSectionModalOpen] = useState(false);
  const [updatedNames, setupdatedNames] = useState([]);

  const getAllSections = () => {
    setloading(true);
    getSectionsApi({ chapterId: chapter._id })
      .then((res) => {
        setupdatedNames(res.map((r) => r.name));
        setsections(res);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const handleDeleteSection = (index) => {
    deleteSectionApi({ sectionId: sections[index]._id })
      .then((res) => {
        getAllSections();
      })
      .catch((err) => {});
  };

  const handleUpdateSection = (index) => {
    if (updatedNames[index])
      updateSectionApi({
        sectionId: sections[index]._id,
        name: updatedNames[index],
      })
        .then((res) => {
          getAllSections();
        })
        .catch((err) => {
          setmessage({ message: err, success: false });
        });
  };

  const handleNavigate = (index) => {
    setsection(sections[index]);
    setcurrStep(4);
  };

  const handleUpdateName = (index, text) => {
    const newItems = [...updatedNames];
    newItems[index] = text;
    setupdatedNames(newItems);
  };

  useEffect(() => {
    getAllSections();
  }, []);

  return (
    <div>
      {addSectionModalOpen && (
        <AddSectionModal
          subject={subject}
          chapter={chapter}
          open={true}
          onConfirm={() => {
            setaddSectionModalOpen(false);
            getAllSections();
          }}
          onCancel={() => setaddSectionModalOpen(false)}
          setmessage={setmessage}
        />
      )}
      {loading ? (
        <div>
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      ) : (
        <div className="row">
          {!revision && (
            <div className="col-7 col-sm-6 col-md-4 col-xl-3 col-xxl-2 mt-3 mx-auto mx-sm-0">
              <AddButton
                clickAction={() => setaddSectionModalOpen(true)}
                text={"section"}
              />
            </div>
          )}
          {sections.map((section, index) => (
            <div className="col-7 col-sm-6 col-md-4 col-xl-3 col-xxl-2 mt-3 mx-auto mx-sm-0">
              <NavigateButton
                name={section.name}
                index={index}
                navigate={() => handleNavigate(index)}
                setupdatedName={handleUpdateName}
                updatedName={updatedNames[index]}
                updateAction={() => handleUpdateSection(index)}
                deleteAction={() => handleDeleteSection(index)}
                revision={revision}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
