import React, { useEffect, useState } from "react";
import { getAllQuestions as getAllQuestionsApi } from "../../api/questionBank";
import styles from "../../assets/css/revision.module.css";
import ArrowRight from "../../assets/images/icons/arrow-right.svg";
import ArrowLeft from "../../assets/images/icons/arrow-left.svg";

const MAX_VISIBILITY = 3;

const backgrounds = [
  "linear-gradient(93.8deg, #FF007A 0%, #8F00FF 100%)",
  "linear-gradient(162.59deg, #56CCF2 2.46%, #0E5CC6 98.3%)",
  "linear-gradient(100.72deg, #191919 1.51%, #595959 96.71%)",
  "linear-gradient(93.8deg, #FE8C00 0%, #F83600 100%)",
  "linear-gradient(93.8deg, #9D50BB 0%, #6E48AA 100%)",
  "linear-gradient(93.8deg, #7CF86F 0%, #04BAE1 100%)",
  "linear-gradient(93.8deg, #054F15 0%, #F3F730 100%)",
  "linear-gradient(93.55deg, #C31432 0.39%, #6A0D0D 100%)",
  "linear-gradient(93.49deg, #654EA3 0.39%, #EAAFC8 101.63%)",
  "linear-gradient(93.8deg, #FF416C 0%, #FF4B2B 100%)",
];

const Card = ({ title, question, index, active }) => {
  const [showAnswer, setshowAnswer] = useState(false);
  useEffect(() => {
    setshowAnswer(false);
  }, [index, active]);

  return (
    <div
      className={`card border-0 ${styles.revCard}`}
      role="button"
      onClick={() => setshowAnswer(!showAnswer)}
    >
      <div
        className={`${styles.revCardHeader} py-4 px-0 text-white`}
        style={{
          background:
            index < backgrounds.length
              ? backgrounds[index]
              : backgrounds[index % backgrounds.length],
        }}
      >
        <p className={`fw-bolder h2`}>{title}</p>
      </div>
      <div
        className={`text-start ${styles.revCardBody} p-3 bg-white`}
        style={{ flex: 1 }}
      >
        <div
          className={`${styles.revCardQues} ${
            index == active ? styles.active : ""
          }`}
        >
          <p className={`fw-bolder h5 mb-1`}>{question.question}</p>
        </div>
        <hr
          className={`mt-1 mb-2 ${styles.divider} ${
            showAnswer ? styles.show : ""
          }`}
        />
        <div
          className={`${styles.revCardAns} ${showAnswer ? styles.show : ""}`}
        >
          <p className={``}>{question.answer}</p>
        </div>
      </div>
    </div>
  );
};

const Carousel = ({ children, active, setActive }) => {
  const count = React.Children.count(children);

  const handleGotoFirst = () => {
    let a = active;
    for (let i = 0; i < a; i++) {
      (function (i) {
        setTimeout(() => {
          setActive(a - 1);
          a--;
        }, i * 80);
      })(i);
    }
  };

  return (
    <div>
      <div className={`${styles.carousel}`}>
        {active > 0 && (
          <button
            className={`${styles.nav} ${styles.left}`}
            onClick={() => setActive((i) => i - 1)}
          >
            <img src={ArrowLeft} className="h-100 w-100" />
          </button>
        )}
        {React.Children.map(children, (child, i) => (
          <div
            className={`${styles.cardContainer}`}
            style={{
              "--active": i === active ? 1 : 0,
              "--offset": (active - i) / 3,
              "--direction": Math.sign(active - i),
              "--abs-offset": Math.abs(active - i) / 3,
              "pointer-events": active === i ? "auto" : "none",
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
              display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
            }}
          >
            {child}
          </div>
        ))}
        {active < count - 1 && (
          <button
            className={`${styles.nav} ${styles.right}`}
            onClick={() => setActive((i) => i + 1)}
          >
            <img src={ArrowRight} className="h-100 w-100" />
          </button>
        )}
      </div>
      {/* {active > 0 && ( */}
      <button
        className="p-0 bg-transparent border-0"
        onClick={handleGotoFirst}
        disabled={active == 0}
        style={{ opacity: active > 0 ? "1" : 0 }}
      >
        <p className="text-uppercase text-white">
          go to <span className="fw-bolder">first card</span>
        </p>
      </button>
      {/* )} */}
    </div>
  );
};

export default function ViewQuestions({ loading, questions, subjectsLength }) {
  const [active, setActive] = useState(0);

  useEffect(() => {
    setActive(0);
  }, [questions]);

  return (
    <div className="mt-4 questions-carousel-wrapper">
      {loading ? (
        <div>
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      ) : subjectsLength > 0 ? (
        <div>
          {questions.length > 0 ? (
            <div className={`${styles.revisionWrapper}`}>
              <Carousel active={active} setActive={setActive}>
                {[...new Array(questions.length)].map((_, i) => (
                  <Card
                    index={i}
                    active={active}
                    title={`CARD ${i + 1}/${questions.length}`}
                    question={questions[i]}
                  />
                ))}
              </Carousel>
            </div>
          ) : (
            <div className="mt-5">
              <h6 className="text-uppercase text-secondary">
                no questions available
              </h6>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
