import React, { useContext, useEffect, useState } from "react";
import {
  deleteBlog as deleteBlogApi,
  get as getApi,
  like,
  unlike,
} from "../../api/blog";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Moment from "react-moment";
import CardRow from "./CardRow";
import DeleteIcon from "../../assets/images/icons/delete.svg";
import { AuthContext } from "../../contexts/UserContext";
import ConfirmModal from "../modals/Confirm";
import ImageModal from "../modals/ImageModal";

export default function Card({ blog }) {
  const [mainDate, setmainDate] = useState("");
  const [currnetDeleteBlog, setcurrnetDeleteBlog] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const [mainBlog, setmainBlog] = useState(null);
  const basUrl =
    process.env.REACT_APP_BASE_URL +
    "/server-blogs/" +
    blog.date +
    "/dashboard";

  const [mainImg, setmainImg] = useState("");
  const [media, setmedia] = useState([]);

  useEffect(() => {
    setmainBlog(blog);
    let numDate = parseInt(blog.date);

    const date = new Date(numDate);
    setmainDate(date);
    let img = blog.media.find(
      (med) => med.mimeType.includes("image") && !med.mimeType.includes("svg")
    );

    if (img) {
      setmainImg(img.location);
      setmedia(
        blog.media.filter((med) => {
          return med.filename != img.filename;
        })
      );
    } else {
      setmedia(blog.media);
    }
  }, []);

  const handleDelete = (blogId) => {
    setisDeleteModalOpen(true);
    setcurrnetDeleteBlog(blogId);
  };

  const deletePost = () => {
    if (currnetDeleteBlog)
      deleteBlogApi({ blogId: currnetDeleteBlog, blogDate: blog.date })
        .then((res) => {
          window.location.reload();
          setcurrnetDeleteBlog(null);
          setisDeleteModalOpen(false);
        })
        .catch((err) => {});
  };

  const handleLike = () => {
    setmainBlog({ ...mainBlog, liked: true, likes: mainBlog.likes + 1 });
    like({ blogId: blog._id })
      .then((res) => {})
      .catch((err) => {});
  };

  const handleUnlike = () => {
    setmainBlog({ ...mainBlog, liked: false, likes: mainBlog.likes - 1 });
    unlike({ blogId: blog._id })
      .then((res) => {})
      .catch((err) => {});
  };

  const [isDeleteModalOpen, setisDeleteModalOpen] = useState(false);

  const [imageModalOpen, setimageModalOpen] = useState(false);

  return (
    <div className="position-relative">
      {imageModalOpen && (
        <ImageModal
          open={true}
          imageSrc={mainImg}
          onCancel={() => setimageModalOpen(false)}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmModal
          open={isDeleteModalOpen}
          onCancel={() => setisDeleteModalOpen(false)}
          onConfirm={deletePost}
          heading={"Confirm Delete"}
          text={"Are you sure you want to delete this post?"}
          buttonText={"Delete Post"}
        />
      )}

      <div className="text-dark timeline-time position-absolute p-1">
        <Moment format="h:mm A">{mainDate}</Moment>
      </div>
      <div style={{ color: "#56CCF2" }}>
        <Moment format="DD MMM" className="position-absolute timeline-date">
          {mainDate}
        </Moment>
      </div>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{
          color: "#fff",
          background: "none",
          boxShadow: "none",
        }}
        contentArrowStyle={{
          display: "none",
        }}
        iconStyle={{
          background: "#262a41",
          height: "15px",
          width: "15px",
          left: "12px",
          top: "45px",
        }}
        style={{ textAlign: "start" }}
      >
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ marginTop: "10px" }}
        >
          <p
            className="vertical-timeline-element-title h5 notif-title"
            style={{
              color: "#56CCF2",
              wordBreak: "break-word",
              flexBasis: "80%",
            }}
            dangerouslySetInnerHTML={{ __html: blog.title }}
          ></p>
          {currentUser?.isAdmin && !mainBlog?.sample && (
            <svg
              role="button"
              onClick={() => handleDelete(blog._id)}
              className="delete-blog-icon mt-3"
              width="23"
              height="26"
              viewBox="0 0 23 26"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M22.0702 4.66646C20.1825 4.47886 18.2948 4.33816 16.3954 4.23264V4.22092L16.1375 2.6967C15.9616 1.61802 15.7037 0 12.9601 0H9.88819C7.15629 0 6.89835 1.54767 6.71075 2.68497L6.46453 4.18574C5.37413 4.25609 4.28373 4.32644 3.19332 4.43196L0.801475 4.66646C0.309036 4.71336 -0.0427068 5.14717 0.00419221 5.62789C0.0510912 6.1086 0.473182 6.46035 0.965622 6.41345L3.35747 6.17895C9.50128 5.56926 15.6919 5.80376 21.9061 6.42517H21.9999C22.4454 6.42517 22.8323 6.08515 22.8792 5.62789C22.9144 5.14717 22.5627 4.71336 22.0702 4.66646Z" />
              <path d="M19.9129 8.07836C19.6315 7.78524 19.2446 7.62109 18.8459 7.62109H4.02588C3.62724 7.62109 3.2286 7.78524 2.95893 8.07836C2.68926 8.37148 2.53684 8.77012 2.56028 9.18049L3.28722 21.2101C3.41619 22.9923 3.58034 25.22 7.67228 25.22H15.1995C19.2915 25.22 19.4557 23.004 19.5846 21.2101L20.3115 9.19221C20.335 8.77012 20.1825 8.37148 19.9129 8.07836ZM13.3822 19.3459H9.47784C8.99718 19.3459 8.59854 18.9472 8.59854 18.4665C8.59854 17.9858 8.99718 17.5872 9.47784 17.5872H13.3822C13.8629 17.5872 14.2615 17.9858 14.2615 18.4665C14.2615 18.9472 13.8629 19.3459 13.3822 19.3459ZM14.3671 14.656H8.50474C8.02402 14.656 7.62538 14.2573 7.62538 13.7766C7.62538 13.2959 8.02402 12.8972 8.50474 12.8972H14.3671C14.8478 12.8972 15.2464 13.2959 15.2464 13.7766C15.2464 14.2573 14.8478 14.656 14.3671 14.656Z" />
            </svg>
            // <img
            //   className="delete-blog-icon"
            //   src={DeleteIcon}
            //   role="button"
            //   onClick={() => handleDelete(blog._id)}
            // />
          )}
        </div>
        <p
          style={{ wordBreak: "break-word" }}
          dangerouslySetInnerHTML={{ __html: blog.description }}
        ></p>
        {mainImg && (
          <img
            src={mainImg}
            className="h-100 mt-4"
            style={{ maxHeight: "250px" }}
            role="button"
            onClick={() => {
              setimageModalOpen(true);
            }}
          />
        )}
        {media.map((med) => (
          <div>
            <CardRow media={med} baseUrl={basUrl} />
          </div>
        ))}
        {/* <p>{mainBlog?.likes}</p> */}
        {/* {mainBlog?.liked ? (
          <p onClick={handleUnlike}>unlike</p>
        ) : (
          <p onClick={handleLike}>like</p>
        )} */}

        <div
          id="like"
          className={`like-btn ${mainBlog?.liked ? "active" : "dislike"}`}
        >
          <div className="icon">
            <svg
              className="prime"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
              ></path>
            </svg>
            <svg
              role="button"
              onClick={() => {
                if (currentUser && !mainBlog?.sample) {
                  !mainBlog?.liked ? handleLike() : handleUnlike();
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
              ></path>
            </svg>
          </div>
          <p className="counter" id="couter">
            {mainBlog?.likes || 0}
          </p>
        </div>
      </VerticalTimelineElement>
    </div>
  );
}
