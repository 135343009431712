import React, { useEffect, useState } from "react";
import {
  getSubjects as getSubjectsApi,
  deleteSubject as deleteSubjectApi,
  updateSubject as updateSubjectApi,
} from "../../api/questionBank";
import AddSubjectModal from "../modals/AddSubjectModal";
import AddButton from "./AddButton";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";

export default function SelectSubject({
  setcurrStep,
  loading,
  setloading,
  setsubject,
  revision,
  setmessage,
}) {
  const [subjects, setsubjects] = useState([]);
  const [addSubjectModalOpen, setaddSubjectModalOpen] = useState(false);
  const [updatedNames, setupdatedNames] = useState([]);

  const getAllSubjects = () => {
    setloading(true);
    getSubjectsApi()
      .then((res) => {
        setloading(false);
        setupdatedNames(res.map((r) => r.name));
        setsubjects(res);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const handleDeleteSubject = (index) => {
    deleteSubjectApi({ subjectId: subjects[index]._id })
      .then((res) => {
        getAllSubjects();
      })
      .catch((err) => {});
  };

  const handleUpdateSubject = (index) => {
    if (updatedNames[index])
      updateSubjectApi({
        subjectId: subjects[index]._id,
        name: updatedNames[index],
      })
        .then((res) => {
          getAllSubjects();
        })
        .catch((err) => {
          setmessage({ message: err, success: false });
        });
  };

  const handleNavigate = (index) => {
    setsubject(subjects[index]);
    setcurrStep(2);
  };

  const handleUpdateName = (index, text) => {
    const newItems = [...updatedNames];
    newItems[index] = text;
    setupdatedNames(newItems);
  };

  useEffect(() => {
    getAllSubjects();
  }, []);

  return (
    <div>
      {addSubjectModalOpen && (
        <AddSubjectModal
          open={true}
          onConfirm={() => {
            setaddSubjectModalOpen(false);
            getAllSubjects();
          }}
          onCancel={() => setaddSubjectModalOpen(false)}
          setmessage={setmessage}
        />
      )}
      {loading ? (
        <div>
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      ) : (
        <div className="row">
          {!revision && (
            <div className="col-7 col-sm-6 col-md-4 col-xl-3 col-xxl-2 mt-3 mx-auto mx-sm-0">
              <AddButton
                clickAction={() => setaddSubjectModalOpen(true)}
                text={"subject"}
              />
            </div>
          )}
          {subjects.length > 0 ? (
            subjects.map((subject, index) => (
              <div className="col-7 col-sm-6 col-md-4 col-xl-3 col-xxl-2 mt-3 mx-auto mx-sm-0">
                <NavigateButton
                  name={subject.name}
                  index={index}
                  navigate={() => handleNavigate(index)}
                  setupdatedName={handleUpdateName}
                  updatedName={updatedNames[index]}
                  updateAction={() => handleUpdateSubject(index)}
                  deleteAction={() => handleDeleteSubject(index)}
                  revision={revision}
                />
              </div>
            ))
          ) : revision ? (
            <div>
              <p className="h6 fw-bolder">No subjects available</p>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
