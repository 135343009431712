import React from "react";

export default function SettingDropdown({
  updateAction,
  deleteAction,
  renameActive,
  setrenameActive,
}) {
  return (
    <div
      className="position-absolute px-3 pb-2 pt-2 bg-dark text-white"
      style={{ top: "100%", borderRadius: "12px", zIndex: 4 }}
    >
      <div>
        {renameActive ? (
          <p
            onClick={() => {
              setrenameActive(false);
              updateAction();
            }}
          >
            Done
          </p>
        ) : (
          <p onClick={() => setrenameActive(true)}>Rename</p>
        )}

        <p className="delete-btn mb-0" onClick={deleteAction}>
          Delete
        </p>
      </div>
    </div>
  );
}
