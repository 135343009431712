import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteUser } from "../../api/user";
import DeleteMainIcon from "../../assets/images/icons/delete-account-modal/delete-main.svg";
import DeleteCrossIcon from "../../assets/images/icons/delete-account-modal/delete-cross.svg";

export default function DeleteAccountModal({
  open,
  onCancel,
  setdeleteModalOpen,
  setmessage,
  setaccountDeletedModalOpen,
}) {
  const [apiCalled, setapiCalled] = useState(false);

  const handleDeleteAccount = () => {
    setapiCalled(true);
    deleteUser()
      .then((res) => {
        setaccountDeletedModalOpen(true);
        setdeleteModalOpen(false);
        setapiCalled(false);
      })
      .catch((err) => {
        setdeleteModalOpen(false);
        setmessage({ message: err, success: false });
      });
  };

  return (
    <Modal centered isOpen={open} className="delete-modal">
      <ModalBody className="mt-0">
        <p
          className="fw-bold text-white w-100 text-end"
          role="button"
          onClick={onCancel}
          style={{ right: 0, position: "relative", height: "18px" }}
        >
          <img src={DeleteCrossIcon} className="h-100" />
        </p>

        <div className="m-auto w-100 text-center">
          <img src={DeleteMainIcon} style={{ height: "50px" }} />
        </div>
      </ModalBody>
      <ModalFooter>
        <p className="text-center mx-4">
          Are you sure you want to delete your account? All the stored data will
          be deleted and this action can not be undone.
        </p>
        <div className="d-flex gap-5 w-100 mt-4">
          <button
            className={`position-relative btn secondary-btn`}
            style={{ height: "50px" }}
            onClick={onCancel}
          >
            <span className={`fw-bolder loading-text`}>Cancel</span>
          </button>

          <button
            className={`position-relative btn w-100 acc-delete-btn`}
            onClick={!apiCalled && handleDeleteAccount}
          >
            <span
              className={`fw-bolder loading-text ${apiCalled ? "hide" : ""}`}
            >
              Delete Account
            </span>
            {apiCalled && (
              <div className="loading-4 loading">
                <span className="span1"></span>
                <span className="span2"></span>
                <span className="span3"></span>
              </div>
            )}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
