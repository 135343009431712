import { axiosClient } from "./axios";

export const getSampleQuestions = async () => {
  try {
    const response = await axiosClient.get("/sample/rev-questions");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getSampleBlogs = async () => {
  try {
    const response = await axiosClient.get("/sample/blogs");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
