import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import { Handle, Position, NodeResizer, NodeResizeControl } from "reactflow";

import useStore from "./store";
import NodeContextMenu from "./NodeContextMenu";

function MindMapNode({ id, data, selected }) {
  const [editing, setediting] = useState(false);
  const [nodeHeight, setnodeHeight] = useState(0);

  const divRef = useRef(null);
  const [inputValue, setinputValue] = useState("");

  const [showContextMenu, setshowContextMenu] = useState(false);

  const updateNodeLabel = useStore((state) => state.updateNodeLabel);
  const updateNodeAlignment = useStore((state) => state.updateNodeAlignment);
  const updateNodeHeight = useStore((state) => state.updateNodeHeight);

  const node = useStore((state) => state.nodes).find((node) => {
    return node.id == id;
  });

  useEffect(() => {
    if (node?.data) {
      setnodeHeight(node.height);
      setinputValue(node.data.label);
    }
  }, []);

  const handleContextMenu = (e) => {
    e.preventDefault();
    if (selected) setshowContextMenu(true);
  };

  useEffect(() => {
    if (selected) {
      setshowContextMenu(editing);
    } else {
      setediting(false);
      setshowContextMenu(false);
    }
  }, [editing, selected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        !event.target.classList.contains("node-input") &&
        !event.target.parentNode.classList.contains("node-input") &&
        !event.target.tagName == "U" &&
        !event.target.tagName == "B" &&
        !event.target.tagName == "I"
      ) {
        setediting(false);
      } else if (event.target.classList.contains("node-context")) {
        divRef.current.focus();
        setediting(true);
      } else {
        // setediting(true);
      }
    };

    const handleDocClick = (event) => {
      if (
        !event.target.classList.contains("node-context") &&
        !event.target.classList.contains("node-input")
      ) {
        setshowContextMenu(false);
        setediting(false);
      }
    };

    divRef.current.addEventListener("click", handleClickOutside);
    document.addEventListener("click", handleDocClick);

    return () => {
      // divRef.current.removeEventListener("click", handleClickOutside);
      document.removeEventListener("click", handleDocClick);
    };
  }, []);

  const handleAlignChange = (align) => {
    updateNodeAlignment({ nodeId: id, alignment: align });
  };
  const controlStyle = {
    background: "transparent",
    border: "none",
  };

  return (
    <>
      <div
        className={`w-100 position-relative ${editing ? "nodrag" : ""}`}
        onDoubleClick={() => {
          setediting(true);
          setTimeout(() => {
            divRef.current.focus();
          }, 0);
        }}
        style={{ height: node?.height, minWidth: "27px", minHeight: "25px" }}
        onContextMenu={handleContextMenu}
      >
        {showContextMenu && (
          <NodeContextMenu
            nodeWidth={node?.width}
            handleAlignChange={handleAlignChange}
            setediting={setediting}
          />
        )}
        <div
          contentEditable={editing}
          ref={divRef}
          onBlur={() => {
            // setediting(false);
            updateNodeLabel({
              nodeId: id,
              label: inputValue,
            });
          }}
          dangerouslySetInnerHTML={{ __html: data?.label }}
          className={`form-control resizable-div node-input justify-content-center text-break ${
            (selected && !editing) || editing ? "selected" : ""
          } ${editing ? "nodrag" : "disabled"} d-flex flex-column align-items-${
            node?.data?.alignment
          }`}
          placeholder="Add Topic"
          onInput={(e) => {
            const scrollHeight = e.target.scrollHeight + 4;
            updateNodeHeight({
              nodeId: id,
              height: scrollHeight,
            });

            setinputValue(e.target.innerHTML);
          }}
          style={{
            cursor: editing ? "text" : "inherit",
            // resize: editing ? "both" : "none",
            resize: "none",
            width: node?.width,
            height: node?.height,
            userSelect: "text",
          }}
        />
        {selected && <NodeResizer color="#262a41" />}

        <Handle type="target" position={Position.Right} />
        <Handle type="source" position={Position.Right} />
      </div>
    </>
  );
}

export default MindMapNode;

function ResizeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="black"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ position: "absolute", right: 5, bottom: 5 }}
    >
      {/* <path stroke="none" d="M0 0h24v24H0z" fill="none" /> */}
      <polyline points="16 20 20 20 20 16" />
      <line x1="14" y1="14" x2="20" y2="20" />
      <polyline points="8 4 4 4 4 8" />
      <line x1="4" y1="4" x2="10" y2="10" />
    </svg>
  );
}
