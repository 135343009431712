import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/UserContext";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import EditProfile from "../components/profile/EditProfile";
import ViewPlan from "../components/profile/ViewPlan";
import UpdatePassword from "../components/profile/UpdatePassword";
import { motion } from "framer-motion";

export default function Profile() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [currentTab, setcurrentTab] = useState("profile");

  const handleTabChange = (tabName) => {
    setcurrentTab(tabName);
  };

  return (
    <div>
      <motion.div
        className="container position-relative"
        initial={{ opacity: 0, top: "20vh" }}
        animate={{
          opacity: 1,
          top: "0vh",
        }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <div className="row">
          <div className="col-12 col-sm-9 col-lg-6 m-auto d-flex flex-column justify-content-start profile-wrapper">
            <ul className="nav nav-tabs my-4 d-flex justify-content-between flex-nowrap">
              <li
                role="button"
                className={`nav-item w-100 ${
                  currentTab == "profile" ? "active" : ""
                }`}
                onClick={() => {
                  handleTabChange("profile");
                }}
              >
                <p
                  className={`nav-link ${
                    currentTab == "profile" ? "active" : ""
                  } text-dark`}
                  style={{ background: "none" }}
                >
                  Profile
                </p>
              </li>
              <li
                role="button"
                className={`nav-item w-100 ${
                  currentTab == "payment" ? "active" : ""
                }`}
                onClick={() => {
                  handleTabChange("payment");
                }}
              >
                <p
                  className={`nav-link ${
                    currentTab == "payment" ? "active" : ""
                  } text-dark`}
                  style={{ background: "none" }}
                >
                  Plan
                </p>
              </li>
              {!currentUser?.googleId && (
                <li
                  role="button"
                  className={`nav-item w-100 ${
                    currentTab == "security" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleTabChange("security");
                  }}
                >
                  <p
                    className={`nav-link ${
                      currentTab == "security" ? "active" : ""
                    } text-dark`}
                    style={{ background: "none" }}
                  >
                    Security
                  </p>
                </li>
              )}
            </ul>
            {currentTab == "profile" ? (
              <EditProfile
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
              />
            ) : currentTab == "payment" ? (
              <ViewPlan plan={currentUser.plan} />
            ) : (
              <UpdatePassword />
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}
