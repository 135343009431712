import React, { useEffect, useState } from "react";
import SettingsIcon from "../../assets/images/icons/settings.svg";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import SettingDropdown from "../questionBank/SettingDropdown";

export default function NavigateButton({
  name,
  index,
  navigate,
  updateAction,
  deleteAction,
  updatedName,
  setupdatedName,
  revision,
}) {
  const [showSettingsDropDown, setshowSettingsDropDown] = useState(false);
  const [renameActive, setrenameActive] = useState(false);
  const [showSettings, setshowSettings] = useState(false);

  return (
    <div
      className="question-bank-navigate-box position-relative"
      role="button"
      // onMouseEnter={() => setshowSettings(true)}
      onMouseLeave={() => {
        // setshowSettings(false);
        setshowSettingsDropDown(false);
      }}
      onClick={(e) => {
        if (
          !e.target.classList.contains("setting-img") &&
          !e.target.classList.contains("delete-btn") &&
          !renameActive &&
          !showSettingsDropDown
        ) {
          navigate();
        }
      }}
    >
      {!revision && (
        <div className="settings-wrapper">
          <div
            className="position-absolute mb-2 mx-3"
            style={{ bottom: 0, zIndex: 3 }}
            onClick={() => setshowSettingsDropDown(!showSettingsDropDown)}
          >
            <img
              className="setting-img"
              src={SettingsIcon}
              style={{ height: "18px", width: "18px" }}
            />
          </div>
          {showSettingsDropDown && (
            <SettingDropdown
              renameActive={renameActive}
              setrenameActive={setrenameActive}
              deleteAction={deleteAction}
              updateAction={updateAction}
            />
          )}
        </div>
      )}
      <div className="w-100 h-100 position-absolute d-flex flex-column justify-content-center navigate-name-wrapper">
        <div>
          {renameActive ? (
            <input
              className="form-control rename-input text-center h6 fw-bolder"
              type="text"
              value={updatedName}
              onKeyDown={(e) => {
                handleEnterKeyPress(e, updateAction);
              }}
              onChange={(e) => setupdatedName(index, e.target.value)}
            />
          ) : (
            <p className="h6 text-white subject-title">
              <span className="fw-bolder">{name}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
