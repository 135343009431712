import React, { useContext, useEffect, useState } from "react";
import {
  readNotification,
  readAllNotifications,
  openAllNotifications,
} from "../api/notification";
import Moment from "react-moment";
import NotificationImage from "../assets/images/icons/notification.svg";
import NotificationImageLight from "../assets/images/icons/notification-light.svg";
import { useNavigate } from "react-router-dom";
import TickIcon from "../assets/images/icons/notification-check.svg";
import { AuthContext } from "../contexts/UserContext";

export default function Notifications({
  notifications,
  getAllNotifications,
  unreadNotificationsCount,
}) {
  const { darkMode } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleReadNotification = (index) => {
    readNotification({ notificationId: notifications[index]._id })
      .then((res) => {
        navigate(`/updates#${notifications[index].blogId}`);
        getAllNotifications();
      })
      .catch((err) => {});
  };

  const handleReadAllNotifications = () => {
    readAllNotifications()
      .then((res) => {
        getAllNotifications();
      })
      .catch((err) => {});
  };

  const handleOpenAllNotifications = () => {
    openAllNotifications()
      .then((res) => {
        getAllNotifications();
      })
      .catch((err) => {});
  };

  return (
    <div className="position-relative" style={{ zIndex: 10 }}>
      {unreadNotificationsCount > 0 && (
        <div
          className="fw-bolder bg-danger p-0 m-0 d-flex flex-column justify-content-center"
          style={{
            position: "absolute",
            width: "15px",
            left: "17px",
            top: "3px",
            zIndex: 2,
            height: "15px",
            color: "white",
            borderRadius: "50%",
          }}
        >
          <span style={{ fontSize: "8px" }}>
            {unreadNotificationsCount > 99 ? "99+" : unreadNotificationsCount}
          </span>
        </div>
      )}
      <div className="dropdown" onClick={handleOpenAllNotifications}>
        <button
          className="nav-profile-link btn text-white p-0 m-0"
          style={{ background: "none", boxShadow: "none" }}
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {darkMode ? (
            <img src={NotificationImage} style={{ height: "22px" }} />
          ) : (
            <img src={NotificationImageLight} style={{ height: "22px" }} />
          )}
        </button>
        <ul
          className="dropdown-menu navbar-dropdown p-0 m-0 notif-dropdown"
          style={{
            borderRadius: notifications.length > 0 ? "15px" : "2px",
            maxHeight: "70vh",
            overflowY: "scroll",
          }}
        >
          {notifications.length > 0 ? (
            <div className="px-3 py-1">
              <div className="d-flex align-items-center justify-content-between mb-1">
                <p className="h5 fw-bold p-2 m-0">Notifications</p>
                <div
                  className="d-flex align-items-center"
                  role="button"
                  onClick={() => handleReadAllNotifications()}
                >
                  <img src={TickIcon} />
                  <p className="p-2 m-0" style={{ color: "#2173F2" }}>
                    Mark as read
                  </p>
                </div>
              </div>
              <div>
                {notifications.map((notification, index) => (
                  <div>
                    <div className="d-flex gap-1 align-items-center">
                      {!notification.isRead && (
                        <div
                          style={{
                            background: "#2173F2",
                            height: "6px",
                            width: "6px",
                            borderRadius: "50%",
                          }}
                        ></div>
                      )}
                      <li
                        role="button"
                        onClick={() => handleReadNotification(index)}
                        className="pt-3 pb-2 px-3 w-100"
                        style={{
                          background: !notification.isRead ? "#EAEAEA" : "",
                          wordBreak: "break-word",
                        }}
                      >
                        <div>
                          <p
                            className="text-decoration-none navbar-link text-dark fw-bold p-0 m-0 notif-title"
                            dangerouslySetInnerHTML={{
                              __html: notification.text,
                            }}
                          ></p>
                          <div style={{ color: "#909090" }}>
                            <Moment className="small" format="MMM D, YYYY">
                              {notification.createdAt}
                            </Moment>{" "}
                            at{" "}
                            <Moment className="small" format="h:mm A">
                              {notification.createdAt}
                            </Moment>
                          </div>
                        </div>
                      </li>
                    </div>
                    <hr className="my-2 mx-3" style={{ color: "#D4D4D4" }} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-center h6 pt-1">No New Notifications</p>
          )}
        </ul>
      </div>
    </div>
  );
}
