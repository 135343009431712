import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { requestResetPassword as requestResetPasswordApi } from "../../api/user";

export default function ({ open, onCancel, onConfirm, setmessage }) {
  const [email, setemail] = useState("");
  const [apiCalled, setapiCalled] = useState(false);

  const handleRequestReset = () => {
    if (email) {
      setapiCalled(true);
      requestResetPasswordApi({
        email,
      })
        .then((res) => {
          setapiCalled(false);
          setmessage({ message: res, success: true });
          onConfirm();
        })
        .catch((err) => {
          setapiCalled(false);
          // console.log(err);
          setmessage({ message: err, success: false });
          onCancel();
        });
    }
  };

  return (
    <Modal centered isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center w-100 px-2 mt-2 mb-5">
          <p className="h4 fw-bold text-uppercase text-white">reset password</p>
          <p className="fw-bold text-white" role="button" onClick={onCancel}>
            x
          </p>
        </div>
        <input
          type="text"
          className="form-control my-3 primary-input modal-input"
          placeholder="EMAIL..."
          onChange={(e) => {
            setemail(e.target.value);
          }}
          value={email}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className={`position-relative btn secondary-btn w-100`}
          style={{ height: "50px" }}
          onClick={!apiCalled && handleRequestReset}
        >
          <span className={`fw-bolder loading-text ${apiCalled ? "hide" : ""}`}>
            Request reset link
          </span>
          {apiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
}
