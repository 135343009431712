import React, { useEffect } from "react";
import CheckIcon from "../../assets/images/icons/check-orange.svg";
import { useNavigate } from "react-router-dom";

export default function PaidLightCard({ plan }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  //   &#x20B9;
  return (
    <div>
      <div className="paid-card-light text-white px-4 py-5">
        <div className="d-flex flex-column justify-content-center">
          <p className="h4 fw-bolder text-start">Premium</p>
          <p className="display-4 fw-bolder price-main text-start">
            &#x20B9;{plan.price}
            <sub className="price-sub">/year</sub>
          </p>
        </div>
        <div>
          {plan.features.map((feature) => (
            <div className="d-flex gap-3">
              <div className="bg-white rounded-circle plan-card-img p-1 d-flex flex-column justify-content-center">
                <img src={CheckIcon} />
              </div>
              <p className="small fw-bolder">{feature}</p>
            </div>
          ))}
        </div>

        <button
          className={`btn btn-subscribe-light loading-button`}
          // onClick={handleCreateOrder}
          onClick={() => navigate("/user#signup")}
        >
          <span className={`text-uppercase fw-bolder loading-text`}>
            select
          </span>
        </button>
      </div>
    </div>
  );
}
