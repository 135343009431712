import React, { useEffect, useState } from "react";
import { getAllQuestions as getAllQuestionsApi } from "../api/questionBank";
import Filters from "../components/database/Filters";
import Search from "../components/Search";
import Message from "../components/Message";
import Questions from "../components/database/Questions";
import Options from "../components/database/Options";
import { motion } from "framer-motion";

export default function Database() {
  const [loading, setloading] = useState(true);
  const [questions, setquestions] = useState([]);
  const [currSubject, setcurrSubject] = useState("");
  const [currSection, setcurrSection] = useState("");
  const [currChapter, setcurrChapter] = useState("");
  const [questionsCopy, setquestionsCopy] = useState([]);
  const [message, setmessage] = useState(null);
  const [subjects, setsubjects] = useState([]);

  const getAllQuestions = () => {
    setloading(true);
    if (currSubject) {
      getAllQuestionsApi({
        sectionId: currSection,
        chapterId: currChapter,
        subjectId: currSubject,
      })
        .then((res) => {
          setloading(false);
          setquestions(res);
          setquestionsCopy(res);
        })
        .catch((err) => {
          setloading(false);
        });
    } else {
      setloading(false);
    }
  };

  const filterQuestions = (currentSearchValue) => {
    setquestions(
      questionsCopy.filter(
        (question) =>
          question.question
            .toLowerCase()
            .includes(currentSearchValue.toLowerCase()) ||
          question.answer
            .toLowerCase()
            .includes(currentSearchValue.toLowerCase())
      )
    );
  };

  return (
    <motion.div
      className="mt-5 position-relative"
      initial={{ opacity: 0, top: "20vh" }}
      animate={{
        opacity: 1,
        top: "0vh",
      }}
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <div className="d-flex align-items-center mx-3 mx-md-5 gap-3 gap-md-0 justify-content-center">
        <div className="database-search-main-wrapper">
          <Search
            filterQuestions={filterQuestions}
            placeholder={" SEARCH QUESTION OR ANSWER..."}
          />
        </div>
        <div>
          <Options />
        </div>
      </div>
      <div className="position-relative">
        <Filters
          currSubject={currSubject}
          setcurrSubject={setcurrSubject}
          currChapter={currChapter}
          setcurrChapter={setcurrChapter}
          currSection={currSection}
          setcurrSection={setcurrSection}
          getQuestions={getAllQuestions}
          setloading={setloading}
          loading={loading}
          subjects={subjects}
          setsubjects={setsubjects}
        />
      </div>
      {message && <Message message={message} setmessage={setmessage} />}

      {loading ? (
        <div className="mt-5 pt-5">
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      ) : (
        <Questions
          questions={questions}
          getAllQuestions={getAllQuestions}
          subjects={subjects}
          setmessage={setmessage}
        />
      )}
    </motion.div>
  );
}
