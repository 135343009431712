import React, { useEffect, useState } from "react";
import SampleAddTodoInput from "./SampleAddTodoInput";
import TodoItems from "./TodoItems";

export default function SampleTodoList() {
  const [greeting, setgreeting] = useState("");
  const [tasks, settasks] = useState([]);
  const [completion, setcompletion] = useState(0);

  useEffect(() => {
    const date = new Date();
    const hours = date.getHours();

    if (hours >= 5 && hours <= 11) {
      setgreeting("morning");
    } else if (hours >= 12 && hours <= 16) {
      setgreeting("afternoon");
    } else {
      setgreeting("evening");
    }
  }, []);

  useEffect(() => {
    const tasksCopy = [...tasks];

    setcompletion(
      (tasksCopy.filter((taskCopy) => taskCopy.completed).length /
        tasks.length) *
        100
    );
  }, [tasks]);

  return (
    <div>
      <div className="px-0 px-md-3 pt-5 feature-name-wrapper text-white">
        <p className="text-uppercase h4 feature-name-greeting d-flex flex-wrap">
          <p className="m-0">Good {greeting},</p>{" "}
          <div>
            <input
              type="text"
              className="feature-name-input text-white"
              placeholder="TYPE YOUR NAME..."
            />
          </div>
        </p>
        <p className="dashboard-main-sub-heading">
          {tasks.length == 0
            ? "Break your target in easy tasks and add them below..."
            : completion < 100
            ? "Have a look at your tasks and smash them..."
            : "Brilliant ! You have no tasks to perform, want to add more?"}
        </p>
      </div>
      <div className="my-5">
        <SampleAddTodoInput tasks={tasks} settasks={settasks} />
      </div>
      <TodoItems tasks={tasks} settasks={settasks} />
    </div>
  );
}
