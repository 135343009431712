import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import getRemainingDays from "../utils/getRemainingDays";
import FreeCard from "../components/upgradePlan/FreeCard";
import PaidCard from "../components/upgradePlan/PaidCard";
import { checkLogin } from "../api/user";
import Message from "../components/Message";
import { getPlans } from "../api/plan";
import { motion } from "framer-motion";

export default function UpgradePlan() {
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [loading, setloading] = useState(true);
  const [paidApiCalled, setpaidApiCalled] = useState(false);
  const [message, setmessage] = useState("");
  const [paidPlan, setpaidPlan] = useState(null);

  useEffect(() => {
    const remainingTime = getRemainingDays(currentUser?.plan.expires);
    if (currentUser?.plan.plan == "paid" && remainingTime >= 7)
      window.location.href = "/dashboard";

    setloading(true);
    checkLogin()
      .then((res) => {
        handleGetPlans();
        setCurrentUser(res);
      })
      .catch((err) => {
        setloading(false);
        setCurrentUser(null);
        navigate("/user");
      });
  }, []);

  const handleGetPlans = () => {
    getPlans()
      .then((res) => {
        setpaidPlan(res[0]);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  return (
    <div
      className="d-flex flex-column justify-content-center"
      style={{ minHeight: "75vh" }}
    >
      {message && (
        <div>
          <Message message={message} setmessage={setmessage} />
        </div>
      )}
      {!loading && (
        <motion.div
          className="container d-flex flex-column justify-content-center pt-3 pb-4 position-relative"
          initial={{ opacity: 0, top: "20vh" }}
          animate={{
            opacity: 1,
            top: "0vh",
          }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <div className="container">
            <div className="d-flex justify-content-center gap-5 gap-md-3 gap-lg-5 flex-wrap flex-md-nowrap">
              {currentUser.plan.plan != "expired" && (
                <div className="col-11 col-sm-8 col-md-6 col-lg-4 col-xxl-3 position-relative">
                  <FreeCard currentUser={currentUser} />
                </div>
              )}
              <div className="col-11 col-sm-8 col-md-6 col-lg-4 col-xxl-3 mt-5 mt-md-0">
                <PaidCard
                  plan={paidPlan}
                  paidApiCalled={paidApiCalled}
                  setmessage={setmessage}
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}
