import React, { useEffect, useState } from "react";
import VideoIcon from "../../assets/images/icons/video.png";
import PdfIcon from "../../assets/images/icons/pdf.svg";
import xlsIcon from "../../assets/images/icons/xls.svg";
import jpegIcon from "../../assets/images/icons/jpeg.svg";
import docIcon from "../../assets/images/icons/doc.svg";
import txtIcon from "../../assets/images/icons/txt.svg";
import zipIcon from "../../assets/images/icons/zip.svg";
import pptIcon from "../../assets/images/icons/ppt.svg";
import otherIcon from "../../assets/images/icons/general-file.png";
import FileSaver from "file-saver";

export default function CardRow({ media, baseUrl }) {
  const fileTypes = ["ppt", "pdf", "xls", "jpeg", "doc", "txt", "zip"];
  const [fileImage, setfileImage] = useState("");

  useEffect(() => {
    if (media.type.includes("ppt")) {
      setfileImage(pptIcon);
    } else if (media.type == "pdf") {
      setfileImage(PdfIcon);
    } else if (media.type.includes("xls")) {
      setfileImage(xlsIcon);
    } else if (media.type.includes("jpeg")) {
      setfileImage(jpegIcon);
    } else if (media.type.includes("doc")) {
      setfileImage(docIcon);
    } else if (media.type.includes("txt")) {
      setfileImage(txtIcon);
    } else if (media.type.includes("zip")) {
      setfileImage(zipIcon);
    } else {
      setfileImage(otherIcon);
    }
  }, []);

  return (
    <div>
      <div
        className="mt-4 d-flex align-items-center gap-2"
        role="button"
        onClick={() => FileSaver.saveAs(media.location, media.filename)}
      >
        <img src={fileImage} style={{ heihgt: "20px", width: "20px" }} />
        <p className="p-0 m-0 fw-bolder text-decoration-underline">
          {media.filename}
        </p>
      </div>
    </div>
  );
}
