import React from "react";
import BoldIcon from "../../assets/images/icons/bold.svg";
import ItalicIcon from "../../assets/images/icons/italic.svg";
import UnderlineIcon from "../../assets/images/icons/underline.svg";
import TextLeftIcon from "../../assets/images/icons/left-align.svg";
import TextRightIcon from "../../assets/images/icons/right-align.svg";
import TextCenterIcon from "../../assets/images/icons/center-align.svg";

export default function NodeContextMenu({
  nodeWidth,
  handleAlignChange,
  setediting,
  // handleFormatClick
}) {
  return (
    <div
      role="button"
      className="mindmap-options-menu bg-white"
      style={{
        position: "absolute",
        top: "-40px",
        left: nodeWidth - nodeWidth / 3,
      }}
    >
      <div className="d-flex justify-content-between gap-1 node-context">
        <img
          src={TextLeftIcon}
          className="node-context img"
          role="button"
          onClick={() => handleAlignChange("start")}
        />

        <img
          src={TextCenterIcon}
          className="node-context img"
          role="button"
          onClick={() => handleAlignChange("center")}
        />

        <img
          src={TextRightIcon}
          className="node-context img"
          role="button"
          onClick={() => handleAlignChange("end")}
        />
      </div>
    </div>
  );
}
