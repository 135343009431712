import React, { useEffect, useState } from "react";
import PlusIcon from "../../assets/images/icons/plus-white.svg";
import MinusIcon from "../../assets/images/icons/minus.svg";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import CheckWhiteIcon from "../../assets/images/icons/check-white.svg";
import TodoCompleteSound from "../../assets/sounds/todo-completed.mp3";

export default function TodoItems({ tasks, settasks }) {
  const [completeTasksArray, setcompleteTasksArray] = useState([]);
  const [incompleteTasksArray, setincompleteTasksArray] = useState([]);
  const [showSubTaskIndex, setshowSubTaskIndex] = useState(-1);
  const [subtaskText, setsubtaskText] = useState("");
  const todoCompleteSound = new Audio(TodoCompleteSound);
  useEffect(() => {
    let finalIncomplete = [];
    let finalComplete = [];
    tasks.forEach((task) => {
      task.checked = false;
      task.subTasks.forEach((subTask) => {
        subTask.checked = subTask.completed;
      });
      if (task.completed) {
        finalComplete.push(task);
      } else {
        finalIncomplete.push(task);
      }
    });
    setcompleteTasksArray(finalComplete);
    setincompleteTasksArray(finalIncomplete);
  }, [tasks]);

  const handleUpdateTask = (text, completed) => {
    if (completed) {
      let tasksCopy = [...completeTasksArray];
      let mainTask = tasksCopy.find((task) => task.text == text);
      mainTask.completed = false;
      setcompleteTasksArray(tasksCopy);
    } else {
      let tasksCopy = [...incompleteTasksArray];
      let mainTask = tasksCopy.find((task) => task.text == text);
      mainTask.completed = true;
      setincompleteTasksArray(tasksCopy);
    }
    let tasksCopy = [...tasks];
    let task = tasksCopy.find((task) => task.text == text);
    task.checked = !task.checked;

    setTimeout(() => {
      settasks(tasksCopy);
      if (task.completed) {
        todoCompleteSound.play();
      }
    }, 600);
  };
  const handleDeleteAllTodos = () => {
    settasks(tasks.filter((task) => !task.completed));
  };
  const handleDeleteTask = (text) => {
    settasks(tasks.filter((task) => task.text != text));
  };
  const handleToggleSubtasks = (index, e) => {
    if (!e.target.classList.contains("task-change")) {
      setsubtaskText("");
      showSubTaskIndex == index
        ? setshowSubTaskIndex(-1)
        : setshowSubTaskIndex(index);
    }
  };

  const handleDeleteSubTask = (text, index) => {
    let tasksCopy = [...tasks];
    let mainTask = tasksCopy.find((task) => task.text == text);
    let filteredTasks = mainTask.subTasks.filter((subtask, i) => i != index);
    mainTask.subTasks = filteredTasks;

    settasks(tasksCopy);
  };
  const handleAddSubTask = (text) => {
    if (subtaskText) {
      setsubtaskText("");
      let tasksCopy = [...tasks];
      let todo = tasksCopy.find((task) => task.text == text);
      if (todo.subTasks.length < 3) {
        todo.subTasks.push({ text: subtaskText, completed: false });
      }

      settasks(tasksCopy);
    }
  };
  const handleCompleteSubTask = (text, index) => {
    let tasksCopy = [...tasks];
    let mainTask = tasksCopy.find((task) => task.text == text);
    mainTask.subTasks[index].completed = !mainTask.subTasks[index].completed;

    settasks(tasksCopy);
  };

  return (
    <div>
      <div className="px-3 my-5 text-start">
        <p
          className={`primary-text h5 fw-bold mb-4 text-nowrap text-uppercase`}
        >
          to do ({tasks.length - completeTasksArray.length})
        </p>
        <div className="px-4 text-white">
          {incompleteTasksArray.map((task, index) => (
            <div
              className={`main-task ${
                index < incompleteTasksArray.length - 1
                  ? "sample-main-task-after"
                  : ""
              } p-2`}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                role="button"
              >
                <div className="d-flex">
                  <input
                    id={task.text}
                    type="checkbox"
                    className="todo-check"
                    checked={task.checked}
                  />
                  <label
                    onClick={() => {
                      handleUpdateTask(task.text, task.completed);
                    }}
                    for={task._id}
                    className={`${
                      task.checked
                        ? "text-decoration-line-through text-secondary"
                        : ""
                    }`}
                    role="button"
                  >
                    <span
                      style={{
                        minWidth: "16px",
                        position: "relative",
                        borderRadius: "4px",
                      }}
                    ></span>
                    {task.text}
                  </label>
                </div>
                <div className="d-flex gap-2 task-handlers">
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{
                      height: "15px",
                      width: "15px",
                      border: "1px solid white",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                    onClick={(e) => handleToggleSubtasks(index, e)}
                  >
                    <img src={PlusIcon} />
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{
                      height: "15px",
                      width: "15px",
                      border: "1px solid #909090",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                    onClick={() => {
                      handleDeleteTask(task.text);
                    }}
                  >
                    <img src={MinusIcon} />
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "10%" }}>
                {task.subTasks.map((subTask, i) => (
                  <div
                    className="d-flex justify-content-between my-1 main-subtask align-items-center"
                    role="button"
                  >
                    <div
                      className="d-flex"
                      onClick={() => {
                        handleCompleteSubTask(task.text, i);
                      }}
                    >
                      <input
                        id={i}
                        type="checkbox"
                        className="todo-check"
                        checked={subTask.checked}
                      />
                      <label
                        for={subTask._id}
                        className={`my-1 ${
                          subTask.completed
                            ? " text-decoration-line-through text-secondary"
                            : ""
                        }`}
                        role="button"
                      >
                        <span
                          style={{
                            minWidth: "16px",
                            position: "relative",
                            borderRadius: "99px",
                          }}
                        ></span>
                        {subTask.text}
                      </label>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center subtask-handlers"
                      style={{
                        height: "15px",
                        width: "15px",
                        border: "1px solid #909090",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                      onClick={() => handleDeleteSubTask(task.text, i)}
                    >
                      <img src={MinusIcon} />
                    </div>
                  </div>
                ))}
                {showSubTaskIndex == index && (
                  <div className="d-flex justify-content-between my-2">
                    <div
                      className="d-flex align-items-center"
                      style={{ flex: 0.9 }}
                    >
                      <div
                        style={{
                          height: "14px",
                          width: "14px",
                          border: "1px solid white",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                      ></div>
                      <input
                        style={{ flex: 0.9 }}
                        type="text"
                        placeholder="Add Sub Task..."
                        className="secondary-input px-2 w-100"
                        onChange={(e) => setsubtaskText(e.target.value)}
                        value={subtaskText}
                        onKeyDown={(e) => {
                          handleEnterKeyPress(e, () => {
                            handleAddSubTask(task.text);
                          });
                        }}
                      />
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{
                        flex: 0.1,
                        height: "15px",
                        maxWidth: "15px",
                        width: "15px",
                        border: "1px solid #909090",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                    >
                      <img src={MinusIcon} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="px-3 text-start">
        <div className="d-flex justify-content-between">
          <p
            className={`primary-text h5 fw-bold mb-4 text-nowrap text-uppercase`}
          >
            completed ({tasks.length - incompleteTasksArray.length})
          </p>
          {completeTasksArray.length > 0 && (
            <p
              onClick={handleDeleteAllTodos}
              className={`primary-text small mb-4 text-nowrap clear-text`}
            >
              Clear All
            </p>
          )}
        </div>
        <div className="px-4 text-white">
          {completeTasksArray.map((task, index) => (
            <div>
              <div
                className={`main-task ${
                  index < completeTasksArray.length - 1
                    ? "sample-main-task-after"
                    : ""
                } p-2`}
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  role="button"
                >
                  <div className="d-flex">
                    <input
                      id={task.text}
                      type="checkbox"
                      className="todo-check"
                      checked={!task.checked}
                    />
                    <label
                      onClick={() => {
                        handleUpdateTask(task.text, task.completed);
                      }}
                      for={task._id}
                      className="text-decoration-line-through text-secondary"
                      role="button"
                    >
                      <span
                        style={{
                          minWidth: "16px",
                          position: "relative",
                          borderRadius: "4px",
                        }}
                      ></span>
                      {task.text}
                    </label>
                  </div>

                  <div className="d-flex gap-2 task-handlers">
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{
                        height: "15px",
                        width: "15px",
                        border: "1px solid #909090",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                      onClick={() => handleDeleteTask(task.text)}
                    >
                      <img src={MinusIcon} />
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "5%" }}>
                  {task.subTasks.map((subTask) => (
                    <div
                      className="d-flex justify-content-between my-1 main-subtask"
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex flex-column justify-content-center"
                          style={{
                            height: "14px",
                            width: "14px",
                            border: "1px solid white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        >
                          <img src={CheckWhiteIcon} />
                        </div>
                        <p
                          className={`m-0 p-0 px-2 text-decoration-line-through secondary-text text-break`}
                        >
                          {subTask.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
