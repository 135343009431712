import React, { useEffect, useState } from "react";

import {
  getSubjects as getSubjectsApi,
  getChapters as getChaptersApi,
  getSections as getSectionsApi,
  getAllQuestions as getAllQuestionsApi,
} from "../../api/questionBank";
import DropdownMenu from "../DropdownMenu";

export default function SelectSubject({
  subjects,
  setsubjects,
  setquestions,
  setloading,
  randomize,
  setrandomize,
}) {
  const [currSubject, setcurrSubject] = useState("");
  const [currSection, setcurrSection] = useState("");
  const [currChapter, setcurrChapter] = useState("");
  const [sections, setsections] = useState([]);
  const [chapters, setchapters] = useState([]);
  const [question, setquestion] = useState(null);
  const [loadingQuestion, setloadingQuestion] = useState(false);
  const [loadingSubjects, setloadingSubjects] = useState(false);
  const [loadingChapters, setloadingChapters] = useState(false);
  const [loadingSections, setloadingSections] = useState(false);

  const getQuestions = () => {
    if (currSubject && !loadingQuestion) {
      setloading(true);
      getAllQuestionsApi({
        sectionId: currSection,
        chapterId: currChapter,
        subjectId: currSubject,
        // limit: 1,
      })
        .then((res) => {
          if (randomize) {
            res.sort(() => Math.random() - 0.5);
          }
          setquestions(res);
          setquestion(res[0]);
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
          // console.log(err);
        });
    }
  };
  useEffect(() => {
    getQuestions();
  }, [currSubject, currChapter, currSection, randomize]);

  const getAllSubjects = () => {
    setloadingSubjects(true);
    getSubjectsApi()
      .then((res) => {
        setsubjects(res);
        if (res.length > 0) {
          setcurrSubject(res[0]._id);
        }
        setloadingSubjects(false);
      })
      .catch((err) => {
        setloadingSubjects(false);
      });
  };

  const getAllChapters = () => {
    setloadingChapters(true);
    getChaptersApi({ subjectId: currSubject })
      .then((res) => {
        setchapters(res);
        if (res.length > 0) {
          // setcurrChapter(res[0]._id);
        } else {
          setcurrChapter("");
          setsections([]);
        }
        setloadingChapters(false);
      })
      .catch((err) => {
        setloadingChapters(false);
      });
  };

  const getAllSections = () => {
    setloadingSections(true);
    getSectionsApi({ chapterId: currChapter })
      .then((res) => {
        setsections(res);
        if (res.length > 0) {
        } else {
          setcurrSection("");
        }
        setloadingSections(false);
      })
      .catch((err) => {
        setloadingSections(false);
      });
  };

  useEffect(() => {
    getAllSubjects();
  }, []);

  useEffect(() => {
    setchapters([]);
    setsections([]);
    setcurrChapter("");
    setcurrSection("");
    if (currSubject) {
      getAllChapters(currSubject);
    }
  }, [currSubject]);

  useEffect(() => {
    setsections([]);
    setcurrSection("");
    if (currChapter) {
      getAllSections(currChapter);
    }
  }, [currChapter]);

  return (
    <div>
      {loadingSubjects ? (
        <div className="mt-5 pt-3">
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      ) : subjects.length > 0 ? (
        <div className="m-auto database-filters-wrapper">
          <div className="d-flex gap-3 justify-content-center">
            <DropdownMenu
              mainItems={subjects}
              changeAction={setcurrSubject}
              defaultValue={null}
            />
            {currSubject && !loadingChapters && (
              <DropdownMenu
                mainItems={chapters}
                changeAction={setcurrChapter}
                defaultValue={{ name: "All chapters" }}
              />
            )}
            {currChapter && !loadingSections && (
              <DropdownMenu
                mainItems={sections}
                changeAction={setcurrSection}
                defaultValue={{ name: "All sections" }}
              />
            )}
          </div>
          <div>
            <div className="form-check form-switch d-flex justify-content-center mt-3 gap-2 p-0 m-0">
              <input
                className="form-check-input m-0 mt-1"
                style={{ height: "20px", width: "30px" }}
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                onClick={() => setrandomize(!randomize)}
              />
              <label
                className="form-check-label text-white fw-bold m-0 p-0 h5"
                for="flexSwitchCheckChecked"
                role="button"
              >
                Shuffle Cards
              </label>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-5">
          <h6 className="text-uppercase text-secondary">
            no subjects available
          </h6>
        </div>
      )}
    </div>
  );
}
