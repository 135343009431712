import React from "react";
import {
  Panel,
  useReactFlow,
  getRectOfNodes,
  getTransformForBounds,
} from "reactflow";
import { toPng, toCanvas, toSvg } from "html-to-image";
import ExportImg from "../../assets/images/icons/export.svg";
import jsPDF from "jspdf";

function DownloadButton({ mapId }) {
  const imageWidth = 1500;
  const imageHeight = 768;
  const { getNodes } = useReactFlow();
  const onClick = () => {
    const nodesBounds = getRectOfNodes(getNodes());
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.3,
      2
    );

    toCanvas(document.querySelector(".react-flow__viewport"), {
      width: imageWidth + 300,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${transform[0] + 300}px, ${
          transform[1]
        }px) scale(${transform[2]})`,
      },
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let pos = 0;
        const pdf = new jsPDF("l", "mm");
        pdf.addImage(
          imgData,
          "PNG",
          imgWidth / 4,
          imgHeight / 2,
          imgWidth,
          imgHeight
        );
        pdf.save(`${mapId}.pdf`);
      })
      .catch((err) => {
        console.warn("Downloading error: ", err);
      });
  };

  async function exportFlow() {
    // reactFlowInstance.fitView();

    function filter(node) {
      return node.tagName !== "i";
    }

    //get only printable elements by className
    let elements = document.getElementsByClassName("react-flow__renderer")[0];

    toSvg(elements, { filter }).then(async (svgContent) => {
      const svgElement = await decodeURIComponent(
        svgContent.replace(`data:image/svg+xml;charset=utf-8,`, "").trim()
      );

      const newWindow = window.open();
      newWindow.document.write(
        `<html>
   <head>
   <title>Flow.pdf</title>
   <style>
   @page {
     size: A4 landscape !important;
     margin:0 !important;
   }
   @media print {
   * {    
   -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
   color-adjust: exact !important;                 /*Firefox*/
    }
   }
   </style>
   </head>
   <body style="margin:60px 32px 32px 32px ">   
           ${svgElement}

   <script src="https://cdnjs.cloudflare.com/ajax/libs/canvg/1.5/canvg.min.js"></script>
   <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.4/jspdf.min.js"></script>
      
   <script>   
      const b = document.createElement("button");
      b.id = "downloadPdf";
      b.addEventListener("click",async function () {        

        // const svgElement = document.querySelector("svg");
        // const svgString = new XMLSerializer().serializeToString(svgElement);
        // const blob = new Blob([svgString], { type: 'image/svg+xml' });
        // const url = URL.createObjectURL(blob);
        
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = 'image.svg';
        // a.click();
        
        // URL.revokeObjectURL(url);        

        // const pdf = new jsPDF();            
        // const content = document.querySelector("svg");                      
        // await pdf.fromHTML(content, 1000, 1000);        
        // pdf.save('page.pdf');


        window.print();
        window.close();

      });
      b.click();      

  </script>
   </body>
   </html>`
      );
    });
  }

  return (
    <div
      className="d-flex align-items-center gap-1"
      role="button"
      onClick={exportFlow}
    >
      <img src={ExportImg} style={{ height: "22px" }} />
      <span className="text-dark fw-bold">Export</span>
    </div>
  );
}

export default DownloadButton;
