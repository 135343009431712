import React, { useEffect } from "react";

const Download = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.async = true;
    script.setAttribute("data-uid", "776a48b83f");
    script.src = "https://iamcastudent.ck.page/776a48b83f/index.js";

    const targetDiv = document.getElementById("download");

    // Append the script to the target div
    if (targetDiv) {
      targetDiv.appendChild(script);
    }

    // Cleanup: remove the script when the component unmounts
    return () => {
      if (targetDiv) {
        targetDiv.removeChild(script);
      }
    };
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      id="download"
    >
      {/* Your component content */}
    </div>
  );
};

export default Download;
