import React, { useEffect, useState } from "react";
import Card1Icon from "../assets/images/icons/home/card-1.svg";
import Card2Icon from "../assets/images/icons/home/card-2.svg";
import Card3Icon from "../assets/images/icons/home/card-3.svg";
import GetSetLine from "../assets/images/icons/home/get-set-line.svg";
import HomeAnimation from "../components/HomeAnimation";
import HomeDarkVec from "../assets/images/vectors/home-dark.svg";
import HomeDarkVec2 from "../assets/images/vectors/home-dark-2.svg";
import { Link, useLocation } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../assets/animations/example.json";
import axios from "axios";
import HomeSec1Img from "../assets/updated/home/sec1-1.png";
import HomeSec2Img from "../assets/updated/home/sec1-2.png";
import HomeSec3Img from "../assets/updated/home/sec1-3.png";
import RevCardsImg from "../assets/updated/home/rev-cards.png";
import MindmapImg from "../assets/updated/home/mindmap.png";
import RecallImg from "../assets/updated/home/recall.png";
import TodoImg from "../assets/updated/home/todo.png";
import UpArrow from "../assets/updated/home/up-arrow.svg";
import DownArrow from "../assets/updated/home/down-arrow.svg";
import DownArrow2 from "../assets/updated/home/down-arrow-2.svg";
import RightArrow from "../assets/updated/home/right-arrow.svg";
import TickIcon from "../assets/updated/home/tick.svg";
import LinesImg from "../assets/updated/home/lines.svg";
import Lines2Img from "../assets/updated/home/lines2.svg";
import FeedbackBack from "../assets/updated/home/feedback-back.svg";
import RightArrow2 from "../assets/updated/home/right-arrow2.svg";

export default function Home() {
  const location = useLocation();

  const [checks, setchecks] = useState([
    {
      checked: false,
      text: "I am afraid of scoring below 40 marks for passing or 60 marks for exemption",
    },
    {
      checked: false,
      text: "I'm eager to streamline and organize my study schedule effectively",
    },
    {
      checked: false,
      text: "I want to use Mind Maps for better understanding of a particular topic",
    },
    {
      checked: false,
      text: "Having Revision Cards accessible during Articleship or while on the move is something I'm looking forward to",
    },
    {
      checked: false,
      text: "I want to prepare small chapters very well, from which questions are asked in exam for 1, 2 or 4 marks",
    },
    {
      checked: false,
      text: "I want to understand and remember my theory chapters well until the exam",
    },
    {
      checked: false,
      text: "I quickly want to revise theory chapters just before the day of exam when time is more important",
    },
  ]);

  const [faqs, setfaqs] = useState([
    {
      question: "What is Finbite?",
      answer:
        "Finbite is a web-based revision tool for CA/CS and CMA students, offering tools like Revision Cards, Active Recall, Mind Map, and To-Do List.",
      active: false,
    },
    {
      question: "How can Finbite benefit me?",
      answer:
        "Finbite enhances exam preparation with tailored tools for diverse learning preferences, aiding in understanding, retention, and recall.",
      active: false,
    },
    {
      question: "What tools does Finbite offer?",
      answer:
        "Finbite provides Revision Cards, Active Recall Tool, Mind Maps, To-Do Lists, and more for effective and engaging exam revision.",
      active: false,
    },
    {
      question: "Is Finbite suitable for all exam levels?",
      answer: "Yes, Finbite caters to all levels of CA/CS and CMA exams.",
      active: false,
    },
    {
      question: "Can Finbite useful for students other than CA/CS/CMS?",
      answer:
        "Absolutely, any student can use these Revision Tools for their exam preparation.",
      active: false,
    },
    {
      question: "Can I access Finbite on different devices?",
      answer:
        "Absolutely, Finbite is accessible on desktops, laptops, tablets, and smartphones for flexible study sessions.",
      active: false,
    },
    {
      question: "How do I get started with Finbite?",
      answer:
        "Sign up on our website, explore revision tools, and start your preparation.",
      active: false,
    },
    {
      question: "Is an internet connection necessary to use Finbite?",
      answer: "Yes, since it is a web based tool, internet is necessary.",
      active: false,
    },
    {
      question: "Is there a cost for using Finbite?",
      answer:
        "Finbite offers free and premium plans, with basic features available for free for trial purpose and additional benefits in premium plans.",
      active: false,
    },
    {
      question: "How can I contact Finbite support?",
      answer:
        "Email hello@finbite.in for prompt assistance with any questions, feedback or suggestions.",
      active: false,
    },
  ]);

  const [feedbacks, setfeedbacks] = useState([]);

  useEffect(() => {
    const section = document.getElementById(location.hash.split("#")[1]);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.hash]);

  useEffect(() => {
    const data = [
      {
        heading: "Revolutionized My Study Routine!",
        description:
          "Revision Cards made my study sessions incredibly efficient. I could remember key points effortlessly, and it felt like I was playing a game while revising...",
        type: "CS Candidate",
      },
      {
        heading: "Structured Success with To-Do Lists",
        description:
          "To-Do Lists kept me on track and helped me tackle my study goals systematically. I felt more organized, and my productivity",
        type: "CA Candidate",
      },
      {
        heading: "Active Recall, Active Success",
        description:
          "Active Recall Tool challenged me to think critically about what I was learning. It enhanced my understanding and improved my exam score.",
        type: "CMA Candidate",
      },
    ];
    const groups = [];
    for (let i = 0; i < data.length; i += 3) {
      groups.push(data.slice(i, i + 3));
    }
    setfeedbacks(groups);

    // scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container font-jakarta home-wrapper d-flex flex-column">
      <div
        id="home"
        className="row align-items-center gap-3 gap-lg-0 home-sec-1"
        // style={{ minHeight: "calc(100vh - 165px)" }}
        // style={{ minHeight: "50vh" }}
      >
        <div className="col-12 col-lg-6 order-2 order-lg-1 text-start">
          <p className="fw-bold hero-text fw-bold text-nowrap">
            Elevate your success
            <br /> journey with an exclusive
            <br /> revision tool designed for
            <br /> <span className="fw-bold text-purple">CA/CS/CMA</span> exams
          </p>
          <p className="fw-semibold">
            Effective revision techniques for maximum success
          </p>
          <div className="d-flex gap-3">
            <Link to={"/user#signup"}>
              <button className="updated-btn border-0 h-36 px-3">
                Let's Start
              </button>
            </Link>
            <Link to={"/features"}>
              <button className="white-btn h-36 border-0 px-3">
                See Features
              </button>
            </Link>
          </div>
        </div>
        <div className="col-12 col-lg-6 order-1 order-lg-2">
          <div className="d-flex gap-3">
            <div
              style={{
                overflow: "hidden",
                borderRadius: "32px",
                width: "55%",
              }}
            >
              <img
                src={HomeSec1Img}
                className="home-sec1-img w-100 h-100 zoom-out"
              />
            </div>
            <div
              className="d-flex flex-column justify-content-between gap-3"
              style={{ width: "50%" }}
            >
              <div
                className="home-sec1-img"
                style={{
                  overflow: "hidden",
                  borderRadius: "32px",
                }}
              >
                <img
                  src={HomeSec2Img}
                  className="home-sec1-img zoom-in me-4 me-lg-0"
                  style={{ width: "100%" }}
                />
              </div>
              <div
                className="home-sec1-img"
                style={{
                  overflow: "hidden",
                  borderRadius: "32px",
                }}
              >
                <img
                  src={HomeSec3Img}
                  className="home-sec1-img zoom-in me-4 me-lg-0"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column" data-aos="fade-up">
        <p className="fw-bold h2 home-sec2-heading" id="revision">
          Smart tools that help you to ace your professional exam
        </p>
        <div className="row gap-4 gap-lg-0">
          <div className="col-12 col-lg-6">
            <div
              className="bg-offwhite d-flex flex-column justify-content-center home-feature-card"
              style={{
                borderRadius: "24px",
                minWidth: "fit-content",
              }}
            >
              <img
                src={RevCardsImg}
                className="w-auto"
                style={{ maxWidth: "100%" }}
              />
              <div className="position-relative">
                <p
                  className="font-indie small"
                  style={{ transform: "rotate(2.16deg)" }}
                >
                  Click on card <br /> to reveal answer
                </p>
                <img
                  src={UpArrow}
                  className="position-absolute"
                  style={{ top: "-55px", left: "35%" }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 text-start px-3 px-lg-5 d-flex flex-column justify-content-center">
            <div className="d-flex flex-column gap-4">
              <div className="d-flex gap-2 align-items-center position-relative">
                {/* <img src={LinesImg} /> */}
                <p className="fw-bold m-0 h2 text-purple">Revision Cards</p>
                {/* <img src={Lines2Img} /> */}
                {/* <img
                  src={DownArrow}
                  className="position-absolute"
                  style={{
                    height: "45px",
                    width: "45px",
                    top: "40px",
                    left: "-12px",
                  }}
                /> */}
              </div>

              <div className="d-flex gap-4">
                <img src={TickIcon} />
                <p className="m-0 h5 fw-normal">
                  Powerful tool for efficient revision
                </p>
              </div>
              <div className="d-flex gap-4">
                <img src={TickIcon} />
                <p className="m-0 h5 fw-normal">
                  Colorful and refreshing cards
                </p>
              </div>
              <div className="d-flex gap-4">
                <img src={TickIcon} />
                <p className="m-0 h5 fw-normal">
                  Unlock the power of recalling crucial information effortlessly
                </p>
              </div>
              <Link
                to={"/user#signup"}
                className="h5 fw-bold text-purple home-start-explore"
                style={{ textDecoration: "none" }}
              >
                Start exploring now <img src={RightArrow} />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-4 gap-lg-0" data-aos="fade-up">
        <div className="col-12 col-lg-6 text-start px-3 px-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex gap-2 align-items-center position-relative">
              {/* <img src={LinesImg} /> */}
              <p className="fw-bold m-0 h2 text-purple">Mind Maps</p>
              {/* <img src={Lines2Img} /> */}
              {/* <img
                src={DownArrow2}
                className="position-absolute"
                style={{
                  height: "45px",
                  width: "45px",
                  top: "24px",
                  left: "59%",
                }}
              /> */}
            </div>

            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Introduces a whole new dimension to your study experience
              </p>
            </div>
            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Exciting feature that enhances your learning journey
              </p>
            </div>
            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Unveils a unique approach to mind mapping for improved
                understanding
              </p>
            </div>
            <Link
              to={"/user#signup"}
              className="h5 fw-bold text-purple home-start-explore"
              style={{ textDecoration: "none" }}
            >
              Start exploring now <img src={RightArrow} />
            </Link>
          </div>
        </div>
        <div className="col-12 col-lg-6 order-1 order-lg-2">
          <div
            className="bg-offwhite home-feature-card d-flex flex-column justify-content-center"
            style={{ borderRadius: "24px" }}
          >
            <img
              src={MindmapImg}
              className="w-auto"
              style={{ maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>
      <div className="row gap-4 gap-lg-0" data-aos="fade-up">
        <div className="col-12 col-lg-6">
          <div
            className="bg-offwhite home-feature-card d-flex flex-column justify-content-center align-items-center"
            style={{ borderRadius: "24px" }}
          >
            <img
              src={RecallImg}
              // className="w-auto"
              style={{ maxWidth: "100%", width: "fit-content" }}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 text-start px-3 px-lg-5 d-flex flex-column justify-content-center">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex gap-2 align-items-center position-relative">
              {/* <img src={LinesImg} /> */}
              <p className="fw-bold m-0 h2 text-purple">Active Recall Tool</p>
              {/* <img src={Lines2Img} /> */}
              {/* <img
                src={DownArrow}
                className="position-absolute"
                style={{
                  height: "45px",
                  width: "45px",
                  top: "40px",
                  left: "-12px",
                }}
              /> */}
            </div>

            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Create questions based on topics you aim to conquer
              </p>
            </div>
            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Key tool for mastering subjects through knowledge retrieval
              </p>
            </div>
            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Encourages active interaction with the material in the form of
                short questions and answers
              </p>
            </div>
            <Link
              to={"/user#signup"}
              className="h5 fw-bold text-purple home-start-explore"
              style={{ textDecoration: "none" }}
            >
              Start exploring now <img src={RightArrow} />
            </Link>
          </div>
        </div>
      </div>
      <div className="row gap-4 gap-lg-0" data-aos="fade-up">
        <div className="col-12 col-lg-6 text-start px-3 px-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <div className="d-flex flex-column gap-4">
            <div className="d-flex gap-2 align-items-center position-relative">
              {/* <img src={LinesImg} /> */}
              <p className="fw-bold m-0 h2 text-purple">To Do List</p>
              {/* <img src={Lines2Img} /> */}
              {/* <img
                src={DownArrow2}
                className="position-absolute"
                style={{
                  height: "45px",
                  width: "45px",
                  top: "24px",
                  left: "42%",
                }}
              /> */}
            </div>

            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Organize your tasks, set priorities with to-do lists
              </p>
            </div>
            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Track your progress effectively with this specialized lists
              </p>
            </div>
            <div className="d-flex gap-4">
              <img src={TickIcon} />
              <p className="m-0 h5 fw-normal">
                Your guide to a more structured and productive study routine
              </p>
            </div>
            <Link
              to={"/user#signup"}
              className="h5 fw-bold text-purple home-start-explore"
              style={{ textDecoration: "none" }}
            >
              Start exploring now <img src={RightArrow} />
            </Link>
          </div>
        </div>
        <div className="col-12 col-lg-6 order-1 order-lg-2">
          <div
            className="bg-offwhite home-feature-card d-flex flex-column justify-content-center align-items-center"
            style={{ borderRadius: "24px" }}
          >
            <img
              src={TodoImg}
              style={{ maxWidth: "100%", width: "fit-content" }}
            />
          </div>
        </div>
      </div>
      <div className="text-start" data-aos="fade-up" id="why">
        <p className="h2 text-purple mb-5 why-heading position-relative">
          Why <span className="fw-bold">FINBITE ?</span>
        </p>
        <div>
          <p
            style={{ textAlign: "justify" }}
            className="h5 fw-normal why-description"
          >
            Revising for professional exams like{" "}
            <span className="fw-bold">CA</span>,{" "}
            <span className="fw-bold">CS</span> or{" "}
            <span className="fw-bold">CMA</span> is essential in order to do
            well in Examination. <span className="fw-bold">Finbite</span> is a
            tool designed by a Chartered Accountant from his own experience,
            where you can make sure that you go through and remember each and
            every corner of the book just before your exam date.
            <br />
            <br />
            Needless to say, one must focus on important topics prior to the
            exam day; however it is just as crucial to take into consideration
            those topics which may not seem as important but could still be
            asked by the examiner - ultimately that creates a great impact on
            whether one passes or fails in examination.
          </p>
        </div>
      </div>
      <div className="text-start" data-aos="fade-up">
        <p className="h2 text-purple mb-5 why-heading position-relative">
          Please check all boxes where your answer Is{" "}
          <span className="fw-bold">YES!</span>
        </p>
        <div className="d-flex flex-column gap-3">
          {checks.map((check, index) => (
            <div className="d-flex align-items-center">
              <input
                id={check.text}
                type="checkbox"
                className="home-todo-check"
                checked={check.checked}
                onClick={() => {
                  const checksCopy = [...checks];
                  const foundIndex = checksCopy.findIndex(
                    (ch) => ch.text == check.text
                  );
                  checksCopy[foundIndex].checked =
                    !checksCopy[foundIndex].checked;
                  setchecks(checksCopy);
                }}
              />
              <label
                for={check.text}
                className="text-dark h5 fw-normal"
                role="button"
              >
                <span
                  style={{
                    minWidth: "20px",
                    position: "relative",
                    borderRadius: "4px",
                  }}
                ></span>
                {check.text}
              </label>
              {/* <input
                id={index}
                type="checkbox"
                className="home-check"
                checked={check.checked}
                onClick={() => {
                  const checksCopy = [...checks];
                  const foundIndex = checksCopy.findIndex(
                    (ch) => ch.text == check.text
                  );
                  checksCopy[foundIndex].checked =
                    !checksCopy[foundIndex].checked;
                  setchecks(checksCopy);
                }}
              /> */}
              {/* <label
                for={index}
                className="text-dark h5 fw-normal"
                role="button"
              >
                <span
                  style={{
                    minWidth: "20px",
                    position: "relative",
                    borderRadius: "4px",
                  }}
                ></span>
                {check.text}
              </label> */}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-column" data-aos="fade-up">
        <div
          className="bg-purple px-5 custom-full-width-child"
          style={{ paddingBottom: "70px", paddingTop: "70px" }}
        >
          <p className="h5 text-white">
            Our tools aren't just for studying; they really help you succeed.{" "}
            <br />
            Many students like you have already seen how beneficial they are.
          </p>
        </div>
        <div
          id="feedback"
          className="custom-full-width-child px-5 position-relative overflow-hidden"
          style={{
            background: "#F7F7FB",
            paddingBottom: "100px",
            paddingTop: "100px",
          }}
        >
          <div className="w-100 h-100 position-absolute feedback-blur"></div>
          <img src={FeedbackBack} className="position-absolute feedback-back" />
          <div style={{ zIndex: 4, position: "relative" }}>
            <div
              id="carouselExampleControls"
              class="carousel slide"
              style={{ position: "static" }}
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                {feedbacks.map((feed, index) => (
                  <div class={`carousel-item ${index == 0 ? "active" : ""}`}>
                    <div className="row text-start mx-auto">
                      {feed.map((f) => (
                        <div className="col-12 col-sm-10 col-md-7 col-lg-4 mb-4 mb-lg-0 mx-auto">
                          <div
                            className="mx-auto card rounded-3 d-flex flex-column gap-2 feedback-card py-4 px-3 h-100 d-flex flex-column justify-content-between"
                            style={{ maxWidth: "400px" }}
                          >
                            <p className="fw-bold h5 m-0">"{f.heading}"</p>
                            <p className="text-gray3 m-0">{f.description}</p>
                            <p className="fw-bold small m-0">{f.type}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              {/* <button
              class="carousel-control-prev updated-btn"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
              style={{
                transform: "rotate(180deg)",
                opacity: 1,
                width: "42px",
                height: "42px",
                borderRadius: 100,
                top: "40%",
              }}
            >
              <img src={RightArrow2} />
              <span class="visually-hidden">Previous</span>
            </button> */}
              <button
                class="carousel-control-next updated-btn"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
                style={{
                  opacity: 1,
                  width: "42px",
                  height: "42px",
                  borderRadius: 100,
                  top: "40%",
                }}
              >
                <img src={RightArrow2} />
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="home-faq" data-aos="fade-up" id="faq">
        <p className="h2 text-start text-purple fw-bold mb-4 why-heading position-relative">
          Frequently Asked Questions (FAQ)
        </p>
        <div class="accordion" id="accordionExample">
          {faqs.map((faq, index) => (
            <div class="accordion-item py-3">
              <div class="accordion-header py-2" id={`heading${index}`}>
                <button
                  class="accordion-button bg-white border-0"
                  style={{ boxShadow: "none" }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index}`}
                  aria-expanded="false"
                  aria-controls={`collapse${index}`}
                >
                  <p
                    className={`h5 fw-semibold faq-heading ${
                      index == faqs.length - 1 ? "last-child" : ""
                    }`}
                  >
                    {faq.question}
                  </p>
                </button>
              </div>
              <div
                id={`collapse${index}`}
                class="accordion-collapse collapse border-none border-0"
                aria-labelledby={`headin${index}`}
                data-bs-parent="#accordionExample"
              >
                <p class="accordion-body h5 fw-normal text-start pt-4">
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="custom-full-width-child bg-purple2 px-5"
        style={{ paddingBottom: "100px", paddingTop: "100px" }}
        data-aos="fade-up"
      >
        <div className="container d-flex flex-column gap-4">
          <p className="fw-bold h2 text-purple">
            Are you ready to boost your exam preparation?
          </p>
          <p
            className="text-purple mx-auto h5 fw-normal"
            style={{ width: "77%" }}
          >
            Don't wait another moment. Invest in your future today and secure
            the top-quality exam tools that will transform your exam
            preparation.
          </p>
          <p className="h4 text-purple fw-bold">Start free trial today</p>
          <Link to={"/user#signup"}>
            <button
              className="updated-btn border-0 px-4 py-2 h-auto mx-auto"
              style={{ width: "fit-content" }}
            >
              Try it free
            </button>
          </Link>
        </div>
      </div>
      {/* <div className="home-main-wrapper">
      <div
        className="sec-1-vec position-absolute"
        style={{
          zIndex: -1,
          right: "0",
          top: "-30px",
        }}
      >
        <svg
          width="30vw"
          style={{ maxWidth: "500px", minWidth: "280px" }}
          height="80%"
          viewBox="0 0 243 582"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M271.177 -20C271.177 45.5243 271.177 70.284 271.177 149.958C271.177 218.425 289.582 568.417 245.623 575.36C62.0001 631.948 -21.0001 312.5 5.49997 149.958C33.2091 -20 153.706 -20 271.177 -20Z"
            fill="#F1F4FB"
          />
        </svg>
      </div>
      <div className="position-relative">
        <section>
          <div className="home-main-container container d-flex flex-column align-items-center align-items-md-end">
            <div className="d-flex flex-column flex-md-row justify-content-center gap-2 gap-md-5">
              <p className="h4 m-0 p-0 fw-bold text-dark-secondary text-wrap home-sec-1-heading mt-1">
                REVISION CARDS.
              </p>
              <p className="h4 m-0 p-0 fw-bold text-dark-secondary text-wrap home-sec-1-heading">
                TO DO.
              </p>
              <p className="h4 m-0 p-0 fw-bold text-dark-secondary text-wrap home-sec-1-heading">
                ACTIVE RECALL TOOL.
              </p>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-ened align-items-center">
              <p className="sec-1-by h3 fw-bold m-0 px-2">by</p>
              <div className="sec-1-iam-wrapper h-50">
                <p className="sec-1-iam px-3 small py-1 home-sec-1-sub-heading-2">
                  IAMCASTUDENT
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="home-mac d-block d-md-none my-4">
            <Lottie options={defaultOptions} height={200} width={200} />            
          </div>

          <div className="sec-2-main-wrapper">
            <div
              className="sec-2-wrapper text-start px-4 pt-4 py-md-5 position-relative"
              style={{ paddingBottom: "35px" }}
            >
              <div className="position-absolute home-mac d-none d-md-block">
                <Lottie options={defaultOptions} height={400} width={400} />                
              </div>
              <ul className="sec-2-list">
                <li className="sec-2-li">
                  Specifically designed for CA/CS/CMA Students
                </li>
                <li>To Do List</li>
                <li>Revision Cards</li>
                <li>Exam Days Counter</li>
                <li>Access to latest updates</li>
                <li>Active Recall Study technique</li>
              </ul>
              <div className="text-center text-md-start mt-4 pb-0 pb-md-5">
                <Link to="/user#signup">
                  <button className="btn start-btn px-5 fw-bold">
                    LET'S START
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <HomeAnimation />

        <div
          className="position-absolute w-100 h-100 home-vec-wrapper"
          style={{ zIndex: 2 }}
        >
          <div className="position-relative home-dark-vec-wrapper-1">
            <img src={HomeDarkVec} className="w-100" />
          </div>
          <div className="position-relative home-dark-vec-wrapper-2">
            <img src={HomeDarkVec2} className="w-100" />
          </div>
        </div>

        <div className="home-bg-dark position-relative">
          <div className="container position-relative" style={{ zIndex: 3 }}>
            <section>
              <p className="text-white h4 fw-bold py-0 pt-4 what-heading mb-5 mb-md-2">
                WHAT'S FOR YOU
              </p>
              <div className="row gap-5 m-auto text-center d-flex justify-content-center justify-content-lg-between">
                <div className="text-start col-10 col-sm-8 col-md-6 col-lg-3">
                  <div className="card card-1 h-100 mx-0 mx-xxl-3">
                    <div className="card-body">
                      <div className="card-1-img-wrapper py-2 text-center my-2">
                        <img src={Card1Icon} className="w-50 py-2" />
                      </div>
                      <p className="card-title h5 fw-bolder">Revision Cards</p>
                      <p className="card-text">
                        Using <span className="fw-bold">Revision Cards</span> is
                        an easy and quick way to check if you got the answer
                        correct. It's like playing cards and it's the best way
                        to remember all the points with very minimum efforts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-start col-10 col-sm-8 col-md-6 col-lg-3">
                  <div className="card card-2 h-100 mx-0 mx-xxl-3">
                    <div className="card-body">
                      <div className="card-2-img-wrapper py-2 text-center my-2">
                        <img src={Card2Icon} className="w-50 py-2" />
                      </div>
                      <p className="card-title h5 fw-bolder">To Do List</p>
                      <p className="card-text">
                        <span className="fw-bold">To-do lists</span> keep you
                        organized, help you set priorities, help you coordinate
                        similar tasks, and keep track of your progress.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-start col-10 col-sm-8 col-md-6 col-lg-3">
                  <div className="card card-3 h-100 mx-0 mx-xxl-3">
                    <div className="card-body">
                      <div className="card-3-img-wrapper py-2 text-center my-2">
                        <img src={Card3Icon} className="w-50 py-2" />
                      </div>
                      <p className="card-title h5 fw-bolder">
                        Active Recall Tool
                      </p>
                      <p className="card-text">
                        With <span className="fw-bold">Active Recall</span>, you
                        learn by retrieving information from your brain by
                        creating questions based on a topic you wish to learn.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Link to="/features">
                <button className="btn explore-btn px-4 py-2 my-5 fw-bold">
                  EXPLORE FEATURES
                </button>
              </Link>
            </section>
            <section className="text-start text-white">
              <p className="h5 why-heading mb-5">
                Why <span className="fw-bold">FINBITE ?</span>
              </p>
              <div>
                <p style={{ textAlign: "justify" }}>
                  Revising for professional exams like{" "}
                  <span className="fw-bold">CA</span>,{" "}
                  <span className="fw-bold">CS</span> or{" "}
                  <span className="fw-bold">CMA</span> is essential in order to
                  do well in Examination.{" "}
                  <span className="fw-bold">inbite</span> is a tool designed by
                  a Chartered Accountant from his own experience, where you can
                  make sure that you go through and remember each and every
                  corner of the book just before your exam date.
                  <br />
                  <br />
                  Needless to say, one must focus on important topics prior to
                  the exam day; however it is just as crucial to take into
                  consideration those topics which may not seem as important but
                  could still be asked by the examiner - ultimately that creates
                  a great impact on whether one passes or fails in examination.
                </p>
              </div>
            </section>
            <section className="text-start text-white">
              <p className="h5 how-heading mb-5 mt-5 pt-5">
                How to use Revision Cards with{" "}
                <span className="fw-bold">FINBITE ?</span>
              </p>
              <div>
                <div>
                  <div className="get-set-wrapper mt-4">
                    <span
                      className="px-4 py-1"
                      style={{ background: "#EB5757" }}
                    >
                      Get
                    </span>
                    <p className="mt-3">
                      Create Subject, Chapter and Section in{" "}
                      <span className="fw-bold">ADD QUESTIONS</span> tab and
                      type your Questions and Answers or alternatively you can
                      also import from Excel file
                    </p>
                    <img src={GetSetLine} className="get-set-line" />
                  </div>
                  <div className="get-set-wrapper mt-4">
                    <span
                      className="px-4 py-1"
                      style={{ background: "#F2C94C" }}
                    >
                      SET
                    </span>
                    <p className="mt-3">
                      You can view Questions and Answers in{" "}
                      <span className="fw-bold">DATABASE</span> tab which can be
                      edited or deleted as per your requirement{" "}
                    </p>
                    <img src={GetSetLine} className="get-set-line" />
                  </div>
                  <div className="get-set-wrapper mt-4">
                    <span
                      className="px-4 py-1"
                      style={{ background: "#00A07C" }}
                    >
                      GO
                    </span>
                    <p className="mt-3">
                      Go to <span className="fw-bold">REVISION CARDS</span> tab
                      Select Subject, Chapter and Section you want to revise,
                      and that’s all. Revision Cards are ready for revision{" "}
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="text-white mt-5 pt-5 pb-5">
              <p className="h4 fw-bold">
                ARE YOU READY TO BOOST YOUR EXAM PREPARATIONS?
              </p>
              <p className="h5">Start free trial today</p>
              <Link to="/user#signup">
                <button className="btn explore-btn px-4 py-2 mt-3 fw-bold">
                  I AM READY
                </button>
              </Link>
            </section>
          </div>
        </div>
      </div>
    </div> */}
    </div>
  );
}
