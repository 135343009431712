import { axiosClient } from "./axios";

export const getMindmap = async ({ mindmapId }) => {
  try {
    const response = await axiosClient.get(`/mindmap/single/${mindmapId}`);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const createMindmap = async ({ mindmapName, folderId, data }) => {
  try {
    const response = await axiosClient.post("/mindmap", {
      data,
      mindmapName,
      folderId,
    });

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteMindmap = async ({ mindmapId }) => {
  try {
    const response = await axiosClient.delete(`/mindmap/${mindmapId}`);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const cloneMindmap = async ({ mindmapId }) => {
  try {
    const response = await axiosClient.post(`/mindmap/clone/${mindmapId}`);

    return response.data;
  } catch (err) {
    console.log(err);
    throw err.response.data;
  }
};
export const udpateMindmap = async ({ mindmapId, data }) => {
  try {
    const response = await axiosClient.put(`/mindmap/${mindmapId}`, { data });

    return response.data;
  } catch (err) {
    console.log(err);
    throw err.response.data;
  }
};
