import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createChapter as createChapterApi } from "../../api/questionBank";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";

export default function AddChapterModal({
  subject,
  open,
  onCancel,
  onConfirm,
  setmessage,
}) {
  const [chapter, setchapter] = useState(null);
  const [apiCalled, setapiCalled] = useState(false);

  const handleAddChapter = () => {
    setapiCalled(true);
    if (chapter) {
      createChapterApi({
        chapterName: chapter,
        subjectId: subject._id,
      })
        .then((res) => {
          setapiCalled(false);
          onConfirm();
        })
        .catch((err) => {
          setapiCalled(false);
          setmessage({ message: err, success: false });
          onCancel();
        });
    }
  };

  return (
    <Modal centered isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center w-100 px-2 mt-2 mb-5">
          <p className="h4 fw-bold text-uppercase text-white">Add a chapter</p>
          <p className="fw-bold text-white" role="button" onClick={onCancel}>
            x
          </p>
        </div>
        <input
          type="text"
          className="form-control my-4 primary-input modal-input"
          placeholder="Subject..."
          value={subject.name}
          disabled
        />
        <input
          type="text"
          className="form-control my-4 primary-input modal-input"
          placeholder="Chapter..."
          onChange={(e) => {
            setchapter(e.target.value);
          }}
          onKeyDown={(e) => handleEnterKeyPress(e, handleAddChapter)}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className={`position-relative btn secondary-btn w-100`}
          style={{ height: "50px" }}
          onClick={!apiCalled && handleAddChapter}
        >
          <span className={`fw-bolder loading-text ${apiCalled ? "hide" : ""}`}>
            Add Chapter
          </span>
          {apiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
}
