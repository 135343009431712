import { axiosClient } from "./axios";

export const getUsers = async () => {
  try {
    const response = await axiosClient.get("/admin/user");

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateUser = async ({ userId }) => {
  try {
    const response = await axiosClient.put("/admin/user", { userId });

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
