import React, { useState, useEffect } from "react";

function BubbleAnimation() {
  const [bubbles, setBubbles] = useState([]);

  useEffect(() => {
    const bubbleTimeout = setTimeout(() => {
      clearInterval(interval);
    }, 3000);

    const interval = setInterval(() => {
      const height = Math.random() * (window.innerWidth / 20) + "px";
      const bubble = {
        id: new Date().getTime(),
        left: Math.random() * 100 + "%",
        bottom: "-20%",
        animationDuration: Math.random() + 1 + "s",
        height: height,
        width: height,
        // animationDelay: Math.random() + 1 + "s",
      };
      setBubbles((bubbles) => [...bubbles, bubble]);
    }, 20);

    return () => {
      clearInterval(interval);
      clearTimeout(bubbleTimeout);
    };
  }, []);

  function handleAnimationEnd(id) {
    setBubbles((bubbles) => bubbles.filter((bubble) => bubble.id !== id));
  }

  return (
    <div className="bubble-container">
      {bubbles.map((bubble) => (
        <div
          key={bubble.id}
          className="bubble"
          style={{
            left: bubble.left,
            animationDuration: bubble.animationDuration,
            animationDelay: bubble.animationDelay,
            height: bubble.height,
            width: bubble.width,
            minHeight: "20px",
            minWidth: "20px",
            minHeight: "35px",
            minWidth: "35px",
          }}
          onAnimationEnd={() => handleAnimationEnd(bubble.id)}
        ></div>
      ))}
    </div>
  );
}

export default BubbleAnimation;
