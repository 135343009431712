import React, { useState } from "react";

import { useLocalStorage } from "../hooks/useLocalStorage";
import { checkLogin } from "../api/user";

export const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useLocalStorage("user", null);
  const [showUserDate, setshowUserDate] = useLocalStorage(
    "showUserDate",
    false
  );
  const [darkMode, setdarkMode] = useState(true);

  function checkAuth() {
    checkLogin().then((activeUser) => {
      if (activeUser) {
        setCurrentUser(activeUser);
      } else {
        setCurrentUser(null);
      }
    });
  }

  const value = {
    currentUser,
    setCurrentUser,
    darkMode,
    showUserDate,
    setshowUserDate,
    setdarkMode,
    checkAuth,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export default AuthProvider;
