import logo from "./logo.svg";
import "./App.css";
import "./assets/css/updated.css";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import User from "./pages/user";
import MainRoutes from "./routes/MainRoutes";
import "./assets/css/app.css";
import { Fragment, useContext, useEffect } from "react";
import { AuthContext } from "./contexts/UserContext";
import EmailVerifictaion from "./pages/emailVerifictaion";
import ResetPassword from "./pages/ResetPassword";
import Home from "./pages/home";
import Features from "./pages/feature";
import Pricing from "./pages/pricing";
import HomeNavbar from "./components/navbar/HomeNavbar";
import Footer from "./components/Footer";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Refund from "./pages/refund";
import MindMap from "./pages/mindMap";
import RequireAuth from "./routes/RequireAuth";
import { ReactFlowProvider } from "reactflow";
import { AnimatePresence } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";
import Download from "./pages/download";
import Main from "./pages/main";

function App() {
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // }, [location.pathname]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <AnimatePresence>
        <Routes>
          <Route
            path="/user"
            element={!currentUser ? <User /> : <Navigate to="/dashboard" />}
          />
          <Route
            path="/verify-email/:confirmationToken/:userId"
            element={
              currentUser ? <EmailVerifictaion /> : <Navigate to="/user" />
            }
          />
          <Route
            path="/reset-password/:resetToken/:userId"
            element={<ResetPassword />}
          />
          <Route
            path="/"
            element={
              <>
                <Main />
                <Footer />
              </>
            }
          />
          <Route
            path="/revision-tool"
            element={
              <Fragment>
                <div>
                  <HomeNavbar></HomeNavbar>
                  <Home></Home>
                  <Footer></Footer>
                </div>
              </Fragment>
            }
          />
          <Route
            path="/features"
            element={
              <Fragment>
                <div>
                  <HomeNavbar></HomeNavbar>
                  <Features />
                  <Footer></Footer>
                </div>
              </Fragment>
            }
          />
          <Route
            path="/pricing"
            element={
              <Fragment>
                <div>
                  <HomeNavbar></HomeNavbar>
                  <Pricing></Pricing>
                  <Footer></Footer>
                </div>
              </Fragment>
            }
          />
          <Route
            path="/mindmap"
            element={
              <ReactFlowProvider>
                <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                  <MindMap />
                </RequireAuth>
              </ReactFlowProvider>
            }
          />
          <Route
            path="privacy"
            element={
              <>
                <HomeNavbar></HomeNavbar>
                <Privacy />
              </>
            }
          />
          <Route
            path="terms"
            element={
              <>
                <HomeNavbar></HomeNavbar>
                <Terms />
              </>
            }
          />
          <Route
            path="refund"
            element={
              <>
                <HomeNavbar></HomeNavbar>
                <Refund />
              </>
            }
          />
          <Route path="/download" element={<Download />} />
          <Route path="*" element={<MainRoutes />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
