import React, { Fragment, useContext, useEffect } from "react";
import Navbar from "../components/navbar/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import QuestionBank from "../pages/questionBank";
import Revision from "../pages/revision";
import ViewSections from "../pages/viewSections";
import ViewChapters from "../pages/viewChapters";
import RequireAuth from "./RequireAuth";
import Profile from "../pages/profile";
import UpgradePlan from "../pages/upgradePlan";
import Database from "../pages/database";
import Blog from "../pages/blog";
import Updates from "../pages/updates";
import Contact from "../pages/contact";
import { AuthContext } from "../contexts/UserContext";
import ManageUsers from "../pages/manageUsers";
import Coupons from "../pages/coupons";
import Checkout from "../pages/checkout";

export default function MainRoutes() {
  const location = useLocation();
  const { darkMode, setdarkMode, currentUser } = useContext(AuthContext);

  useEffect(() => {
    setdarkMode(true);
  }, [location]);

  return (
    <Fragment>
      <div
        className="position-relative"
        style={{
          minHeight: "100vh",
          overflowY: "hidden",
          background: darkMode ? "#262a41" : "#CBD5E0",
        }}
      >
        <Navbar />
        <div
          className="container main-wrapper mt-5"
          style={{
            position: "relative",
            minHeight: "75vh",
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route
              path="/question-bank"
              element={
                <RequireAuth
                  redirectTo="/user"
                  noPlanChildren="/upgrade-plan"
                  expiredRedirect={"/dashboard"}
                >
                  <QuestionBank />
                </RequireAuth>
              }
            ></Route>
            <Route
              path="/revision"
              element={
                <RequireAuth
                  redirectTo="/user"
                  noPlanChildren="/upgrade-plan"
                  expiredRedirect={"/dashboard"}
                >
                  <Revision />
                </RequireAuth>
              }
            />
            <Route
              path="/database"
              element={
                <RequireAuth
                  redirectTo="/user"
                  noPlanChildren="/upgrade-plan"
                  expiredRedirect={"/dashboard"}
                >
                  <Database />
                </RequireAuth>
              }
            />
            <Route
              path="/subject/:subId/view-chapters"
              element={
                <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                  <ViewChapters />
                </RequireAuth>
              }
            />
            <Route
              path="/subject/:subId/chapter/:chapId/view-sections"
              element={
                <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                  <ViewSections />
                </RequireAuth>
              }
            />
            <Route
              path="/profile"
              element={
                <RequireAuth
                  redirectTo="/user"
                  noPlanChildren="/upgrade-plan"
                  allowNoPlan={true}
                >
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/upgrade-plan"
              element={
                // <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                <UpgradePlan />
                // </RequireAuth>
              }
            />
            <Route
              path="/blog"
              element={
                <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                  <Blog />
                </RequireAuth>
              }
            />
            <Route
              path="/updates"
              element={
                <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                  <Updates />
                </RequireAuth>
              }
            />
            <Route
              path="/contact"
              element={
                // <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                <Contact />
                // </RequireAuth>
              }
            />
            <Route
              path="/manage-users"
              element={
                <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                  <ManageUsers />
                </RequireAuth>
              }
            />
            <Route
              path="/checkout/:id"
              allowNoPlan={true}
              element={
                <RequireAuth
                  redirectTo="/user"
                  noPlanChildren="/upgrade-plan"
                  allowNoPlan={true}
                >
                  <Checkout />
                </RequireAuth>
              }
            />
            <Route
              path="/coupons"
              element={
                <RequireAuth redirectTo="/user" noPlanChildren="/upgrade-plan">
                  <Coupons />
                </RequireAuth>
              }
            />

            {/* <Route path="*" element={<HomeRoutes />} /> */}
          </Routes>
        </div>
      </div>
    </Fragment>
  );
}
