import React, { useContext } from "react";
import { AuthContext } from "../../contexts/UserContext";
import MoonIcon from "../../assets/images/icons/moon.svg";
import SunIcon from "../../assets/images/icons/sun.svg";

export default function Options() {
  const { setdarkMode, darkMode } = useContext(AuthContext);
  return (
    <div className="text-white">
      <div className="form-check form-switch d-flex justify-content-center gap-2 p-0 m-0">
        <div role="button" onClick={() => setdarkMode(!darkMode)}>
          {!darkMode ? (
            <img src={MoonIcon} style={{ height: "27px" }} />
          ) : (
            <img src={SunIcon} style={{ height: "27px" }} />
          )}
        </div>
        {/* <input
          className="form-check-input m-0 mt-1"
          style={{ height: "20px", width: "30px" }}
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          onClick={() => setdarkMode(!darkMode)}
        />
        <label
          className={`form-check-label ${
            darkMode ? "text-white" : "text-dark"
          } fw-bold m-0 p-0 h5`}
          for="flexSwitchCheckChecked"
        >
          Light Mode
        </label> */}
      </div>
    </div>
  );
}
