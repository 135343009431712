import React, { useContext, useEffect, useState } from "react";
import styles from "../assets/css/dropdown.module.css";
import { AuthContext } from "../contexts/UserContext";

export default function DropdownMenu({
  mainItems,
  changeAction,
  defaultValue,
}) {
  const [showDropdown, setshowDropdown] = useState(false);
  const [items, setitems] = useState([]);
  const [currentValue, setcurrentValue] = useState(
    defaultValue ? defaultValue : mainItems[0]
  );

  const handleCurrentValue = (value) => {
    changeAction(value?._id);
    setshowDropdown(false);
    setcurrentValue(value);
  };
  const handleToggle = () => {
    setshowDropdown(!showDropdown);
  };

  useEffect(() => {
    setitems(mainItems);
  }, []);

  return (
    <div className="position-relative" style={{ zIndex: 3 }}>
      <div>
        <button
          className={`${styles.mainButton} form-control d-flex justify-content-between bg-dark dropdown-text`}
          onClick={handleToggle}
        >
          <span
            className={`${styles.dropdownTitle} ${styles.textTruncate} fw-bolder`}
          >
            {currentValue?.name}
          </span>
          <span
            className={`${styles.dropdownArrow} ${
              showDropdown ? styles.active : ""
            }`}
          >
            <svg
              className={`${styles.svg}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
            >
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
            </svg>
          </span>
        </button>
        <div
          className={`position-absolute w-100 text-start bg-white mt-2 rounded ${
            styles.toggler
          } ${showDropdown ? styles.show : styles.hide}`}
          style={{ minWidth: "fit-content" }}
        >
          {defaultValue && showDropdown && (
            <div
              role="button"
              className={`py-2 px-2 ${styles.dropdownItem} rounded h6 mb-0 text-uppercase`}
              style={{ whiteSpace: "nowrap" }}
              onClick={() =>
                handleCurrentValue({ name: defaultValue?.name, _id: "" })
              }
            >
              {defaultValue.name}
            </div>
          )}
          {items.map((item) => {
            if (item)
              return (
                <div
                  role="button"
                  className={`py-2 px-2 ${styles.dropdownItem} rounded h6 mb-0`}
                  onClick={() => handleCurrentValue(item)}
                >
                  <span style={{ wordBreak: "break-word" }}>{item?.name}</span>
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
}
