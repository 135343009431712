import { axiosClient } from "./axios";

export const login = async ({ email, password }) => {
  try {
    let response = await axiosClient.post("/user/login", { email, password });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const checkLogin = async () => {
  try {
    let response = await axiosClient.post("/user/check-login");
    return response.data;
  } catch (err) {
    // console.log(err);
    throw err.response.data;
  }
};

export const register = async ({ name, email, password, examDate }) => {
  try {
    let response = await axiosClient.post("/user/register", {
      username: name,
      email,
      password,
      examDate,
      isVerified: false,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const logout = async () => {
  try {
    let response = await axiosClient.post("/user/logout");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateProfile = async ({ name, examDate }) => {
  try {
    let response = await axiosClient.put("/user/update-profile", {
      name,
      examDate,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const upgradePlan = async () => {
  try {
    let response = await axiosClient.put("/user/plan");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updatePassword = async ({ currentPassword, newPassword }) => {
  try {
    let response = await axiosClient.put("/user/password", {
      currentPassword,
      newPassword,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const sendEmailVerificationRequest = async () => {
  try {
    let response = await axiosClient.post("/user/verify-email");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const verifyEmail = async ({ token, userId }) => {
  try {
    let response = await axiosClient.put("/user/verify-email", {
      token,
      userId,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const requestResetPassword = async ({ email }) => {
  try {
    let response = await axiosClient.post("/user/reset-password", {
      email,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const resetPassword = async ({ userId, token, password }) => {
  try {
    let response = await axiosClient.put("/user/reset-password", {
      userId,
      token,
      password,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteUser = async () => {
  try {
    let response = await axiosClient.delete(`/user`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
