import React, { useEffect, useRef, useState } from "react";
import {
  getAllQuestions as getAllQuestionsApi,
  createQuestion as createQuestionApi,
  updateQuestion as updateQuestionApi,
  deleteQuestion as deleteQuestionApi,
  addFile as addFileApi,
} from "../../api/questionBank";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import Message from "../Message";
import ImportFileModal from "../modals/ImportFileModal";

export default function AddQuestions({
  subjectName,
  chapterName,
  section,
  setcurrStep,
}) {
  const [currQuestion, setcurrQuestion] = useState("");
  const [currAnswer, setcurrAnswer] = useState("");
  const [addQuestionApiCalled, setaddQuestionApiCalled] = useState(false);
  const [file, setfile] = useState(null);
  const [message, setmessage] = useState("");
  const [submitQuestionApiCalled, setsubmitQuestionApiCalled] = useState(false);
  const [fileImportModalOpen, setfileImportModalOpen] = useState(false);

  useEffect(() => {
    if (!section._id) {
      setcurrStep(3);
    }
  }, []);

  const handleSubmitQuestion = () => {
    if (currQuestion && currAnswer && !addQuestionApiCalled) {
      setsubmitQuestionApiCalled(true);
      createQuestionApi({
        question: currQuestion,
        answer: currAnswer,
        sectionId: section._id,
      })
        .then((res) => {
          setcurrQuestion("");
          setcurrAnswer("");
          setmessage({
            message: "Question and answer added successfully",
            success: true,
          });
          setsubmitQuestionApiCalled(false);
        })
        .catch((err) => {
          setmessage({
            message: "Question already exists",
            success: false,
          });
          setcurrQuestion("");
          setcurrAnswer("");
          setsubmitQuestionApiCalled(false);
        });
    }
  };

  const handleFileUpload = () => {
    if (!file) {
      return;
    }
    const fileExtension = file.name.split(".")[file.name.split(".").length - 1];
    if (fileExtension != "xlsx") {
      setfile(null);
      return;
    }
    setaddQuestionApiCalled(true);
    addFileApi({ file, secId: section._id })
      .then((res) => {
        setaddQuestionApiCalled(false);
        setmessage({
          message: "File imported successfully",
          success: true,
        });
        setfileImportModalOpen(false);
      })
      .catch((err) => {
        setaddQuestionApiCalled(false);
        setmessage({
          message: "Error occured while uploading questions",
          success: true,
        });
        setfileImportModalOpen(false);
      });
  };

  // useEffect(() => {
  //   handleFileUpload();
  // }, [file]);

  return (
    <div>
      {fileImportModalOpen && (
        <ImportFileModal
          open={true}
          onCancel={() => setfileImportModalOpen(false)}
          addQuestionApiCalled={addQuestionApiCalled}
          onConfirm={handleFileUpload}
          setfile={setfile}
        />
      )}
      <div>
        <div>
          <p className="text-uppercase text-white">
            <span className="h6 fw-bolder">Selection: </span>{" "}
            <span>{subjectName}</span> {" > "}
            <span>{chapterName}</span>
            {" > "}
            <span>{section.name}</span>
          </p>
        </div>
        <div className="question-bank-wrapper">
          <input
            type="text"
            placeholder="ADD QUESTION..."
            className="form-control mt-4 primary-input modal-input form-control-lg lg-input-placeholder"
            value={currQuestion}
            onChange={(e) => setcurrQuestion(e.target.value)}
          />
          <input
            type="text"
            placeholder="ADD ANSWER..."
            className="form-control mt-4 primary-input modal-input form-control-lg lg-input-placeholder"
            value={currAnswer}
            onChange={(e) => setcurrAnswer(e.target.value)}
            onKeyDown={(e) => handleEnterKeyPress(e, handleSubmitQuestion)}
          />
          <div className="d-flex justify-content-between mt-4 gap-3 gap-lg-4 flex-wrap flex-lg-nowrap">
            <button
              className={`btn secondary-btn fw-bolder text-uppercase loading-button lg-btn ${
                submitQuestionApiCalled ? "activeLoading" : ""
              }`}
              style={{ minHeight: "50px" }}
              onClick={!submitQuestionApiCalled && handleSubmitQuestion}
            >
              <span
                className={`loading-text fw-bolder small ${
                  submitQuestionApiCalled ? "hide" : ""
                }`}
              >
                Enter
              </span>
              <div className="loading-4 loading">
                <span className="span1"></span>
                <span className="span2"></span>
                <span className="span3"></span>
              </div>
            </button>

            <button
              className={`position-relative btn btn-import w-100 text-uppercase fw-bolder lg-btn`}
              onClick={() => setfileImportModalOpen(true)}
            >
              <div
                className={`loading-text ${addQuestionApiCalled ? "hide" : ""}`}
              >
                import excel file
              </div>
              {addQuestionApiCalled && (
                <div className="loading-4 loading">
                  <span className="span1"></span>
                  <span className="span2"></span>
                  <span className="span3"></span>
                </div>
              )}
            </button>
          </div>
          {message && (
            <div className="mt-5 pb-2">
              <Message message={message} setmessage={setmessage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
