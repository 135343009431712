import React, { useEffect, useState } from "react";
import PlusIcon from "../../assets/images/icons/plus-white.svg";
import MinusIcon from "../../assets/images/icons/minus.svg";
import CheckWhiteIcon from "../../assets/images/icons/check-white.svg";
import {
  deleteTodo as deleteTodoApi,
  updateTodo as updateTodoApi,
  addSubTask as addSubTaskApi,
  deleteSubTask as deleteSubTaskApi,
  updateSubTask as updateSubTaskApi,
  deleteAllTodos as deleteAllTodosApi,
} from "../../api/todos";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import styles from "../../assets/css/todo.module.css";
import Message from "../Message";
import TodoCompleteSound from "../../assets/sounds/todo-completed.mp3";

export default function TodoListMain({
  setcompletion,
  tasks,
  settasks,
  addTaskApiCalled,
  setaddTaskApiCalled,
  getAllTasks,
  completeTasksArray,
  incompleteTasksArray,
  setincompleteTasksArray,
  setcompleteTasksArray,
}) {
  const [showSubTaskIndex, setshowSubTaskIndex] = useState(-1);
  const [subtaskText, setsubtaskText] = useState("");
  const [updateTaskApiCalled, setupdateTaskApiCalled] = useState(false);
  const [message, setmessage] = useState(null);
  const [totalTasks, settotalTasks] = useState(0);
  const [completedTasks, setcompletedTasks] = useState(0);
  const todoCompleteSound = new Audio(TodoCompleteSound);

  const handleTaskCompletion = () => {
    console.log(tasks);
    let finalTotalTasks = 0;
    let finalCompletedTasks = 0;
    let finalTotalMainTasks = 0;
    let finalCompletedMainTasks = 0;
    if (tasks.length > 0) {
      tasks.forEach((task) => {
        finalTotalMainTasks++;
        if (task.completed) {
          finalCompletedMainTasks++;
        }

        if (task.subTasks.length > 0) {
          finalTotalTasks += task.subTasks.length;
          if (task.completed) {
            finalCompletedTasks += task.subTasks.length;
          } else {
            task.subTasks.forEach((subTask) => {
              if (subTask.completed) {
                finalCompletedTasks++;
              }
            });
          }
        } else {
          finalTotalTasks++;
          if (task.completed) {
            finalCompletedTasks++;
          }
        }
      });
      setcompletion(Math.floor((finalCompletedTasks / finalTotalTasks) * 100));
    } else {
      setcompletion(100);
    }

    settotalTasks(finalTotalMainTasks);
    setcompletedTasks(finalCompletedMainTasks);
  };

  const handleUpdateTask = ({ id, color, completed }) => {
    let tasksCopy = [...tasks];
    let tas = tasksCopy.find((task) => task._id == id);
    tas.completed = completed;
    tas.checked = !tas.checked;
    if (completed) todoCompleteSound.play();
    setTimeout(() => {
      tasksCopy.forEach((task) => {
        task.checked = false;
        task.subTasks.forEach((subTask) => {
          subTask.checked = subTask.completed;
        });
      });
      setcompleteTasksArray(tasksCopy.filter((task) => task.completed == true));
      setincompleteTasksArray(
        tasksCopy.filter((task) => task.completed == false)
      );
      settasks(tasksCopy);
    }, 500);

    // setTimeout(() => {
    if (!updateTaskApiCalled) {
      setupdateTaskApiCalled(true);

      updateTodoApi({
        todoId: id,
        completed: completed,
        color: color ? color : "",
      })
        .then((res) => {
          setupdateTaskApiCalled(false);
          // getAllTasks();
        })
        .catch((err) => {
          setupdateTaskApiCalled(false);
        });
    }
    // }, 300);
  };

  const handleAddSubTask = (id) => {
    if (subtaskText && !addTaskApiCalled) {
      setaddTaskApiCalled(true);
      let tasksCopy = [...tasks];
      let todo = tasksCopy.find((tas) => tas._id == id);
      todo.subTasks.push({
        text: subtaskText,
        completed: false,
        checked: false,
      });
      settasks(tasksCopy);
      setsubtaskText("");

      addSubTaskApi({ todoId: id, todoText: subtaskText })
        .then((res) => {
          // getAllTasks();
          setaddTaskApiCalled(false);
        })
        .catch((err) => {
          todo.subTasks.pop();
          settasks(tasksCopy);
          setmessage({ message: err, success: false });
          setaddTaskApiCalled(false);
        });
    }
  };
  const handleCompleteSubTask = (id, text, completed) => {
    if (!updateTaskApiCalled) {
      let tasksCopy = [...tasks];
      let todo = tasksCopy.find((tas) => tas._id == id);

      let subTask = todo.subTasks.find((to) => to.text == text);
      subTask.completed = !completed;
      subTask.checked = !completed;
      settasks(tasksCopy);

      setsubtaskText("");

      if (!completed) {
        todoCompleteSound.play();
      }

      if (!todo.completed) {
        updateSubTaskApi({
          todoId: todo._id,
          todoText: text,
          completed: !completed,
        })
          .then((res) => {
            // getAllTasks();
            setupdateTaskApiCalled(false);
          })
          .catch((err) => {
            setupdateTaskApiCalled(false);
          });
      }
    }
  };

  const handleToggleSubtasks = (index, e) => {
    if (!e.target.classList.contains("task-change")) {
      setsubtaskText("");
      showSubTaskIndex == index
        ? setshowSubTaskIndex(-1)
        : setshowSubTaskIndex(index);
    }
  };

  const handleDeleteTask = (id) => {
    settasks((tasks) => tasks.filter((task) => task._id != id));
    setincompleteTasksArray((tas) => tas.filter((t) => t._id != id));
    setcompleteTasksArray((tas) => tas.filter((t) => t._id != id));

    deleteTodoApi({ todoId: id })
      .then((res) => {
        // getAllTasks();
      })
      .catch((err) => {});
  };

  const handleDeleteSubTask = (id, text) => {
    let tasksCopy = [...tasks];
    let todo = tasksCopy.find((tas) => tas._id == id);
    todo.subTasks = todo.subTasks.filter((to) => to.text != text);
    settasks(tasksCopy);

    deleteSubTaskApi({ todoId: id, todoText: text })
      .then((res) => {
        // getAllTasks();
      })
      .catch((err) => {});
  };

  const handleDeleteAllTodos = () => {
    settasks((tas) => tas.filter((task) => task.completed == false));
    setcompleteTasksArray([]);
    deleteAllTodosApi()
      .then((res) => {
        // getAllTasks();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    handleTaskCompletion();
  }, [tasks]);

  useEffect(() => {
    getAllTasks();
  }, []);

  return (
    <div>
      {message && <Message message={message} setmessage={setmessage} />}
      <div className="px-md-3 text-start">
        <p
          className={`primary-text h5 fw-bold mb-4 text-nowrap text-uppercase ${styles.mainHeading}`}
        >
          to do ({totalTasks - completedTasks})
        </p>
        <div className="px-md-4 text-white">
          {incompleteTasksArray.map((task, index) => (
            <div
              className={`main-task ${
                index < incompleteTasksArray.length - 1 ? "main-task-after" : ""
              } p-2`}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                role="button"
              >
                <div className="d-flex">
                  <input
                    id={task._id}
                    type="checkbox"
                    className="todo-check"
                    checked={task.checked}
                    onClick={() => {
                      handleUpdateTask({
                        id: task._id,
                        color: task.color,
                        completed: !task.completed,
                      });
                    }}
                  />
                  <label
                    for={task._id}
                    className={`${
                      task.checked
                        ? "text-decoration-line-through text-secondary"
                        : ""
                    }`}
                    style={{ wordBreak: "break-all" }}
                    role="button"
                  >
                    <span
                      style={{
                        minWidth: "16px",
                        position: "relative",
                        borderRadius: "4px",
                      }}
                    ></span>
                    {task.text}
                  </label>
                </div>
                <div className="d-flex gap-2 task-handlers">
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{
                      height: "15px",
                      width: "15px",
                      border: "1px solid white",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                    onClick={(e) => handleToggleSubtasks(index, e)}
                  >
                    <img src={PlusIcon} />
                  </div>
                  <div
                    className="d-flex flex-column justify-content-center"
                    style={{
                      height: "15px",
                      width: "15px",
                      border: "1px solid #909090",
                      borderRadius: "50%",
                      padding: "2px",
                    }}
                    onClick={() => {
                      handleDeleteTask(task._id);
                    }}
                  >
                    <img src={MinusIcon} />
                  </div>
                </div>
              </div>
              <div style={{ marginLeft: "10%" }}>
                {task.subTasks.map((subTask) => (
                  <div
                    className="d-flex justify-content-between my-1 main-subtask align-items-center"
                    role="button"
                  >
                    <div
                      className="d-flex"
                      onClick={() => {
                        handleCompleteSubTask(
                          task._id,
                          subTask.text,
                          subTask.completed
                        );
                      }}
                    >
                      <input
                        id={subTask._id}
                        type="checkbox"
                        className="todo-check"
                        checked={subTask.checked}
                      />
                      <label
                        for={subTask._id}
                        className={`my-1 ${
                          subTask.completed
                            ? " text-decoration-line-through text-secondary"
                            : ""
                        }`}
                        role="button"
                        style={{ wordBreak: "break-all" }}
                      >
                        <span
                          style={{
                            minWidth: "16px",
                            position: "relative",
                            borderRadius: "99px",
                          }}
                        ></span>
                        {subTask.text}
                      </label>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center subtask-handlers"
                      style={{
                        height: "15px",
                        width: "15px",
                        border: "1px solid #909090",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                      onClick={() =>
                        handleDeleteSubTask(task._id, subTask.text)
                      }
                    >
                      <img src={MinusIcon} />
                    </div>
                  </div>
                ))}
                {showSubTaskIndex == index && (
                  <div className="d-flex justify-content-between my-2">
                    <div
                      className="d-flex align-items-center"
                      style={{ flex: 0.9 }}
                    >
                      <div
                        style={{
                          height: "14px",
                          width: "14px",
                          border: "1px solid white",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                      ></div>
                      <input
                        style={{ flex: 0.9, outline: "none" }}
                        type="text"
                        placeholder="Add Sub Task..."
                        className="secondary-input px-2 w-100"
                        y
                        onChange={(e) => setsubtaskText(e.target.value)}
                        value={subtaskText}
                        onKeyDown={(e) => {
                          handleEnterKeyPress(e, () => {
                            handleAddSubTask(task._id);
                          });
                        }}
                      />
                    </div>
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{
                        flex: 0.1,
                        height: "15px",
                        maxWidth: "15px",
                        width: "15px",
                        border: "1px solid #909090",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                    >
                      <img src={MinusIcon} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="px-md-3 text-start">
        <div className="d-flex justify-content-between">
          <p
            className={`primary-text h5 fw-bold mb-4 text-nowrap text-uppercase ${styles.mainHeading}`}
          >
            completed ({completedTasks})
          </p>
          {completeTasksArray.length > 0 && (
            <p
              onClick={handleDeleteAllTodos}
              className={`primary-text small mb-4 text-nowrap clear-text ${styles.mainHeading}`}
            >
              Clear All
            </p>
          )}
        </div>
        <div className="px-md-4 text-white">
          {completeTasksArray.map((task, index) => (
            <div>
              <div
                className={`main-task ${
                  index < completeTasksArray.length - 1 ? "main-task-after" : ""
                } p-2`}
              >
                <div
                  className="d-flex justify-content-between align-items-center"
                  role="button"
                >
                  <div className="d-flex">
                    <input
                      id={task._id}
                      type="checkbox"
                      className="todo-check"
                      checked={!task.checked}
                      onClick={() => {
                        handleUpdateTask({
                          id: task._id,
                          color: task.color,
                          completed: !task.completed,
                        });
                      }}
                    />
                    <label
                      for={task._id}
                      className="text-decoration-line-through text-secondary"
                      role="button"
                      style={{ wordBreak: "break-all" }}
                    >
                      <span
                        style={{
                          minWidth: "16px",
                          position: "relative",
                          borderRadius: "4px",
                        }}
                      ></span>
                      {task.text}
                    </label>
                  </div>

                  <div className="d-flex gap-2 task-handlers">
                    <div
                      className="d-flex flex-column justify-content-center"
                      style={{
                        height: "15px",
                        width: "15px",
                        border: "1px solid #909090",
                        borderRadius: "50%",
                        padding: "2px",
                      }}
                      onClick={() => handleDeleteTask(task._id)}
                    >
                      <img src={MinusIcon} />
                    </div>
                  </div>
                </div>
                <div style={{ marginLeft: "5%" }}>
                  {task.subTasks.map((subTask) => (
                    <div
                      className="d-flex justify-content-between my-1 main-subtask"
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="d-flex flex-column justify-content-center"
                          style={{
                            minHeight: "14px",
                            minWidth: "14px",
                            height: "14px",
                            width: "14px",
                            border: "1px solid white",
                            borderRadius: "50%",
                            padding: "2px",
                          }}
                        >
                          <img src={CheckWhiteIcon} />
                        </div>
                        <p
                          className={`m-0 p-0 px-2 text-decoration-line-through secondary-text text-break`}
                        >
                          {subTask.text}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
