import React from "react";
import Moment from "react-moment";

export default function ViewPlan({ plan }) {
  return (
    <div>
      <div className="mb-4">
        <p className="text-start text-white">Current Plan</p>
        <input
          type="text"
          className="form-control primary-input text-capitalize"
          value={plan.plan}
          readOnly
        />
      </div>

      {plan.plan == "paid" && (
        <div className="d-flex flex-column gap-4">
          <div>
            <p className="text-start text-white">Amount (INR)</p>
            <input
              type="text"
              className="form-control primary-input"
              value={plan.amount / 100}
              readOnly
            />
          </div>
          <div>
            <p className="text-start text-white">Expires on</p>
            <div className="text-start primary-input d-flex flex-column justify-content-center px-2">
              <Moment format="DD MMM, YYYY">{plan.expires}</Moment>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
