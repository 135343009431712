import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import styles from "../../assets/css/login.module.css";
import GoogleIcon from "../../assets/images/icons/google-icon.svg";
import { login } from "../../api/user";
import { AuthContext } from "../../contexts/UserContext";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import Message from "../Message";
import { motion } from "framer-motion";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";

export default function Login({ rightPanelActive, togglePanel }) {
  const location = useLocation();
  const { setCurrentUser } = useContext(AuthContext);
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const [message, setmessage] = useState("");
  const [signinApiCalled, setsigninApiCalled] = useState(false);
  const [forgotModalOpen, setforgotModalOpen] = useState(false);
  const [isValid, setisValid] = useState(false);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setemail(newEmail);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setisValid(emailRegex.test(newEmail));
  };

  const navigate = useNavigate();
  const handleSubmit = () => {
    if (email && password) {
      setsigninApiCalled(true);
      login({ email, password })
        .then((res) => {
          setsigninApiCalled(false);
          setCurrentUser(res);
          navigate("/dashboard");
        })
        .catch((err) => {
          setsigninApiCalled(false);
          setmessage({ message: err, success: false });
        });
    }
  };

  useEffect(() => {
    if (location.hash == "#failed") {
      setmessage({ message: "Password reset failed", success: false });
    } else if (location.hash == "#success") {
      setmessage({ message: "Password reset successful", success: true });
    }
  }, []);

  return (
    <motion.div
      className="w-75 mx-auto d-flex flex-column justify-content-md-center h-100 login-right position-relative"
      style={{ maxWidth: "500px" }}
      initial={{ opacity: 0, top: "20vh" }}
      animate={{
        opacity: 1,
        top: "0vh",
      }}
      transition={{ duration: 0.5, delay: 0.5 }}
    >
      {message && (
        <div className="login-error w-100" style={{ left: 0 }}>
          <Message message={message} setmessage={setmessage} />
        </div>
      )}

      {forgotModalOpen && (
        <ResetPasswordModal
          setmessage={setmessage}
          open={forgotModalOpen}
          onConfirm={() => setforgotModalOpen(false)}
          onCancel={() => setforgotModalOpen(false)}
        />
      )}
      <p className="h4 fw-bold">Member Login</p>
      <p className="text-purple fw-semibold">
        Access your exam preparation tools
      </p>
      <div className="d-flex flex-column gap-4 mt-3">
        <div className="form-group with-border border border-secondary rounded-3">
          <label className="mail-label text-purple fw-semibold fs-8">
            Email
          </label>
          <div className="position-relative d-flex align-items-center px-2 py-1">
            <div className="logo mb-1" style={{ marginLeft: "3px" }}>
              {/* <img src={EmailIcon} /> */}
              <svg
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.75"
                  y="1.25"
                  width="15.5"
                  height="12.5"
                  rx="3.25"
                  stroke="#828282"
                  stroke-width="1.5"
                />
                <path
                  d="M1.5 2L6.45865 6.60446C7.60969 7.67328 9.39031 7.67328 10.5414 6.60446L15.5 2"
                  stroke="#828282"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <input
              value={email}
              onChange={handleEmailChange}
              style={{ width: "100%" }}
              className="form-control no-outline border-0"
              placeholder="Email"
              type="text"
            />

            <div
              className={`bg-green rounded-circle  style={{marginLeft:"10px"}}`}
              style={{
                width: "24px",
                height: "24px",
                minWidth: "24px",
                minHeight: "24px",
                opacity: isValid ? 1 : 0,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffffff"
                viewBox="0 0 512 512"
                style={{ width: "15px", marginBottom: "3px" }}
              >
                <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="form-group with-border border border-secondary rounded-3">
          <label className="mail-label text-purple fw-semibold fs-8">
            Password
          </label>
          <div className="position-relative d-flex align-items-center px-2 py-1">
            <div className="logo mb-1" style={{ marginLeft: "3px" }}>
              <svg
                width="16"
                height="19"
                viewBox="0 0 16 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.56422 14.0397C1.66916 14.9188 2.0839 15.7318 2.73405 16.3328C3.3842 16.9338 4.22721 17.2835 5.11188 17.3192C6.03341 17.3519 6.98013 17.3683 7.99986 17.3683C9.01959 17.3683 9.96631 17.3519 10.8878 17.3179C11.7725 17.2826 12.6157 16.9332 13.2661 16.3325C13.9165 15.7317 14.3315 14.9188 14.4368 14.0397C14.5073 13.4102 14.5564 12.7707 14.5564 12.1223C14.5564 11.4753 14.5073 10.8357 14.4368 10.2063C14.3317 9.32671 13.9166 8.51339 13.266 7.91233C12.6153 7.31127 11.7717 6.96183 10.8866 6.92675C9.92472 6.89278 8.96232 6.87599 7.99986 6.87639C6.98013 6.87639 6.03341 6.89402 5.11188 6.92675C4.22721 6.96241 3.3842 7.31211 2.73405 7.91312C2.0839 8.51413 1.66916 9.32712 1.56422 10.2063C1.48789 10.8422 1.44754 11.4819 1.44336 12.1223C1.44336 12.7707 1.49246 13.4102 1.56422 14.0397Z"
                  stroke="#828282"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.6722 6.87765V5.30399C11.6722 4.33004 11.2853 3.39598 10.5966 2.70729C9.90795 2.0186 8.97388 1.6317 7.99993 1.6317C7.02598 1.6317 6.09192 2.0186 5.40323 2.70729C4.71454 3.39598 4.32764 4.33004 4.32764 5.30399V6.87765"
                  stroke="#828282"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 11.3884V12.6473"
                  stroke="#828282"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              {/* <img src={PasswordIcon} /> */}
            </div>
            <input
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
              onKeyDown={(e) => handleEnterKeyPress(e, handleSubmit)}
              className="form-control no-outline border-0"
              placeholder="Password"
              type="password"
            />
          </div>
        </div>
      </div>
      <p
        className="text-end m-0 mt-1 mb-3"
        style={{ cursor: "pointer" }}
        onClick={() => setforgotModalOpen(true)}
      >
        Forgot password?
      </p>
      <button
        className={`position-relative w-100 updated-btn border-0`}
        onClick={!signinApiCalled ? handleSubmit : () => {}}
      >
        <span
          className={`fw-bolder loading-text small ${
            signinApiCalled ? "hide" : ""
          }`}
        >
          Login
        </span>
        {signinApiCalled && (
          <div className="loading-4 loading">
            <span className="span1"></span>
            <span className="span2"></span>
            <span className="span3"></span>
          </div>
        )}
      </button>
      <div className="d-flex align-items-center gap-3 my-3">
        <hr className="w-50" />
        <p className="m-0">or</p>
        <hr className="w-50" />
      </div>
      <div className={`w-100`}>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/auth/google`}
          className={`position-relative btn w-100 h-48 border border-secondary google-btn`}
        >
          <span className={`fw-bolder loading-text small`}>
            <div className="d-flex h-100 w-100 align-items-center justify-content-center shadow-0">
              <img src={GoogleIcon} className="h-50 mx-2" />
              <span className="fw-semibold text-gray2 text-nowrap">
                Continue with Google
              </span>
            </div>
          </span>
        </a>
      </div>
      <p className="mt-3">
        Don’t have an account?{" "}
        <span
          className="text-purple fw-semibold text-decoration-underline"
          style={{ cursor: "pointer" }}
          onClick={togglePanel}
        >
          Sign up
        </span>
      </p>
      {/* <div
        className={`${styles.formContainer} ${
          rightPanelActive ? "d-none" : ""
        }`}
      >
        <div className={`${styles.form} login-form`}>
          <img src={Logo} className="w-25 my-4 login-logo" />

          <div className={styles.buttonInputGroup}>
            <div className="w-100">
              <input
                className="w-100 primary-input form-control mb-3 login-input"
                type="emaail"
                placeholder="Email"
                required
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="w-100">
              <input
                className="w-100 primary-input form-control mb-3 login-input"
                type="password"
                placeholder="Password"
                required
                onChange={(e) => setpassword(e.target.value)}
              />
            </div>
            <div
              className="text-start w-100 px-2"
              role="button"
              onClick={() => setforgotModalOpen(true)}
            >
              <p className="h6" style={{ color: "#75BDFF" }}>
                Reset Password
              </p>
            </div>

            <div className={`mt-4 w-100`}>
              <button
                className={`position-relative btn secondary-btn w-100 login-btn`}
                style={{ height: "50px" }}
                onClick={!signinApiCalled && handleSubmit}
              >
                <span
                  className={`fw-bolder loading-text small ${
                    signinApiCalled ? "hide" : ""
                  }`}
                >
                  Login
                </span>
                {signinApiCalled && (
                  <div className="loading-4 loading">
                    <span className="span1"></span>
                    <span className="span2"></span>
                    <span className="span3"></span>
                  </div>
                )}
              </button>
            </div>
            <div className={styles.socialsWrapper}>
              <header className="w-50 m-auto my-4">
                <h2 className="text-white">or</h2>
              </header>
            </div>
          </div>
          <div className={`w-100`}>
            <a
              href={`${process.env.REACT_APP_BASE_URL}/auth/google`}
              className={`position-relative btn dark-btn w-100 login-btn`}
            >
              <span className={`fw-bolder loading-text small`}>
                <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                  <img src={GoogleIcon} className="h-75 mx-2" />
                  <span className="fw-bolder text-nowrap">
                    Continue with Google
                  </span>
                </div>
              </span>
            </a>
          </div>
          <div className="d-block d-md-none">
            <p className="text-white mt-4 small">
              Don't have an account?{" "}
              <a
                className="text-white"
                onClick={togglePanel}
                style={{ cursor: "pointer", textDecoration: "none" }}
              >
                Register here
              </a>
            </p>
          </div>
          <div
            className="px-3 mb-5 mt-2"
            style={{
              whiteSpace: "normal",
              width: "100%",
              textAlign: "center",
              bottom: 0,
            }}
          >
            {message && (
              <div
                className="position-absolute login-error"
                style={{ left: 0 }}
              >
                <Message message={message} setmessage={setmessage} />
              </div>
            )}
          </div>
        </div>
      </div> */}
    </motion.div>
  );
}
