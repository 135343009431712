import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { AuthContext } from "../../contexts/UserContext";
import moment from "moment";

export default function RemainingTime() {
  const { currentUser } = useContext(AuthContext);
  const [finalExamDate, setfinalExamDate] = useState(null);

  useEffect(() => {
    if (currentUser.examDate) {
      let start = moment(currentUser.examDate);
      let end = moment(new Date().getTime());
      setfinalExamDate(
        Math.ceil(moment.duration(start.diff(end)).asDays()).toString()
      );
    }
  }, []);

  const [isSmall, setIsSmall] = useState(false);

  function handleBoxClick(e) {
    setbuttonClicked(true);
    setshowDate(!showDate);
    setIsSmall(true);
    setTimeout(() => setIsSmall(false), 200);
  }

  const [showDate, setshowDate] = useState(false);
  const [buttonClicked, setbuttonClicked] = useState(false);

  return (
    <div>
      <div
        className={`remaining-wrapper todo-anim overflow-hidden position-relative ${
          isSmall ? "todo-anim-small" : ""
        }`}
        role="button"
        onClick={handleBoxClick}
      >
        <p className="text-uppercase d-flex flex-column justify-content-center h-100">
          {finalExamDate ? (
            <div>
              {buttonClicked ? (
                <div className="overflow-hidden">
                  <div
                    className={`${
                      showDate ? "exam-date-anim " : " "
                    }exam-date position-absolute h-100 w-100 d-flex flex-column justify-content-center`}
                  >
                    <span className="display-3">
                      <Moment format="D">{new Date()}</Moment>
                    </span>
                    <span className="h4 fw-bold">
                      <Moment format="MMM">{new Date()}</Moment>
                    </span>
                  </div>
                  <div
                    className={`rem-days position-absolute h-100 w-100 d-flex flex-column justify-content-center ${
                      !showDate ? "rem-days-anim " : ""
                    }`}
                  >
                    <span className="display-3">{finalExamDate}</span>
                    <span className="h6 fw-bold">
                      Days to go
                      <br /> for exam
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  className={`position-absolute h-100 w-100 d-flex flex-column justify-content-center top-0`}
                >
                  <span className="display-3">{finalExamDate}</span>
                  <span className="h6 fw-bold">
                    Days to go
                    <br /> for exam
                  </span>
                </div>
              )}
            </div>
          ) : (
            <span className="h4 fw-bold">
              SET EXAM <br /> DATE
            </span>
          )}
        </p>
      </div>
    </div>
  );
}
