import { axiosClient } from "./axios";

export const upload = async ({ files, title, description }) => {
  try {
    axiosClient.defaults.headers = {
      "Content-Type": "multipart/form-data",
    };
    const response = await axiosClient.post("/blog", {
      date: Date.now(),
      files,
      title,
      description,
    });
    axiosClient.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteBlog = async ({ blogId, blogDate }) => {
  try {
    const response = await axiosClient.delete(
      `/blog?blogId=${blogId}&date=${blogDate}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const get = async () => {
  try {
    const response = await axiosClient.get("/blog");

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const like = async ({ blogId }) => {
  try {
    const response = await axiosClient.put(`/blog/like?blogId=${blogId}`);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const unlike = async ({ blogId }) => {
  try {
    const response = await axiosClient.put(`/blog/unlike?blogId=${blogId}`);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
