import React from "react";
import { Link, useLocation } from "react-router-dom";
import TwitterIcon from "../assets/updated/home/twitter.svg";
import InstagramIcon from "../assets/updated/home/instagram.svg";
import FacebookIcon from "../assets/updated/home/facebook.svg";
import MailIcon from "../assets/updated/home/mail.svg";
import LocationIcon from "../assets/updated/home/location.svg";
import PhoneIcon from "../assets/updated/home/phone.svg";

export default function Footer() {
  const location = useLocation();

  const email = "hello@finbite.in";

  const handleOpenMail = () => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      const uri = `mailto:${email}`;
      window.location.href = uri;
    } else if (/Win/i.test(navigator.userAgent)) {
      const url = `https://mail.google.com/mail/?view=cm&to=${email}`;
      window.open(url, "_blank");
    } else {
      const url = `https://mail.google.com/mail/?view=cm&to=${email}`;
      window.open(url, "_blank");
    }
  };

  return (
    <section className="container font-jakarta">
      <footer className="text-center text-md-start">
        <div className="pt-5">
          <div className="footer-col-wrapper">
            <div className="footer-col">
              <Link
                className="nav-logo fw-bolder h4 m-0 p-0 text-decoration-none"
                to="/"
              >
                <svg
                  width="80"
                  height="24"
                  viewBox="0 0 87 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.571777 27.1669L5.46732 0.755371H16.2944L15.3403 5.90262H9.95755L9.17872 10.1044H14.2847L13.3417 15.1917H8.23576L6.01608 27.1669H0.571777ZM12.9061 27.1669L17.8016 0.755371H23.0306L18.1351 27.1669H12.9061ZM20.2881 27.1669L25.1838 0.755371H30.7511L30.8977 13.4059L33.2425 0.755371H38.4715L33.576 27.1669H28.2855L28.0264 13.9611L25.5787 27.1669H20.2881ZM35.9752 27.1669L40.8707 0.755371H48.2067C50.1753 0.755371 51.5184 1.22558 52.236 2.16599C52.9659 3.0964 53.1314 4.63706 52.7326 6.788L52.5185 7.94351C52.2885 9.18405 51.8716 10.1895 51.2673 10.9598C50.6734 11.7302 49.8878 12.2354 48.9103 12.4755C50.0746 12.7756 50.759 13.4809 50.9634 14.5914C51.1797 15.6919 51.1405 17.0375 50.8456 18.6282C50.5286 20.3389 50.0872 21.8345 49.5219 23.1152C48.9563 24.3957 48.1771 25.3911 47.1843 26.1015C46.1914 26.8117 44.9004 27.1669 43.3112 27.1669H35.9752ZM44.317 10.7047H45.4243C45.9267 10.7047 46.285 10.5146 46.499 10.1345C46.713 9.75429 46.8691 9.2991 46.9675 8.76886L47.4597 6.1127C47.6174 5.26233 47.3117 4.83714 46.5428 4.83714H45.4047L44.317 10.7047ZM42.6662 22.5148C44.071 22.5148 44.8937 21.8645 45.1349 20.564L45.7468 17.2626C45.8859 16.5122 45.8773 15.922 45.7212 15.4918C45.5772 15.0516 45.1875 14.8315 44.5518 14.8315H43.5521L42.1335 22.4848C42.3554 22.5049 42.5329 22.5148 42.6662 22.5148ZM50.8008 27.1669L55.6965 0.755371H60.9254L56.0299 27.1669H50.8008ZM60.5052 27.1669L64.4607 5.82759H61.2465L62.1865 0.755371H74.0287L73.0885 5.82759H69.8742L65.9189 27.1669H60.5052ZM70.6403 27.1669L75.5358 0.755371H86.3629L85.4173 5.85759H80.096L79.1418 11.0048H84.2479L83.3271 15.972H78.2213L77.1003 22.0197H82.7752L81.8212 27.1669H70.6403Z"
                    fill="url(#paint0_linear_275_198)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_275_198"
                      x1="-58.4239"
                      y1="9.39438"
                      x2="-35.2994"
                      y2="62.9726"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#B39FFF" />
                      <stop offset="1" stop-color="#6A1ED2" />
                    </linearGradient>
                  </defs>
                </svg>
              </Link>

              <p style={{ maxWidth: "300px" }} className="mx-auto">
                {location.pathname == "/"
                  ? "Unlock Your Full Potential with our Premium productivity Tools"
                  : "Unlock Your Full Potential with Premium CA/CS/CMA Exam Revision Tool"}
              </p>
            </div>
            <div className="footer-col">
              <h5
                className="text-purple fw-bold h5"
                style={{ whiteSpace: "nowrap" }}
              >
                Menu
              </h5>
              <ul className="list-unstyled mb-0 footer-list">
                <li>
                  <Link className="text-decoration-none" to="/#home">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/#revision">
                    Our Tools
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/features">
                    Features
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/#feedback">
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/#why">
                    Why Finbite
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/user">
                    Login
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/#faq">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h5
                className="text-purple fw-bold h5"
                style={{ whiteSpace: "nowrap" }}
              >
                More Info
              </h5>
              <ul className="list-unstyled mb-0 footer-list">
                <li>
                  <Link className="text-decoration-none" to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/terms">
                    T&C
                  </Link>
                </li>
                <li>
                  <Link className="text-decoration-none" to="/refund">
                    Refund Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h5
                className="text-purple fw-bold h5"
                style={{ whiteSpace: "nowrap" }}
              >
                Connect With Us
              </h5>
              <ul className="list-unstyled mb-0 footer-list d-flex gap-3 flex-column position-relative align-items-center align-items-lg-start">
                <li>
                  <Link
                    className="text-decoration-none d-flex align-items-center position-relative gap-3"
                    style={{ width: "100px" }}
                    to={"https://www.facebook.com/thefinbite"}
                    target="_blank"
                  >
                    <img
                      src={FacebookIcon}
                      style={{ position: "absolute", left: "-20px" }}
                    />

                    <p className="my-0 mx-4">Facebook</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none d-flex align-items-center position-relative gap-3"
                    style={{ width: "100px" }}
                    to={"https://twitter.com/thefinbite"}
                    target="_blank"
                  >
                    <img
                      src={TwitterIcon}
                      style={{ position: "absolute", left: "-20px" }}
                    />

                    <p className="my-0 mx-4">Twitter</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-decoration-none d-flex align-items-center position-relative gap-3"
                    style={{ width: "100px" }}
                    to={"https://www.instagram.com/finbite/"}
                    target="_blank"
                  >
                    <img
                      src={InstagramIcon}
                      style={{ position: "absolute", left: "-20px" }}
                    />

                    <p className="my-0 mx-4">Instagram</p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col mb-4 mb-lg-0">
              <h5
                className="text-purple fw-bold h5"
                style={{ whiteSpace: "nowrap" }}
              >
                Reach Out
              </h5>
              <ul className="list-unstyled mb-0 footer-list d-flex gap-3 flex-column position-relative align-items-center align-items-lg-start">
                <li>
                  <div
                    className="text-decoration-none d-flex align-items-center"
                    style={{ width: "190px" }}
                  >
                    <img src={MailIcon} />

                    <p className="my-0 mx-2">hello@finbite.in</p>
                  </div>
                </li>
                <li>
                  <div
                    className="text-decoration-none d-flex align-items-center"
                    style={{ width: "190px" }}
                  >
                    <img src={LocationIcon} />

                    <p className="my-0 mx-2">
                      Haridham Complex, R V Desai Road, Vadodara, Gujarat,
                      390001.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="text-decoration-none d-flex align-items-center"
                    style={{ width: "190px" }}
                  >
                    <img src={PhoneIcon} />

                    <p className="my-0 mx-2">+918200592630</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            className="mt-0 mt-md-4"
            style={{ border: "1px solid #262A41" }}
          />
        </div>

        <div className="text-center p-3 sub-footer-heading fw-bold">
          COPYRIGHT © 2024 FINBITE. ALL RIGHTS RESERVED
        </div>
      </footer>
    </section>
  );
}
