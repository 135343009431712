import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default function ConfirmModal({
  open,
  onCancel,
  onConfirm,
  heading,
  text,
  buttonText,
}) {
  const [apiCalled, setapiCalled] = useState(false);

  const handleClick = () => {
    setapiCalled(true);
    onConfirm();
  };

  return (
    <Modal centered isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center w-100 px-2 mt-2 mb-5">
          <p className="h4 fw-bold text-uppercase text-white">{heading}</p>
          <p className="fw-bold text-white" role="button" onClick={onCancel}>
            x
          </p>
        </div>
        <p className="text-white fw-bold h6 text-center">{text}</p>
      </ModalBody>
      <ModalFooter>
        <button
          className={`position-relative btn secondary-btn w-100`}
          style={{ height: "50px" }}
          onClick={!apiCalled ? handleClick : null}
        >
          <span className={`fw-bolder loading-text ${apiCalled ? "hide" : ""}`}>
            {buttonText}
          </span>
          {apiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
}
