import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  Controls,
  useStoreApi,
  useReactFlow,
  ConnectionLineType,
} from "reactflow";
import shallow from "zustand/shallow";
import useStore from "./store";
import MindMapNode from "./Node";
import MindMapEdge from "./Edge";
import "reactflow/dist/style.css";
import SaveImg from "../../assets/images/icons/save.svg";
import { getMindmap, udpateMindmap } from "../../api/mindmap";
import Message from "../Message";
import ConfirmModal from "../modals/Confirm";
import { saveAs } from "file-saver";
import domtoimage from "dom-to-image";
import DownloadButton from "./DownloadButton";

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  addChildNode: state.addChildNode,
  initialData: state.initialData,
  deleteNode: state.deleteNode,
  undo: state.undo,
  redo: state.redo,
  onKeyDown: state.onKeyDown,
  isChanged: state.isChanged,
  updateChangesMade: state.updateChangesMade,
});

const nodeTypes = {
  mindmap: MindMapNode,
};

const edgeTypes = {
  mindmap: MindMapEdge,
};

const nodeOrigin = [0.5, 0.5];
const connectionLineStyle = { stroke: "#F6AD55", strokeWidth: 3 };
const defaultEdgeOptions = { style: connectionLineStyle, type: "mindmap" };

export default function Flow({
  mapId,
  setchangesMade,
  saveModalOpen,
  setsaveModalOpen,
  mapIdToUpdate,
  setmapIdToUpdate,
  setactiveMindmap,
  mindmapLoading,
  setmindmapLoading,
}) {
  const [message, setmessage] = useState(null);

  // whenever you use multiple values, you should use shallow for making sure that the component only re-renders when one of the values change
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    addChildNode,
    initialData,
    onKeyDown,
    isChanged,
    updateChangesMade,
  } = useStore(selector, shallow);

  const connectingNodeId = useRef(null);

  const store = useStoreApi();
  const { project } = useReactFlow();

  const getChildNodePosition = (event, parentNode) => {
    const { domNode } = store.getState();

    if (
      !domNode ||
      // we need to check if these properites exist, because when a node is not initialized yet,
      // it doesn't have a positionAbsolute nor a width or height
      !parentNode?.positionAbsolute ||
      !parentNode?.width ||
      !parentNode?.height
    ) {
      return;
    }

    const { top, left } = domNode.getBoundingClientRect();

    // we need to remove the wrapper bounds, in order to get the correct mouse position
    const panePosition = project({
      x: event.clientX - left,
      y: event.clientY - top,
    });

    // we are calculating with positionAbsolute here because child nodes are positioned relative to their parent
    return {
      x: panePosition.x - parentNode.positionAbsolute.x + parentNode.width / 2,
      y: panePosition.y - parentNode.positionAbsolute.y + parentNode.height / 2,
    };
  };

  const onConnectStart = useCallback((_, { nodeId }) => {
    setchangesMade(true);
    connectingNodeId.current = nodeId;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const { nodeInternals } = store.getState();
      const targetIsPane = event.target.classList.contains("react-flow__pane");
      if (targetIsPane && connectingNodeId.current) {
        const parentNode = nodeInternals.get(connectingNodeId.current);
        const childNodePosition = getChildNodePosition(event, parentNode);
        if (parentNode && childNodePosition) {
          addChildNode(parentNode, childNodePosition);
        }
      }
    },
    [getChildNodePosition]
  );

  const saveMindmap = () => {
    udpateMindmap({
      mindmapId: mapId,
      data: { data: { nodes, edges } },
    })
      .then((res) => {
        setchangesMade(false);
        // if (autoSave) {
        // } else {
        setmessage({ message: "Mindmap Saved Successfully", success: true });
        // }

        if (mapIdToUpdate) {
          setactiveMindmap(mapIdToUpdate);
          setmapIdToUpdate("");
        }
        setsaveModalOpen(false);
      })
      .catch((err) => {
        setsaveModalOpen(false);
      });
  };
  const getMindmapData = () => {
    if (mapId) {
      setmindmapLoading(true);
      getMindmap({ mindmapId: mapId })
        .then((res) => {
          setmindmapLoading(false);
          initialData(res.data);
        })
        .catch((err) => {
          setmindmapLoading(false);
        });
    } else {
      setmindmapLoading(false);
    }
  };

  useEffect(() => {
    setchangesMade(false);
    getMindmapData();
  }, [mapId]);

  useEffect(() => {
    document.addEventListener("keydown", (e) =>
      onKeyDown(e, { setchangesMade })
    );

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    setchangesMade(isChanged);
  }, [isChanged]);

  useEffect(() => {
    updateChangesMade(false);
  }, [mapId]);

  return (
    <div
      className="vh-100 w-100 position-relative"
      // style={{ background: "#cfcfcf" }}
      style={{ background: "#ffffff" }}
    >
      {saveModalOpen && (
        <ConfirmModal
          open={true}
          onCancel={() => {
            setsaveModalOpen(false);
            if (mapIdToUpdate) {
              setactiveMindmap(mapIdToUpdate);
              setmapIdToUpdate("");
            }
          }}
          onConfirm={saveMindmap}
          heading={"Save Changes"}
          text={"Do you want to save changes?"}
          buttonText={"Save Changes"}
        />
      )}
      {message && (
        <div
          className="position-absolute"
          style={{ width: "100%", top: "65px", zIndex: 1000 }}
        >
          <Message message={message} setmessage={setmessage} />
        </div>
      )}
      {mapId && !mindmapLoading ? (
        <div className="h-100">
          <div
            className="position-absolute bg-white px-3 py-1 save-export"
            style={{ right: "20px", top: "20px", zIndex: 100 }}
          >
            <div className="d-flex gap-2">
              <div
                className="d-flex align-items-center gap-1"
                role="button"
                onClick={saveMindmap}
              >
                <img src={SaveImg} style={{ height: "22px" }} />
                <span className="fw-bold" style={{ color: "#9d50bb" }}>
                  Save
                </span>
              </div>
              <hr
                className="bg-dark"
                style={{
                  height: "2px",
                  width: "20px",
                  transform: "rotate(90deg)",
                }}
              />
              <DownloadButton mapId={mapId} />
              {/* <div
                className="d-flex align-items-center gap-1"
                role="button"
                onClick={handleDownloadFlowAsImg}
              >
                <img src={ExportImg} style={{ height: "22px" }} />
                <span className="text-dark fw-bold">Export</span>
              </div> */}
            </div>
          </div>
          <ReactFlow
            id="react-flow-container"
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            nodeOrigin={nodeOrigin}
            connectionLineStyle={connectionLineStyle}
            defaultEdgeOptions={defaultEdgeOptions}
            connectionLineType={ConnectionLineType.SimpleBezier}
            fitView
            defaultViewport={{ x: 0, y: 0, zoom: 1 }}
            deleteKeyCode={null}
            minZoom={0.1}
            // proOptions={{ hideAttribution: true }}
          >
            <Controls showInteractive={false} />
            {/* <DownloadButton /> */}
          </ReactFlow>
        </div>
      ) : !mapId ? (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <p className="text-center fw-bold">Select a mindmap to view</p>
        </div>
      ) : (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <p className="text-center fw-bold">Loading...</p>
        </div>
      )}
    </div>
  );
}
