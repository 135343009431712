import React, { useEffect, useState } from "react";
import CheckImg from "../assets/images/icons/check-white.svg";

export default function Message({ message, setmessage }) {
  const [hideMessage, sethideMessage] = useState(false);
  useEffect(() => {
    if (message) {
      window.scrollTo(0, 0);
      setTimeout(() => {
        sethideMessage(true);
      }, 4000);
      setTimeout(() => {
        setmessage("");
      }, 5000);
    }
  }, []);

  return (
    <div
      className={`px-4 w-100 text-center message-wrapper ${
        message.success ? "message-success" : "message-failure"
      } ${hideMessage ? "hide" : ""}`}
      z
    >
      <p
        className={`py-2 ${
          message.success ? "text-success" : "text-failure"
        } px-0`}
      >
        <div className="d-flex align-items-center justify-content-center">
          {message.success && (
            <div className="success-img-wrapper">
              <img src={CheckImg} className="success-img" />
            </div>
          )}
          <p className="text-dark m-0 p-0">{message.message}</p>
        </div>
      </p>
    </div>
  );
}
