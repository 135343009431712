import React, { useContext, useEffect, useState } from "react";
import { createCoupon, getCoupons } from "../api/coupon";
import { getPlans, updatePlan } from "../api/plan";
import CouponRow from "../components/coupons/CouponRow";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/UserContext";
import { motion } from "framer-motion";

const Coupons = () => {
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);

  const [coupons, setcoupons] = useState([]);
  const [plan, setplan] = useState(null);
  const [couponName, setcouponName] = useState("");
  const [couponPrice, setcouponPrice] = useState("");
  const [planAmount, setplanAmount] = useState("");
  const [loading, setloading] = useState(false);
  const [addCouponApiCalled, setaddCouponApiCalled] = useState(false);
  const [updatePriceApiCalled, setupdatePriceApiCalled] = useState(false);

  const handleGetCoupons = async () => {
    getCoupons()
      .then((res) => {
        setcoupons(res);
      })
      .catch((err) => {});
  };
  const handleGetPlans = async () => {
    getPlans()
      .then((res) => {
        setplan(res[0]);
        setplanAmount(res[0].price);
      })
      .catch((err) => {});
  };
  const handleAddCoupon = () => {
    if (couponName && couponPrice) {
      setaddCouponApiCalled(true);
      createCoupon({ data: { name: couponName, amount: couponPrice } })
        .then((res) => {
          setcoupons([...coupons, { name: couponName, amount: couponPrice }]);
          setcouponName("");
          setcouponPrice("");
          setaddCouponApiCalled(false);
        })
        .catch((err) => {
          setaddCouponApiCalled(false);
        });
    }
  };
  const handleUpdatePrice = () => {
    setupdatePriceApiCalled(true);
    updatePlan({ data: { price: planAmount }, id: plan._id })
      .then((res) => {
        setupdatePriceApiCalled(false);
      })
      .catch((err) => {
        setupdatePriceApiCalled(false);
      });
  };

  useEffect(() => {
    if (currentUser?.isAdmin) {
      setloading(true);
      Promise.all([handleGetCoupons(), handleGetPlans()])
        .then((res) => {
          setloading(false);
        })
        .catch((err) => {
          setloading(false);
        });
    } else {
      navigate("/dashboard");
    }
  }, []);

  return (
    <motion.div
      className="position-relative"
      initial={{ opacity: 0, top: "20vh" }}
      animate={{
        opacity: 1,
        top: "0vh",
      }}
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <div className="d-flex flex-column flex-sm-row gap-5">
        <div className="w-100 d-flex flex-column gap-3">
          <input
            type="text"
            placeholder="Enter coupon name"
            className="form-control white-input bg-white text-center outline-none"
            value={couponName}
            onChange={(e) => setcouponName(e.target.value.toUpperCase())}
          />
          <input
            type="number"
            placeholder="Enter discount amount"
            className="form-control white-input bg-white text-center outline-none"
            value={couponPrice}
            onChange={(e) => {
              if (e.target.value >= 0) setcouponPrice(e.target.value);
            }}
          />
          <button
            disabled={addCouponApiCalled}
            className={`position-relative btn btn-import w-100 text-uppercase fw-bolder`}
            style={{
              background:
                "linear-gradient(116deg, #30DBB5 -2.57%, #00A07C 100%)",
              borderRadius: "11px",
            }}
            onClick={handleAddCoupon}
          >
            <div className={`loading-text ${addCouponApiCalled ? "hide" : ""}`}>
              Create Coupon
            </div>
            {addCouponApiCalled && (
              <div className="loading-4 loading">
                <span className="span1"></span>
                <span className="span2"></span>
                <span className="span3"></span>
              </div>
            )}
          </button>
        </div>
        <div className="w-100 d-flex flex-column gap-3">
          <input
            type="text"
            placeholder="Main Price"
            className="form-control white-input bg-white text-center outline-none fw-bold"
            value={"Main Price"}
            readOnly
          />
          <input
            type="number"
            placeholder="Enter Main Price"
            className="form-control white-input bg-white text-center outline-none"
            value={planAmount}
            onChange={(e) => {
              if (e.target.value >= 0) setplanAmount(e.target.value);
            }}
          />
          <button
            disabled={updatePriceApiCalled}
            className={`position-relative btn btn-import w-100 text-uppercase fw-bolder`}
            style={{
              background: "#FF4B4B",
              borderRadius: "11px",
            }}
            onClick={handleUpdatePrice}
          >
            <div
              className={`loading-text ${updatePriceApiCalled ? "hide" : ""}`}
            >
              Save
            </div>
            {updatePriceApiCalled && (
              <div className="loading-4 loading">
                <span className="span1"></span>
                <span className="span2"></span>
                <span className="span3"></span>
              </div>
            )}
          </button>
        </div>
      </div>
      <div className="w-100 m-auto mt-3">
        {coupons.length > 0 ? (
          <table
            className={`table table-bordered table-responsive questions-table text-white`}
          >
            <thead>
              <tr>
                <th scope="col">Coupon Code</th>
                <th scope="col">Discount Amount</th>
                <th scope="col">Edit</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>
            <tbody>
              {coupons.map((coup) => (
                <CouponRow coupon={coup} setcoupons={setcoupons} />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="mt-5">
            <h6 className="text-uppercase text-secondary">
              No coupons to show
            </h6>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default Coupons;
