import { axiosClient } from "./axios";

// create subject
export const createSubject = async ({
  subjectName,
  chapterName,
  sectionName,
}) => {
  try {
    let response = await axiosClient.post("/question-bank/create", {
      subject: {
        name: subjectName,
      },
      chapter: {
        name: chapterName,
      },
      section: {
        name: sectionName,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// create chapter
export const createChapter = async ({
  subjectId,
  chapterName,
  sectionName,
}) => {
  try {
    let response = await axiosClient.post("/question-bank/create", {
      subjectId,
      chapter: {
        name: chapterName,
      },
      section: {
        name: sectionName,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// create section
export const createSection = async ({ chapterId, sectionName }) => {
  try {
    let response = await axiosClient.post("/question-bank/create", {
      chapterId,
      section: {
        name: sectionName,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// get subjects
export const getSubjects = async () => {
  try {
    let response = await axiosClient.get("/question-bank/user-subjects");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// get chapters
export const getChapters = async ({ subjectId }) => {
  try {
    let response;
    if (subjectId) {
      response = await axiosClient.get(
        `/question-bank/subject-chapters?subjectId=${subjectId}`
      );
    } else {
      response = await axiosClient.get("/question-bank/user-chapters");
    }

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// get sections
export const getSections = async ({ subjectId, chapterId }) => {
  try {
    let response;
    if (chapterId) {
      response = await axiosClient.get(
        `/question-bank/chapter-sections?chapterId=${chapterId}`
      );
    } else if (subjectId) {
      response = await axiosClient.get(
        `/question-bank/subject-sections?subjectId=${subjectId}`
      );
    } else {
      response = await axiosClient.get(`/question-bank/user-sections`);
    }

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// get single subject
export const getSingleSubject = async ({ subjectId }) => {
  try {
    let response = await axiosClient.get(
      `/question-bank/subject?subjectId=${subjectId}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// get single chapter
export const getSingleChapter = async ({ chapterId }) => {
  try {
    let response = await axiosClient.get(
      `/question-bank/chapter?chapterId=${chapterId}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// get single section
export const getSingleSection = async ({ sectionId }) => {
  try {
    let response = await axiosClient.get(
      `/question-bank/section?sectionId=${sectionId}`
    );

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// get questions
export const getAllQuestions = async ({
  sectionId,
  chapterId,
  subjectId,
  limit,
}) => {
  try {
    let response;
    if (sectionId) {
      response = await axiosClient.get(
        `/question-bank/section-questions?sectionId=${sectionId}${
          limit ? `&limit=${limit}` : ""
        }`
      );
    } else if (chapterId) {
      response = await axiosClient.get(
        `/question-bank/chapter-questions?chapterId=${chapterId}${
          limit ? `&limit=${limit}` : ""
        }`
      );
    } else if (subjectId) {
      response = await axiosClient.get(
        `/question-bank/subject-questions?subjectId=${subjectId}${
          limit ? `&limit=${limit}` : ""
        }`
      );
    } else {
      response = await axiosClient.get(
        `/question-bank/user-questions${limit ? `&limit=${limit}` : ""}`
      );
    }

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// create question
export const createQuestion = async ({ question, answer, sectionId }) => {
  try {
    let response = await axiosClient.post("/question-bank/add-question", {
      question: {
        question,
        answer,
      },
      sectionId,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// update question
export const updateQuestion = async ({
  question,
  answer,
  questionId,
  sectionId,
}) => {
  try {
    let response = await axiosClient.put("/question-bank/update-question", {
      question,
      answer,
      _id: questionId,
      sectionId,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// delete question
export const deleteQuestion = async ({ questionId }) => {
  try {
    let response = await axiosClient.delete(
      `/question-bank/delete-question?_id=${questionId}`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// add file
export const addFile = async ({ file, secId }) => {
  try {
    axiosClient.defaults.headers = {
      "Content-Type": "multipart/form-data",
    };
    let response = await axiosClient.post("/question-bank/add-file", {
      file,
      sectionId: secId,
    });
    axiosClient.defaults.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// delete subject
export const deleteSubject = async ({ subjectId }) => {
  try {
    let response = await axiosClient.delete(
      `/question-bank/subject?_id=${subjectId}`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// delete chapter
export const deleteChapter = async ({ chapterId }) => {
  try {
    let response = await axiosClient.delete(
      `/question-bank/chapter?_id=${chapterId}`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// delete section
export const deleteSection = async ({ sectionId }) => {
  try {
    let response = await axiosClient.delete(
      `/question-bank/section?_id=${sectionId}`
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// update subject
export const updateSubject = async ({ subjectId, name }) => {
  try {
    let response = await axiosClient.put(`/question-bank/subject`, {
      _id: subjectId,
      name,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// update chapter
export const updateChapter = async ({ chapterId, name }) => {
  try {
    let response = await axiosClient.put(`/question-bank/chapter`, {
      _id: chapterId,
      name,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

// update section
export const updateSection = async ({ sectionId, name }) => {
  try {
    let response = await axiosClient.put(`/question-bank/section`, {
      _id: sectionId,
      name,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
