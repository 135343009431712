import { axiosClient } from "./axios";

export const addTodo = async ({ text }) => {
  try {
    let response = await axiosClient.post("/todo/add-task", {
      text,
      completed: false,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
export const deleteTodo = async ({ todoId }) => {
  try {
    let response = await axiosClient.delete(`/todo/delete-task?_id=${todoId}`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
export const updateTodo = async ({ todoId, completed, color }) => {
  try {
    let response = await axiosClient.put("/todo/update-task", {
      _id: todoId,
      completed,
      color,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
export const addSubTask = async ({ todoId, todoText }) => {
  try {
    let response = await axiosClient.put("/todo/add-subtask", {
      _id: todoId,
      text: todoText,
      completed: false,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
export const deleteSubTask = async ({ todoId, todoText }) => {
  try {
    let response = await axiosClient.put("/todo/delete-subtask", {
      _id: todoId,
      text: todoText,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
export const updateSubTask = async ({ todoId, todoText, completed }) => {
  try {
    let response = await axiosClient.put("/todo/update-subtask", {
      _id: todoId,
      text: todoText,
      completed,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
export const deleteAllTodos = async () => {
  try {
    let response = await axiosClient.delete("/todo/delete-all");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getAllTodos = async () => {
  try {
    let response = await axiosClient.get("/todo");
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
