import React, { useEffect, useRef, useState } from "react";
import ViewQuestions from "../components/revision/ViewQuestions";
import { getSampleBlogs, getSampleQuestions } from "../api/sample";
import { VerticalTimeline } from "react-vertical-timeline-component";
import Card from "../components/updates/Card";
import AddQuestions from "../components/sample/AddQuestions";
import SampleTodoList from "../components/sample/SampleTodoList";
import Typewriter from "typewriter-effect";

export default function Features() {
  const [loadingQuestions, setloadingQuestions] = useState(true);
  const [questions, setquestions] = useState([]);
  const [blogs, setblogs] = useState([]);
  const [loadingBlogs, setloadingBlogs] = useState(false);

  useEffect(() => {
    // window.scrollTo(0, 0);

    getSampleQuestions()
      .then((res) => {
        setloadingQuestions(false);
        setquestions(res);
      })
      .catch((err) => {
        setloadingQuestions(false);
      });

    getSampleBlogs()
      .then((res) => {
        setblogs(res);
        setloadingBlogs(false);
      })
      .catch((err) => {
        setloadingBlogs(false);
      });
  }, []);

  const arrayRef = useRef([]);

  return (
    <div>
      <div>
        <p className="feature-main-heading h2 fw-bold features-heading text-dark-secondary text-uppercase my-5">
          Features
        </p>
      </div>
      <div>
        <p className="h4 fw-bold features-heading text-dark-secondary text-uppercase my-5 pt-5">
          revision cards
        </p>
      </div>
      <div className="feature-bg-dark pt-5 pb-4 my-5">
        <ViewQuestions
          loading={loadingQuestions}
          questions={questions}
          subjectsLength={1}
        />
      </div>
      <div className="container mb-5">
        <p className="text-dark-secondary" style={{ textAlign: "justify" }}>
          Revision cards are a great way for students to check if they have the
          correct answer. It's like playing cards and it's the best way to
          remember all the points with very minimum efforts. By using revision
          cards, students can quickly and easily check their PREPARATION and
          make sure they're on the right track.
          <br />
          <br /> By condensing key information down into a small, manageable
          format, they can help you to focus your revision and learn the
          essential points more effectively. What's more, they're easy to make
          BY yourself and can be used anywhere – so you can revise on the go!
        </p>
      </div>
      <div className="my-5 pt-5">
        <p className="h4 fw-bold features-heading text-dark-secondary text-uppercase">
          todo
        </p>
      </div>
      <div className="feature-bg-dark pb-5">
        <div className="container">
          <SampleTodoList />
        </div>
      </div>
      <div className="container my-5">
        <p style={{ textAlign: "justify" }}>
          To-do lists are a great way to keep yourself organized and on track.
          By setting priorities and grouping similar tasks together, you can
          make sure that you're making the most efficient use of your time.
          Plus, keeping track of your progress is a great motivator to keep
          going!
          <br />
          <br />
          If you're not already using a to-do list, IT IS highly recommend
          giving it a try. You may just find that it helps you accomplish more
          than you ever thought possible. <br />
          <br />
          it's always gratifying to see OUR progress as WE cross items off OUR
          list.
        </p>
      </div>
      <div className="my-5 pt-5">
        <p className="h4 fw-bold features-heading text-dark-secondary text-uppercase">
          active recall
        </p>
        {/* <p className="h6 fw-bold features-heading text-dark-secondary text-uppercase">
          <Typewriter
            options={{
              strings: [
                "add question and answer",
                "revise from database table given below",
                "try to recall the answers for each question",
              ],
              autoStart: true,
              pauseFor: 500,
              delay: 100,
              loop: true,
            }}
          />
        </p> */}
      </div>
      <div className="feature-bg-dark my-5 pt-5 pb-4">
        <AddQuestions />
      </div>
      <div className="container">
        <p style={{ textAlign: "justify" }}>
          Active recall is a great way to learn new information and remember it
          easily. By creating questions based on the topic you wish to learn,
          you force your brain to go through the process of retrieval, which
          helps commit the information to memory. Additionally, actively testing
          yourself in this way can lead to a deeper understanding of the
          material. So next time you want to remember something easily, forget
          about rote memorization and try active recall instead!
        </p>
      </div>
      <div className="my-5 pt-5">
        <p className="h4 fw-bold features-heading text-dark-secondary text-uppercase">
          updates
        </p>
      </div>
      <div className="feature-bg-dark py-5">
        <VerticalTimeline layout="1-column-left" className="p-0 mt-5">
          {blogs.map((blog, index) => {
            return (
              <div ref={(el) => (arrayRef.current[index] = el)} id={blog._id}>
                <Card blog={blog} />
              </div>
            );
          })}
        </VerticalTimeline>
      </div>
      <div className="container mt-5">
        <p style={{ textAlign: "justify" }}>
          As a CA/CS/CMA student, you'll have access to updates on all the
          latest news and developments in your field. You'll also be able to
          stay up-to-date on financial news and developments that could impact
          your studies or career. Plus, you'll receive useful tips, tricks and
          notes from our team to help you succeed.
        </p>
      </div>

      <div className="my-5 pt-5">
        <p className="h4 fw-bold features-heading text-dark-secondary text-uppercase">
          Mind Map
        </p>
      </div>
      <div className="container mt-5">
        <p style={{ textAlign: "justify" }}>
          Mind maps are valuable tools for studying as they provide a visual way
          to organize information, simplifying complex topics and aiding
          comprehension. <br />
          By presenting key concepts in a structured and interconnected format,
          they enhance memory retention. Additionally, mind maps foster creative
          thinking and problem-solving by encouraging the exploration of
          relationships between ideas, making them an effective resource for
          efficient and effective learning.
        </p>
      </div>
    </div>
  );
}
