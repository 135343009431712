import React, { useContext, useEffect, useState } from "react";

import {
  getSubjects as getSubjectsApi,
  getChapters as getChaptersApi,
  getSections as getSectionsApi,
  getAllQuestions as getAllQuestionsApi,
} from "../../api/questionBank";
import { AuthContext } from "../../contexts/UserContext";
import DropdownMenu from "../DropdownMenu";
import DropdownLight from "../DropdownLight";

export default function Filters({
  currSubject,
  setcurrSubject,
  currChapter,
  setcurrChapter,
  currSection,
  setcurrSection,
  getQuestions,
  subjects,
  setsubjects,
}) {
  const { darkMode } = useContext(AuthContext);
  const [sections, setsections] = useState([]);
  const [chapters, setchapters] = useState([]);
  const [loadingSubjects, setloadingSubjects] = useState(false);
  const [loadingChapters, setloadingChapters] = useState(false);
  const [loadingSections, setloadingSections] = useState(false);

  useEffect(() => {
    getQuestions();
  }, [currSubject, currChapter, currSection]);

  const getAllSubjects = () => {
    setloadingSubjects(true);
    getSubjectsApi()
      .then((res) => {
        setsubjects(res);
        if (res.length > 0) {
          setcurrSubject(res[0]._id);
        }
        setloadingSubjects(false);
      })
      .catch((err) => {
        setloadingSubjects(false);
      });
  };

  const getAllChapters = () => {
    setloadingChapters(true);
    getChaptersApi({ subjectId: currSubject })
      .then((res) => {
        setchapters(res);
        if (res.length > 0) {
          // setcurrChapter(res[0]._id);
        } else {
          setcurrChapter("");
          setsections([]);
        }
        setloadingChapters(false);
      })
      .catch((err) => {
        setloadingChapters(false);
      });
  };

  const getAllSections = () => {
    setloadingSections(true);
    getSectionsApi({ chapterId: currChapter })
      .then((res) => {
        setsections(res);
        if (res.length > 0) {
        } else {
          setcurrSection("");
        }
        setloadingSections(false);
      })
      .catch((err) => {
        setloadingSections(false);
      });
  };

  useEffect(() => {
    getAllSubjects();
  }, []);

  useEffect(() => {
    setchapters([]);
    setsections([]);
    setcurrChapter("");
    setcurrSection("");
    if (currSubject) {
      getAllChapters(currSubject);
    }
  }, [currSubject]);

  useEffect(() => {
    setsections([]);
    setcurrSection("");
    if (currChapter) {
      getAllSections(currChapter);
    }
  }, [currChapter]);

  return (
    <div>
      {!loadingSubjects && (
        <div className="m-auto database-filters-wrapper">
          {subjects.length > 0 &&
            (darkMode ? (
              <div className="d-flex gap-3 my-4 justify-content-center">
                <DropdownMenu
                  mainItems={subjects}
                  changeAction={setcurrSubject}
                  defaultValue={null}
                />
                {currSubject && !loadingChapters && (
                  <DropdownMenu
                    mainItems={chapters}
                    changeAction={setcurrChapter}
                    defaultValue={{ name: "All chapters" }}
                  />
                )}
                {currChapter && !loadingSections && (
                  <DropdownMenu
                    mainItems={sections}
                    changeAction={setcurrSection}
                    defaultValue={{ name: "All sections" }}
                  />
                )}
              </div>
            ) : (
              <div className="d-flex gap-3 my-4 justify-content-center">
                <DropdownLight
                  mainItems={subjects}
                  changeAction={setcurrSubject}
                  defaultValue={null}
                />
                {currSubject && !loadingChapters && (
                  <DropdownLight
                    mainItems={chapters}
                    changeAction={setcurrChapter}
                    defaultValue={{ name: "All chapters" }}
                  />
                )}
                {currChapter && !loadingSections && (
                  <DropdownLight
                    mainItems={sections}
                    changeAction={setcurrSection}
                    defaultValue={{ name: "All sections" }}
                  />
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
