import React, { useEffect, useState } from "react";
import {
  getChapters as getChaptersApi,
  deleteChapter as deleteChapterApi,
  updateChapter as updateChapterApi,
} from "../../api/questionBank";
import AddChapterModal from "../modals/AddChapterModal";
import AddButton from "./AddButton";
import NavigateButton from "./NavigateButton";
import { useNavigate } from "react-router-dom";

export default function SelectChapter({
  setcurrStep,
  loading,
  setloading,
  subject,
  setchapter,
  revision,
  setmessage,
}) {
  useEffect(() => {
    if (!subject) {
      setcurrStep(1);
    }
  }, []);

  const navigate = useNavigate();
  const [chapters, setchapters] = useState([]);
  const [addChapterModalOpen, setaddChapterModalOpen] = useState(false);
  const [updatedNames, setupdatedNames] = useState([]);

  const getAllChapters = () => {
    setloading(true);
    getChaptersApi({ subjectId: subject._id })
      .then((res) => {
        setupdatedNames(res.map((r) => r.name));
        setchapters(res);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const handleDeleteChapter = (index) => {
    deleteChapterApi({ chapterId: chapters[index]._id })
      .then((res) => {
        getAllChapters();
      })
      .catch((err) => {});
  };

  const handleUpdateChapter = (index) => {
    if (updatedNames[index])
      updateChapterApi({
        chapterId: chapters[index]._id,
        name: updatedNames[index],
      })
        .then((res) => {
          getAllChapters();
        })
        .catch((err) => {
          setmessage({ message: err, success: false });
        });
  };

  const handleNavigate = (index) => {
    setchapter(chapters[index]);
    setcurrStep(3);
  };

  const handleUpdateName = (index, text) => {
    const newItems = [...updatedNames];
    newItems[index] = text;
    setupdatedNames(newItems);
  };

  useEffect(() => {
    getAllChapters();
  }, []);

  return (
    <div>
      {addChapterModalOpen && (
        <AddChapterModal
          subject={subject}
          open={true}
          onConfirm={() => {
            setaddChapterModalOpen(false);
            getAllChapters();
          }}
          onCancel={() => setaddChapterModalOpen(false)}
          setmessage={setmessage}
        />
      )}
      {loading ? (
        <div>
          <div className="spinner-border text-secondary" role="status"></div>
        </div>
      ) : (
        <div className="row">
          {!revision && (
            <div className="col-7 col-sm-6 col-md-4 col-xl-3 col-xxl-2 mt-3 mx-auto mx-sm-0">
              <AddButton
                clickAction={() => setaddChapterModalOpen(true)}
                text={"chapter"}
              />
            </div>
          )}
          {chapters.map((chapter, index) => (
            <div className="col-7 col-sm-6 col-md-4 col-xl-3 col-xxl-2 mt-3 mx-auto mx-sm-0">
              <NavigateButton
                name={chapter.name}
                index={index}
                navigate={() => handleNavigate(index)}
                setupdatedName={handleUpdateName}
                updatedName={updatedNames[index]}
                updateAction={() => handleUpdateChapter(index)}
                deleteAction={() => handleDeleteChapter(index)}
                revision={revision}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
