import React from "react";
import { SimpleBezierEdge, getSimpleBezierPath } from "reactflow";

function MindMapEdge(props) {
  const { sourceX, sourceY, targetX, targetY } = props;

  const [edgePath] = getSimpleBezierPath({
    sourceX: sourceX - 2,
    sourceY: sourceY,
    targetX: targetX - 3,
    targetY: targetY,
  });

  return <SimpleBezierEdge path={edgePath} {...props} />;
}

export default MindMapEdge;
