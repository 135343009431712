import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { createSubject as createSubjectApi } from "../../api/questionBank";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";

export default function AddSubjectModal({
  open,
  onCancel,
  onConfirm,
  setmessage,
}) {
  const [subject, setsubject] = useState(null);
  const [apiCalled, setapiCalled] = useState(false);

  const handleAddSubject = () => {
    if (subject) {
      setapiCalled(true);
      createSubjectApi({
        subjectName: subject,
      })
        .then((res) => {
          setapiCalled(false);
          onConfirm();
        })
        .catch((err) => {
          setapiCalled(false);
          setmessage({ message: err, success: false });
          onCancel();
        });
    }
  };

  return (
    <Modal centered isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center w-100 px-2 mt-2 mb-5">
          <p className="h4 fw-bold text-uppercase text-white">Add a subject</p>
          <p className="fw-bold text-white" role="button" onClick={onCancel}>
            x
          </p>
        </div>
        <input
          type="text"
          className="form-control my-3 primary-input modal-input"
          placeholder="Subject..."
          onChange={(e) => {
            setsubject(e.target.value);
          }}
          onKeyDown={(e) => handleEnterKeyPress(e, handleAddSubject)}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className={`position-relative btn secondary-btn w-100`}
          style={{ height: "50px" }}
          onClick={!apiCalled && handleAddSubject}
        >
          <span className={`fw-bolder loading-text ${apiCalled ? "hide" : ""}`}>
            Add Subject
          </span>
          {apiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
}
