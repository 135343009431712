import React, { useEffect, useState } from "react";
import FreeLightCard from "../components/upgradePlan/FreeLightCard";
import PaidLightCard from "../components/upgradePlan/PaidLightCard";
import { getPlans } from "../api/plan";

export default function Pricing() {
  const [loading, setloading] = useState(true);
  const [paidPlan, setpaidPlan] = useState(null);

  const handleGetPlans = () => {
    getPlans()
      .then((res) => {
        setloading(false);
        setpaidPlan(res[0]);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  useEffect(() => {
    handleGetPlans();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-pricing-wrapper">
      <p className="feature-main-heading h2 fw-bold features-heading text-dark-secondary text-uppercase my-5">
        Pricing
      </p>
      {!loading && (
        <div className="pricing-bg position-relative">
          <div className="pricing-bg-top position-absolute w-100 h-50"></div>
          <div className="container d-flex flex-column justify-content-center pb-4">
            <div className="container my-5" style={{ zIndex: 2 }}>
              <div className="d-flex justify-content-center gap-5 gap-md-3 gap-lg-5 flex-wrap flex-md-nowrap">
                <div className="col-11 col-sm-8 col-md-6 col-lg-4 col-xxl-3 position-relative">
                  <FreeLightCard />
                </div>
                <div className="mt-5 mt-md-0 col-11 col-sm-8 col-md-6 col-lg-4 col-xxl-3">
                  <PaidLightCard plan={paidPlan} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
