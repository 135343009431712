import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSingleSubject as getSingleSubjectApi,
  getChapters as getChaptersApi,
} from "../api/questionBank";
import AddChapterModal from "../components/modals/AddChapterModal";

export default function ViewChapters() {
  const params = useParams();
  const navigate = useNavigate();
  const [subject, setsubject] = useState(null);
  const [chapters, setchapters] = useState([]);

  const [addChapterModalOpen, setaddChapterModalOpen] = useState(false);

  const getSubject = () => {
    getSingleSubjectApi({ subjectId: params.subId })
      .then((res) => {
        setsubject(res);
      })
      .catch((err) => {});
  };

  const getChapters = () => {
    getChaptersApi({ subjectId: params.subId })
      .then((res) => {
        setchapters(res);
      })
      .catch((err) => {});
  };

  const handleViewChapters = (chapId) => {
    navigate(`/subject/${subject._id}/chapter/${chapId}/view-sections`);
  };

  useEffect(() => {
    getSubject();
    getChapters();
  }, []);

  return (
    <div className="container">
      {addChapterModalOpen && (
        <AddChapterModal
          subject={subject}
          open={addChapterModalOpen}
          onCancel={() => {
            setaddChapterModalOpen(false);
          }}
          onConfirm={() => {
            getChapters();
            setaddChapterModalOpen(false);
          }}
        />
      )}

      <div>
        {/* <p className="text-start h4">Your subjects</p> */}
        <div className="text-end mb-3">
          <button
            className="btn btn-primary w-25"
            onClick={() => setaddChapterModalOpen(true)}
          >
            Add Chapter
          </button>
        </div>
        <table className="subjects-table table table-bordered questions-table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {chapters.map((chapter, index) => (
              <tr>
                <td scope="row">{index + 1}</td>
                <td className="">{chapter.name}</td>
                <td>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => handleViewChapters(chapter._id)}
                  >
                    View Sections
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
