import React, { useContext, useEffect, useState } from "react";
import Map from "../components/mindmap/Map";
import Sidebar from "../components/mindmap/Sidebar";
import MapMain from "../components/mindmap/MapMain";
import { AuthContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";

export default function MindMap() {
  const navigate = useNavigate();
  const [activeMindmap, setactiveMindmap] = useState("");
  const [changesMade, setchangesMade] = useState(false);
  const [saveModalOpen, setsaveModalOpen] = useState(false);
  const [mapIdToUpdate, setmapIdToUpdate] = useState("");
  const [mindmapLoading, setmindmapLoading] = useState(false);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser?.plan.plan == "free") {
      return navigate("/dashboard");
    }
  }, []);

  return (
    <div>
      <div className="d-flex light-bg">
        <Sidebar
          mindmapLoading={mindmapLoading}
          changesMade={changesMade}
          setsaveModalOpen={setsaveModalOpen}
          activeMindmap={activeMindmap}
          setactiveMindmap={setactiveMindmap}
          setmapIdToUpdate={setmapIdToUpdate}
        />
        <MapMain
          mindmapLoading={mindmapLoading}
          setmindmapLoading={setmindmapLoading}
          mapId={activeMindmap ? activeMindmap : null}
          setchangesMade={setchangesMade}
          saveModalOpen={saveModalOpen}
          setsaveModalOpen={setsaveModalOpen}
          mapIdToUpdate={mapIdToUpdate}
          setactiveMindmap={setactiveMindmap}
          setmapIdToUpdate={setmapIdToUpdate}
        />
      </div>
    </div>
  );
}
