import { axiosClient } from "./axios";

export const getPlans = async () => {
  try {
    const res = await axiosClient.get(`/plan`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getSinglePlan = async ({ id }) => {
  try {
    const res = await axiosClient.get(`/plan/${id}`);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updatePlan = async ({ data, id }) => {
  try {
    const res = await axiosClient.put(`/plan/${id}`, data);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};
