import React from "react";

export default function ProgressBar({ currStep, loading, setcurrStep }) {
  return (
    <div>
      {!loading && (
        <div className="container position-relative">
          <div className="row">
            <br />
            <div className="col-md-12">
              <div className="progress mt-3">
                <div
                  className={`progress-${
                    currStep == 2
                      ? "25"
                      : currStep == 3
                      ? "50"
                      : currStep == 4
                      ? "75"
                      : ""
                  }`}
                ></div>
                <div
                  onClick={() => setcurrStep(1)}
                  role="button"
                  className={`one ${
                    currStep >= 1 ? "success-color" : "no-color"
                  }`}
                ></div>
                <div
                  onClick={() => setcurrStep(2)}
                  role="button"
                  className={`two ${
                    currStep >= 2 ? "success-color" : "no-color"
                  }`}
                ></div>
                <div
                  onClick={() => setcurrStep(3)}
                  role="button"
                  className={`three ${
                    currStep >= 3 ? "success-color" : "no-color"
                  }`}
                ></div>
                <div
                  onClick={() => setcurrStep(4)}
                  role="button"
                  className={`four ${
                    currStep >= 4 ? "success-color" : "no-color"
                  }`}
                ></div>
                <div className="progress-bar"></div>
                <div
                  className="progress-text text-uppercase d-flex justify-content-between position-absolute mt-4"
                  style={{ left: "20%", width: "67%" }}
                >
                  <p className={`${currStep == 1 ? "active" : ""} text-white`}>
                    subject
                  </p>
                  <p className={`${currStep == 2 ? "active" : ""} text-white`}>
                    chapter
                  </p>
                  <p className={`${currStep == 3 ? "active" : ""} text-white`}>
                    section
                  </p>
                  <p className={`${currStep == 4 ? "active" : ""} text-white`}>
                    add question
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
