import React, { useEffect, useState } from "react";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import { deleteCoupon, updateCoupon } from "../../api/coupon";

const CouponRow = ({ coupon, setcoupons }) => {
  const [couponName, setcouponName] = useState("");
  const [couponAmount, setcouponAmount] = useState("");
  const [editing, setediting] = useState(false);

  const handleEditCoupon = () => {
    updateCoupon({
      data: { name: couponName, amount: couponAmount },
      id: coupon._id,
    })
      .then((res) => {
        setcoupons((prev) => {
          const coupFound = prev.find((cou) => cou._id == coupon._id);
          coupFound.name = couponName;
          coupFound.amount = couponAmount;
          return prev;
        });
        setediting(false);
      })
      .catch((err) => {});
  };
  const handleCancelEdit = () => {
    setediting(false);
  };
  const handleDeleteCoupon = () => {
    deleteCoupon({ id: coupon._id })
      .then((res) => {
        setcoupons((prev) => prev.filter((co) => co._id != coupon._id));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setcouponName(coupon.name);
    setcouponAmount(coupon.amount);
  }, [coupon]);

  return (
    <tr>
      <td className="text-center">
        {!editing ? (
          <p className="text-wrap text-break">{coupon.name}</p>
        ) : (
          <input
            type="text"
            value={couponName}
            onFocus={(e) =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
            className={`border-0 w-100 text-white text-center`}
            style={{ background: "none" }}
            onChange={(e) => setcouponName(e.target.value)}
            onKeyDown={(e) => handleEnterKeyPress(e, handleEditCoupon)}
          />
        )}
      </td>
      <td>
        {!editing ? (
          <p className="text-wrap text-break">₹{coupon.amount}</p>
        ) : (
          <input
            type="number"
            value={couponAmount}
            className={`border-0 w-100 text-white text-center`}
            style={{ background: "none" }}
            onChange={(e) => setcouponAmount(e.target.value)}
            onKeyDown={(e) => handleEnterKeyPress(e, handleEditCoupon)}
          />
        )}
      </td>
      <td>
        {editing ? (
          <button
            className={`btn btn-outline-light-mode btn-outline-primary btn-sm text-white`}
            onClick={() => handleEditCoupon()}
            disabled={!(couponName && couponAmount)}
          >
            Done
          </button>
        ) : (
          <button
            className={`btn btn-outline-light-mode btn-outline-primary btn-sm text-white`}
            onClick={() => setediting(true)}
          >
            Update
          </button>
        )}
      </td>
      <td>
        {editing ? (
          <button
            className={`btn btn-outline-light-mode btn-outline-danger btn-sm text-white`}
            onClick={handleCancelEdit}
          >
            Cancel
          </button>
        ) : (
          <button
            className={`btn btn-outline-light-mode btn-outline-danger btn-sm text-white`}
            onClick={handleDeleteCoupon}
          >
            Delete
          </button>
        )}
      </td>
    </tr>
  );
};

export default CouponRow;
