import React, { useEffect, useRef, useState } from "react";
import DownArrow from "../../assets/images/icons/caret-down.svg";
import { updateFolder } from "../../api/folder";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import { udpateMindmap } from "../../api/mindmap";

export default function Folder({
  mindmapLoading,
  folder,
  setcurrentActiveMenu,
  setOptionsPosition,
  changesMade,
  setOptionsVisible,
  setmapIdToUpdate,
  setsaveModalOpen,
  setactiveMindmap,
  activeMindmap,
  currentActiveMenu,
  renameEnabledRef,
  setfolders,
  setcontextOptionsId,
  contextOptionsId,
}) {
  const divRef = useRef(null);
  const [showFolderDropdown, setshowFolderDropdown] = useState(true);
  const mindmapOptionsRef = useRef(null);
  const folderOptionsRef = useRef(null);
  const handleContextMenu = (e, obj, type) => {
    setcontextOptionsId(obj._id);
    e.stopPropagation();
    setcurrentActiveMenu(type);

    if (type == "Mindmap") {
      // setmindmapOptionsActive(obj);
    } else {
      // setfolderOptionsActive(obj);
    }
    e.preventDefault();
    const clickX = e.clientX;
    const clickY = e.clientY;
    setOptionsPosition({ x: clickX, y: clickY });
    setOptionsVisible(true);
  };

  const handleMindmapChange = (id) => {
    if (!mindmapLoading)
      if (activeMindmap != id)
        if (changesMade) {
          setmapIdToUpdate(id);
          setsaveModalOpen(true);
        } else {
          setactiveMindmap(id);
        }
  };

  const handleFolderToggle = (e) => {
    if (!(currentActiveMenu == "Folder" && contextOptionsId == folder._id)) {
      const folderDiv = document.getElementById("collapse-" + folder._id);
      folderDiv.classList.toggle("show");

      setshowFolderDropdown(!showFolderDropdown);
    }
  };

  const handleFolderRename = (folderName) => {
    if (folderName) {
      updateFolder({ folderId: folder._id, folderName })
        .then((res) => {
          setfolders((prev) => {
            prev.map((fol) => {
              if (fol._id == folder._id) {
                fol.name = folderName;
              }
              return fol;
            });
            return prev;
          });
          setcontextOptionsId("");
          // setfolderOptionsActive("");
          renameEnabledRef.current = false;
        })
        .catch((err) => {});
    }
  };
  const handleMindmapRename = ({ mindmapName, mindmapId }) => {
    if (mindmapName) {
      udpateMindmap({ mindmapId: mindmapId, data: { name: mindmapName } })
        .then((res) => {
          setfolders((prev) => {
            prev.map((fol) => {
              if (fol._id == folder._id) {
                fol.mindmaps.map((map) => {
                  if (map._id == mindmapId) {
                    map.name = mindmapName;
                  }
                  return map;
                });
              }
              return fol;
            });
            return prev;
          });
          setcontextOptionsId("");
          // setmindmapOptionsActive("");
          renameEnabledRef.current = false;
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // if (!renameEnabledRef.current)
      if (!(event.target.tagName == "svg" || event.target.tagName == "path")) {
        if (
          mindmapOptionsRef.current &&
          !mindmapOptionsRef.current.contains(event.target)
        ) {
          if (!event.target.className.includes("sidebar-delete")) {
            setcontextOptionsId("");
          }
          renameEnabledRef.current = false;
          // setcurrentActiveMenu("");
          // setfolderOptionsActive("");
          // setmindmapOptionsActive("");
          setOptionsVisible(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="mb-4"
      key={folder._id}
      id={folder._id}
      ref={folderOptionsRef}
      onContextMenu={(e) => handleContextMenu(e, folder, "Folder")}
    >
      <div
        className="d-flex align-items-center gap-2"
        type="button"
        data-bs-toggle="collapse"
        aria-expanded="false"
        aria-controls={`collapse-${folder._id}`}
        onClick={handleFolderToggle}
      >
        <img
          src={DownArrow}
          className={`folder-dropdown-icon ${
            showFolderDropdown ? "" : "rotate"
          }`}
          style={{ height: "18px" }}
        />
        {renameEnabledRef.current &&
        currentActiveMenu == "Folder" &&
        contextOptionsId == folder._id ? (
          <input
            className="form-control-lg bg-transparent border-0 primary-text folder-edit w-100"
            defaultValue={folder.name}
            autoFocus
            onKeyDown={(e) => {
              handleEnterKeyPress(e, () => handleFolderRename(e.target.value));
            }}
          />
        ) : (
          <h5 className="primary-text m-0 text-break">{folder.name}</h5>
        )}
      </div>
      <div
        className="folder-collapse collapse show"
        id={`collapse-${folder._id}`}
        ref={divRef}
      >
        {folder.mindmaps.map((mindmap) => (
          <div
            key={mindmap._id}
            id={mindmap._id}
            ref={mindmapOptionsRef}
            role="button"
            onClick={() => handleMindmapChange(mindmap._id)}
            onContextMenu={(e) => handleContextMenu(e, mindmap, "Mindmap")}
            style={{
              marginLeft: "1.2rem",
            }}
          >
            {renameEnabledRef.current &&
            currentActiveMenu == "Mindmap" &&
            contextOptionsId == mindmap._id ? (
              <input
                className="form-control-lg bg-transparent border-0 text-white folder-edit w-100"
                defaultValue={mindmap.name}
                autoFocus
                onKeyDown={(e) => {
                  handleEnterKeyPress(e, () =>
                    handleMindmapRename({
                      mindmapId: mindmap._id,
                      mindmapName: e.target.value,
                    })
                  );
                }}
              />
            ) : (
              <h6
                className={`text-white text-start p-2 text-break rounded ${
                  activeMindmap == mindmap._id ? "bg-secondary fw-bold" : ""
                }`}
                style={{ width: "fit-content" }}
              >
                {mindmap.name}
              </h6>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
