import React, { useContext, useEffect, useRef, useState } from "react";
import { getSinglePlan } from "../api/plan";
import { useNavigate, useParams } from "react-router-dom";
import {
  createOrder as createOrderApi,
  verifyPayment as verifyPaymentApi,
} from "../api/payment";
import { AuthContext } from "../contexts/UserContext";
import { verifyCoupon } from "../api/coupon";
import DiscoutIcon from "../assets/images/icons/discount.svg";
import checkoutImg1 from "../assets/images/icons/checkout/1.svg";
import checkoutImg2 from "../assets/images/icons/checkout/2.svg";
import checkoutImg3 from "../assets/images/icons/checkout/3.svg";
import checkoutImg4 from "../assets/images/icons/checkout/4.svg";
import checkoutImg5 from "../assets/images/icons/checkout/5.svg";
import checkoutImg6 from "../assets/images/icons/checkout/6.svg";
import checkoutImg7 from "../assets/images/icons/checkout/7.svg";
import PlusIcon from "../assets/images/icons/plus-black.svg";
import useClickOutside from "../hooks/useClickOutside";
import { motion } from "framer-motion";

const Checkout = () => {
  const navigate = useNavigate();

  const couponDivRef = useRef();

  useClickOutside(couponDivRef, () => {
    if (!couponApplied) {
      setcouponName("");
      setapplyCouponActive(false);
      setshowCouponError(false);
    }
  });

  const { setCurrentUser } = useContext(AuthContext);
  const [paidApiCalled, setpaidApiCalled] = useState(false);
  const [couponName, setcouponName] = useState("");
  const [message, setmessage] = useState({ message: "", success: false });
  const [discount, setdiscount] = useState(0);
  const [amountToPay, setamountToPay] = useState(0);
  const [applyCouponActive, setapplyCouponActive] = useState(false);
  const [isValidCoupon, setisValidCoupon] = useState(false);
  const [showCouponError, setshowCouponError] = useState(false);
  const [couponApplied, setcouponApplied] = useState(false);

  const params = useParams();

  const { currentUser } = useContext(AuthContext);

  const [plan, setplan] = useState(null);
  const [loading, setloading] = useState(true);

  const handleOpenRazorpay = (data) => {
    const options = {
      key: process.env.REACT_APP_KEY_ID,
      amount: Number(data.amount),
      currency: data.currency,
      order_id: data.id,
      name: "FINBITE",
      description: "FINBITE",
      handler: function (res) {
        verifyPaymentApi({
          amount: data.amount,
          razorpay_order_id: res.razorpay_order_id,
          razorpay_signature: res.razorpay_signature,
          razorpay_payment_id: res.razorpay_payment_id,
        })
          .then((res) => {
            setCurrentUser(res);
            navigate("/dashboard");
            window.location.reload();
          })
          .catch((err) => {});
      },
      prefill: {
        name: currentUser.name,
        email: currentUser.email,
        // contact: "123123123",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleCreateOrder = () => {
    setpaidApiCalled(true);
    createOrderApi({ amount: amountToPay })
      .then((res) => {
        setpaidApiCalled(false);
        handleOpenRazorpay(res);
      })
      .catch((err) => {
        setmessage({ message: err.data, success: false });
        setpaidApiCalled(false);
      });
  };

  const handleVerifyCoupon = () => {
    verifyCoupon({ name: couponName })
      .then((res) => {
        const discounTAmount =
          plan.price - res.amount < 0 ? plan.price : res.amount;
        setamountToPay(plan.price - discounTAmount);
        setdiscount(discounTAmount);
        setcouponApplied(true);
        setisValidCoupon(true);
      })
      .catch((err) => {
        setshowCouponError(true);
        setisValidCoupon(false);
      });
  };

  const handleRemoveCoupon = () => {
    setisValidCoupon(false);
    setcouponApplied(false);
    setamountToPay(plan.price);
    setdiscount(0);
  };

  useEffect(() => {
    getSinglePlan({ id: params.id })
      .then((res) => {
        setamountToPay(res.price);
        setplan(res);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center h-100 my-4"
      style={{ minHeight: "75vh" }}
    >
      {!loading && (
        <motion.div
          className="checkout-card font-jakarta d-flex flex-column gap-4 position-relative"
          initial={{ opacity: 0, top: "20vh" }}
          animate={{
            opacity: 1,
            top: "0vh",
          }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <svg
            width="80"
            height="24"
            viewBox="0 0 87 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.571777 27.1669L5.46732 0.755371H16.2944L15.3403 5.90262H9.95755L9.17872 10.1044H14.2847L13.3417 15.1917H8.23576L6.01608 27.1669H0.571777ZM12.9061 27.1669L17.8016 0.755371H23.0306L18.1351 27.1669H12.9061ZM20.2881 27.1669L25.1838 0.755371H30.7511L30.8977 13.4059L33.2425 0.755371H38.4715L33.576 27.1669H28.2855L28.0264 13.9611L25.5787 27.1669H20.2881ZM35.9752 27.1669L40.8707 0.755371H48.2067C50.1753 0.755371 51.5184 1.22558 52.236 2.16599C52.9659 3.0964 53.1314 4.63706 52.7326 6.788L52.5185 7.94351C52.2885 9.18405 51.8716 10.1895 51.2673 10.9598C50.6734 11.7302 49.8878 12.2354 48.9103 12.4755C50.0746 12.7756 50.759 13.4809 50.9634 14.5914C51.1797 15.6919 51.1405 17.0375 50.8456 18.6282C50.5286 20.3389 50.0872 21.8345 49.5219 23.1152C48.9563 24.3957 48.1771 25.3911 47.1843 26.1015C46.1914 26.8117 44.9004 27.1669 43.3112 27.1669H35.9752ZM44.317 10.7047H45.4243C45.9267 10.7047 46.285 10.5146 46.499 10.1345C46.713 9.75429 46.8691 9.2991 46.9675 8.76886L47.4597 6.1127C47.6174 5.26233 47.3117 4.83714 46.5428 4.83714H45.4047L44.317 10.7047ZM42.6662 22.5148C44.071 22.5148 44.8937 21.8645 45.1349 20.564L45.7468 17.2626C45.8859 16.5122 45.8773 15.922 45.7212 15.4918C45.5772 15.0516 45.1875 14.8315 44.5518 14.8315H43.5521L42.1335 22.4848C42.3554 22.5049 42.5329 22.5148 42.6662 22.5148ZM50.8008 27.1669L55.6965 0.755371H60.9254L56.0299 27.1669H50.8008ZM60.5052 27.1669L64.4607 5.82759H61.2465L62.1865 0.755371H74.0287L73.0885 5.82759H69.8742L65.9189 27.1669H60.5052ZM70.6403 27.1669L75.5358 0.755371H86.3629L85.4173 5.85759H80.096L79.1418 11.0048H84.2479L83.3271 15.972H78.2213L77.1003 22.0197H82.7752L81.8212 27.1669H70.6403Z"
              fill="url(#paint0_linear_275_198)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_275_198"
                x1="-58.4239"
                y1="9.39438"
                x2="-35.2994"
                y2="62.9726"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B39FFF" />
                <stop offset="1" stop-color="#6A1ED2" />
              </linearGradient>
            </defs>
          </svg>
          <p className="h3 fw-bold">Payment Summary</p>
          <div>
            <div className="d-flex justify-content-between">
              <p className="h6 text-gray-2">Finbite yearly subscription</p>
              <p className="h6 text-gray-2">₹{plan.price.toFixed(2)}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="h6 fw-bold">Subtotal</p>
              <p className="h6 fw-bold">₹{plan.price.toFixed(2)}</p>
            </div>
          </div>
          <div
            className="py-4"
            style={{
              borderTop: "1px dashed black",
              borderBottom: "1px dashed black",
            }}
            ref={couponDivRef}
          >
            {applyCouponActive ? (
              <div
                className="bg-white d-flex justify-content-between align-items-center p-2 rounded-3"
                style={{
                  border: `1px solid ${
                    showCouponError && !isValidCoupon ? "#FF4949" : "#D1D1D1"
                  }`,
                  height: "50px",
                }}
              >
                {couponApplied && isValidCoupon ? (
                  <div
                    className="d-flex items-center gap-2"
                    style={{ marginLeft: "12px" }}
                  >
                    <p
                      className={`m-0 fw-semibold ${
                        isValidCoupon && couponApplied ? "text-green" : ""
                      }`}
                    >
                      {couponName}
                    </p>
                    <div
                      className={`bg-green rounded-circle d-flex align-items-center justify-content-center`}
                      style={{
                        width: "20px",
                        height: "20px",
                        opacity: isValidCoupon ? 1 : 0,
                        minWidth: "20px",
                        minHeight: "20px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#ffffff"
                        viewBox="0 0 512 512"
                        style={{ width: "15px", marginTop: "0px" }}
                      >
                        <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                      </svg>
                    </div>
                  </div>
                ) : (
                  <input
                    placeholder="Enter coupon code"
                    type="text"
                    className={`form-control w-100 border-0 fw-semibold`}
                    style={{
                      outline: "none",
                      boxShadow: "none",
                    }}
                    value={couponName}
                    onChange={(e) => {
                      setshowCouponError(false);
                      setcouponName(e.target.value.toUpperCase());
                    }}
                  />
                )}
                {couponApplied && isValidCoupon ? (
                  <button
                    className={`btn text-nowrap m-0 p-0 fw-semibold text-purple`}
                    style={{
                      boxShadow: "none",
                    }}
                    onClick={handleRemoveCoupon}
                  >
                    Remove{" "}
                    <span className="d-none d-sm-inline fw-semibold">
                      Coupon
                    </span>
                  </button>
                ) : (
                  <button
                    disabled={!couponName}
                    className={`btn text-nowrap m-0 p-0 fw-semibold ${
                      !couponName ? "text-gray-2" : "text-purple"
                    }`}
                    style={{
                      boxShadow: "none",
                    }}
                    onClick={handleVerifyCoupon}
                  >
                    Apply{" "}
                    <span className="d-none d-sm-inline fw-semibold">
                      Coupon
                    </span>
                  </button>
                )}
              </div>
            ) : (
              <div
                className="bg-white d-flex justify-content-between align-items-center p-2 rounded-3"
                style={{ border: "1px solid #D1D1D1", cursor: "pointer" }}
                onClick={() => setapplyCouponActive(true)}
              >
                <div className="d-flex gap-2 align-items-center">
                  <img src={DiscoutIcon} style={{ height: "32px" }} />
                  <p className="m-0 fw-semibold">Have a coupon code?</p>
                </div>
                <img src={PlusIcon} style={{ width: "16px", height: "16px" }} />
                {/* <p className="m-0 fw-semibold h5" style={{ cursor: "pointer" }}>
                +
              </p> */}
              </div>
            )}
          </div>
          <div
            className="bg-white p-2 rounded-3"
            style={{ border: "1px solid #D1D1D1" }}
          >
            <p className="h6 fw-bold">Service</p>
            <div className="d-flex justify-content-between">
              <p className="h6 text-gray-2">Subscription Amount</p>
              <p className="h6 text-gray-2">₹{plan.price.toFixed(2)}</p>
            </div>
            <div
              className="d-flex justify-content-between mb-2"
              style={{ borderBottom: "1px dashed black" }}
            >
              <p className="h6 text-gray-2">Discount</p>
              <p className="h6 text-gray-2">(-) ₹{discount.toFixed(2)}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="h6 fw-bold">Amount to be paid:</p>
              <p className="h6 fw-bold">₹{amountToPay.toFixed(2)}</p>
            </div>
          </div>
          <button
            disabled={paidApiCalled}
            className={`position-relative pay-btn w-100`}
            onClick={handleCreateOrder}
          >
            <span
              className={`fw-bolder loading-text ${
                paidApiCalled ? "hide" : ""
              }`}
            >
              Proceed to pay ₹{amountToPay.toFixed(2)}
            </span>
            {paidApiCalled && (
              <div className="loading-4 loading">
                <span className="span1"></span>
                <span className="span2"></span>
                <span className="span3"></span>
              </div>
            )}
          </button>
          <div className="d-flex align-items-center justify-content-between w-75 mx-auto">
            <img src={checkoutImg1} style={{ height: "20px", width: "12%" }} />
            <img src={checkoutImg2} style={{ height: "20px", width: "12%" }} />
            <img src={checkoutImg3} style={{ height: "20px", width: "12%" }} />
            <img src={checkoutImg4} style={{ height: "20px", width: "12%" }} />
            <img src={checkoutImg5} style={{ height: "20px", width: "12%" }} />
            <img src={checkoutImg6} style={{ height: "20px", width: "12%" }} />
            <img src={checkoutImg7} style={{ height: "20px", width: "12%" }} />
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Checkout;
