import { axiosClient } from "./axios";

export const createOrder = async ({ amount }) => {
  try {
    const response = await axiosClient.post("/payment/create-order", {
      amount,
    });
    // const response = await axiosClient.post("/phonepe/payment");

    return response.data;
  } catch (err) {
    throw err.response;
  }
};

export const verifyPayment = async ({
  razorpay_order_id,
  razorpay_payment_id,
  razorpay_signature,
  amount,
}) => {
  try {
    const response = await axiosClient.post("/payment/verify", {
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
      amount,
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
