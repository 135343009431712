import { axiosClient } from "./axios";

export const getFolders = async () => {
  try {
    const response = await axiosClient.get(`/folder/all`);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const createFolder = async ({ folderName }) => {
  try {
    const response = await axiosClient.post(`/folder`, { folderName });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const deleteFolder = async ({ folderId }) => {
  try {
    const response = await axiosClient.delete(`/folder/${folderId}`);

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const updateFolder = async ({ folderId, folderName }) => {
  try {
    const response = await axiosClient.put(`/folder/${folderId}`, {
      folderName,
    });

    return response.data;
  } catch (err) {
    throw err.response.data;
  }
};
