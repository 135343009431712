import React, { useContext, useState } from "react";
import {
  deleteQuestion as deleteQuestionApi,
  updateQuestion as updateQuestionApi,
} from "../../api/questionBank";
import { AuthContext } from "../../contexts/UserContext";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";

export default function Questions({
  questions,
  getAllQuestions,
  setmessage,
  subjects,
}) {
  const { darkMode } = useContext(AuthContext);
  const [currEditing, setcurrEditing] = useState(-1);
  const [currEditQuestion, setcurrEditQuestion] = useState("");
  const [currEditAnswer, setcurrEditAnswer] = useState("");
  const [editingEnabled, seteditingEnabled] = useState(false);
  const [editQuestionApiCalled, seteditQuestionApiCalled] = useState(false);

  const editQuestion = (index) => {
    let questionToEdit = questions[index];
    setcurrEditAnswer(questionToEdit.answer);
    setcurrEditQuestion(questionToEdit.question);
    seteditingEnabled(true);
    setcurrEditing(index);
  };
  const deleteQuestion = (index) => {
    deleteQuestionApi({ questionId: questions[index]._id })
      .then((res) => {
        getAllQuestions();
      })
      .catch((err) => {
        setmessage({ message: err, success: false });
      });
  };

  const handleEditQuestion = () => {
    if (currEditQuestion && currEditAnswer && !editQuestionApiCalled) {
      seteditQuestionApiCalled(true);
      updateQuestionApi({
        question: currEditQuestion,
        answer: currEditAnswer,
        questionId: questions[currEditing]._id,
        sectionId: questions[currEditing],
      })
        .then((res) => {
          getAllQuestions();
          seteditingEnabled(false);
          setcurrEditQuestion(null);
          setcurrEditAnswer(null);
          setcurrEditing(-1);
          seteditQuestionApiCalled(false);
        })
        .catch((err) => {
          setmessage({ message: err, success: false });
          seteditQuestionApiCalled(false);
        });
    }
  };

  const handleCancelEdit = () => {
    seteditingEnabled(false);
    setcurrEditQuestion(null);
    setcurrEditAnswer(null);
    setcurrEditing(-1);
  };

  return (
    <div className="w-100 m-auto px-md-5">
      {questions.length > 0 ? (
        <table
          className={`table table-bordered table-responsive questions-table ${
            darkMode ? "text-white" : "text-dark table-border-dark bg-white"
          }`}
        >
          <thead>
            <tr>
              <th scope="col">Question</th>
              <th scope="col">Answer</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question, index) => (
              <tr key={index}>
                <td>
                  {currEditing != index ? (
                    <p className="text-start text-wrap text-break">
                      {question.question}
                    </p>
                  ) : (
                    <input
                      type="text"
                      value={currEditQuestion}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      className={`border-0 w-100 ${
                        darkMode ? "text-white" : "text-dark"
                      }`}
                      style={{ background: "none" }}
                      onChange={(e) => setcurrEditQuestion(e.target.value)}
                      onKeyDown={(e) =>
                        handleEnterKeyPress(e, handleEditQuestion)
                      }
                    />
                  )}
                </td>
                <td>
                  {currEditing != index ? (
                    <p className="text-start text-wrap text-break">
                      {question.answer}
                    </p>
                  ) : (
                    <input
                      type="text"
                      value={currEditAnswer}
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )
                      }
                      className={`border-0 w-100 ${
                        darkMode ? "text-white" : "text-dark"
                      }`}
                      style={{ background: "none" }}
                      onChange={(e) => setcurrEditAnswer(e.target.value)}
                      onKeyDown={(e) =>
                        handleEnterKeyPress(e, handleEditQuestion)
                      }
                    />
                  )}
                </td>
                {editingEnabled ? (
                  <td>
                    {currEditing == index ? (
                      <button
                        className={`btn btn-outline-light-mode btn-outline-primary btn-sm ${
                          darkMode ? "text-white" : "text-dark"
                        }`}
                        onClick={() => handleEditQuestion()}
                        disabled={!(currEditQuestion && currEditAnswer)}
                      >
                        Done
                      </button>
                    ) : (
                      <button
                        disabled
                        className={`btn btn-outline-light-mode btn-outline-primary btn-sm ${
                          darkMode ? "text-white" : "text-dark"
                        }`}
                        onClick={() => editQuestion(index)}
                      >
                        Update
                      </button>
                    )}
                  </td>
                ) : (
                  <td>
                    <button
                      className={`btn btn-outline-light-mode btn-outline-primary btn-sm ${
                        darkMode ? "text-white" : "text-dark"
                      }`}
                      onClick={() => editQuestion(index)}
                    >
                      Update
                    </button>
                  </td>
                )}
                {editingEnabled ? (
                  <td>
                    {currEditing == index ? (
                      <button
                        className={`btn btn-outline-light-mode btn-outline-danger btn-sm ${
                          darkMode ? "text-white" : "text-dark"
                        }`}
                        onClick={() => handleCancelEdit()}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        disabled
                        className={`btn btn-outline-light-mode btn-outline-danger btn-sm ${
                          darkMode ? "text-white" : "text-dark"
                        }`}
                        onClick={() => deleteQuestion(index)}
                      >
                        Delete
                      </button>
                    )}
                  </td>
                ) : (
                  <td>
                    <button
                      className={`btn btn-outline-light-mode btn-outline-danger btn-sm ${
                        darkMode ? "text-white" : "text-dark"
                      }`}
                      onClick={() => deleteQuestion(index)}
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : subjects.length > 0 ? (
        <div className="mt-5">
          <h6 className="text-uppercase text-secondary">
            No questions to show
          </h6>
        </div>
      ) : (
        <div className="mt-5">
          <h6 className="text-uppercase text-secondary">
            no subjects available
          </h6>
        </div>
      )}
    </div>
  );
}
