import React, { useContext, useEffect, useState } from "react";
import { getAllQuestions as getAllQuestionsApi } from "../api/questionBank";
import { AuthContext } from "../contexts/UserContext";

export default function Search({ filterQuestions, placeholder }) {
  const { darkMode } = useContext(AuthContext);

  const [currentSearchValue, setcurrentSearchValue] = useState("");

  useEffect(() => {
    filterQuestions(currentSearchValue);
  }, [currentSearchValue]);

  return (
    <div>
      <div className="m-auto database-search-wrapper">
        <input
          className={`form-control database-search ${
            darkMode ? "search-input" : "search-light"
          }`}
          type="text"
          placeholder={placeholder}
          value={currentSearchValue}
          onChange={(e) => {
            setcurrentSearchValue(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
