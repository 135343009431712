import React, { useEffect, useState } from "react";
import WaterLevelImg from "../../assets/images/icons/water-level.svg";

export default function TodoAnimation({
  completion,
  totalTasks,
  showBubbles,
  setshowBubbles,
}) {
  const handleToggleBubbles = () => {
    if (!showBubbles) {
      setshowBubbles(true);
    }
  };

  const [isSmall, setIsSmall] = useState(false);

  function handleBoxClick(e) {
    setIsSmall(true);
    setTimeout(() => setIsSmall(false), 200);
  }

  return (
    <div
      className={`position-relative todo-anim ${
        isSmall ? "todo-anim-small" : ""
      }`}
      role="button"
      onClick={(e) => {
        handleToggleBubbles();
        handleBoxClick(e);
      }}
    >
      <div className="position-absolute w-100 h-100" style={{ zIndex: 2 }}>
        {totalTasks > 0 ? (
          <p className="text-uppercase d-flex flex-column justify-content-center h-100 text-white">
            <span className="display-5 fw-bolder">{completion}%</span>
            <span className="h6 fw-bold">task completion</span>
          </p>
        ) : (
          <p className="text-uppercase d-flex flex-column justify-content-center h-100 text-white">
            <span className="h5 fw-bolder px-1">Add tasks to fill the jar</span>
          </p>
        )}
      </div>
      <div
        className="Ocean"
        style={{
          background: `${
            totalTasks > 0 ? (completion <= 99 ? "" : "#00F0FF") : ""
          }`,
        }}
      >
        <svg
          className="Wave"
          viewBox="0 0 12960 2500"
          style={{
            // top: `-7%`,
            top: `${
              completion <= 97 ? 100 - completion : completion < 100 ? 3 : -100
            }%`,
            fill: "#00F0FF",
            zIndex: -1,
          }}
        >
          <path d="M9720,0C8100,0,8100,80,6480,80S4860,0,3240,0,1620,80,0,80V2420H12960V80C11340,80,11340,0,9720,0Z">
            <animate
              dur="1.5s"
              repeatCount="indefinite"
              attributeName="d"
              values="
        M9720,0C8100,0,8100,80,6480,80S4860,0,3240,0,1620,80,0,80V2420H12960V80C11340,80,11340,0,9720,0Z;
        M9720,40C8100,40,8100,120,6480,120S4860,40,3240,40,1620,120,0,120V2420H12960V120C11340,120,11340,40,9720,40Z;
        M9720,0C8100,0,8100,80,6480,80S4860,0,3240,0,1620,80,0,80V2420H12960V80C11340,80,11340,0,9720,0Z
      "
            />
          </path>
        </svg>
      </div>
    </div>
  );
}
