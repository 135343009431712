import React, { useState } from "react";
import SelectSubject from "../components/revision/SelectSubject";
import ViewQuestions from "../components/revision/ViewQuestions";
import { motion } from "framer-motion";

export default function Revision() {
  const [questions, setquestions] = useState([]);
  const [loading, setloading] = useState(false);
  const [subjects, setsubjects] = useState([]);
  const [randomize, setrandomize] = useState(false);
  return (
    <motion.div
      className="container position-relative"
      initial={{ opacity: 0, top: "20vh" }}
      animate={{
        opacity: 1,
        top: "0vh",
      }}
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <SelectSubject
        setquestions={setquestions}
        subjects={subjects}
        setsubjects={setsubjects}
        setloading={setloading}
        randomize={randomize}
        setrandomize={setrandomize}
      />
      <ViewQuestions
        questions={questions}
        loading={loading}
        subjectsLength={subjects.length}
      />
    </motion.div>
  );
}
