import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteUser } from "../../api/user";
import DeletedCrossIcon from "../../assets/images/icons/delete-account-modal/deleted-cross.svg";
import { useNavigate } from "react-router-dom";

export default function ImageModal({ open, imageSrc, onCancel }) {
  const navigate = useNavigate();
  return (
    <Modal centered isOpen={open} className="image-modal mx-4 mx-sm-auto">
      <div className="text-end mb-4">
        <img src={DeletedCrossIcon} onClick={onCancel} role="button" />
      </div>
      <img
        src={imageSrc}
        className="img-fluid"
        style={{
          margin: "auto",
        }}
      />
    </Modal>
  );
}
