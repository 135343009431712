import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { upgradePlan } from "../../api/user";
import CheckIcon from "../../assets/images/icons/check.svg";

export default function FreeCard({ currentUser }) {
  const [apiCalled, setapiCalled] = useState(false);
  const navigate = useNavigate();
  const features = [
    "Up to 1 Subject",
    "Up to 1 Chapter",
    "Up to 1 Section",
    "Up to 50 Questions",
    "Up to 5 To Do Items",
  ];

  const handleFreeSelect = () => {
    if (currentUser?.plan.plan != "free" && !apiCalled) {
      setapiCalled(true);
      upgradePlan()
        .then((res) => {
          navigate("/dashboard");
          setapiCalled(false);
        })
        .catch((err) => {
          setapiCalled(false);
        });
    }
  };

  return (
    <div className="h-100">
      <div className="free-card text-white px-3 py-5 h-100">
        <div className="d-flex flex-column justify-content-center">
          <p className="h4 fw-bolder">Basic</p>
          <p className="display-4 fw-bolder">FREE</p>
        </div>
        <div>
          {features.map((feature) => (
            <div className="d-flex gap-3">
              <div className="bg-white rounded-circle plan-card-img p-1 d-flex flex-column justify-content-center">
                <img src={CheckIcon} />
              </div>
              <p className="small fw-bolder">{feature}</p>
            </div>
          ))}
          <div className="d-flex gap-3" style={{ opacity: 0 }}>
            <div className="bg-white rounded-circle plan-card-img p-1 d-flex flex-column justify-content-center">
              <img src={CheckIcon} />
            </div>
            <p className="small fw-bolder">Free Card</p>
          </div>
          <div className="d-flex gap-3" style={{ opacity: 0 }}>
            <div className="bg-white rounded-circle plan-card-img p-1 d-flex flex-column justify-content-center">
              <img src={CheckIcon} />
            </div>
            <p className="small fw-bolder">Free Card</p>
          </div>
        </div>
        <button
          className={`btn btn-select text-uppercase fw-bolder position-relative w-100 ${
            apiCalled ? "activeLoading" : ""
          }`}
          onClick={() => {
            if (!apiCalled) handleFreeSelect();
          }}
        >
          <span
            className={`text-uppercase fw-bolder loading-text ${
              apiCalled ? "hide" : ""
            }`}
          >
            {currentUser?.plan.plan == "free" ? "current plan" : "select"}
          </span>
          {apiCalled && (
            <div className="loading-4 loading">
              <span className="span1" style={{ background: "#2f80ed" }}></span>
              <span className="span2" style={{ background: "#2f80ed" }}></span>
              <span className="span3" style={{ background: "#2f80ed" }}></span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
}
