import React from "react";

export default function AddButton({ clickAction, text }) {
  return (
    <div
      className="question-bank-add-box d-flex flex-column justify-content-center"
      role="button"
      onClick={clickAction}
    >
      <p className="h6 text-uppercase text-white">
        <span className="fw-bolder">Create</span>
        <br />
        new {text}
      </p>
    </div>
  );
}
