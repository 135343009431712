import React, { useContext } from "react";
import CheckIcon from "../../assets/images/icons/check-white.svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/UserContext";

export default function PaidCard({ setmessage, paidApiCalled, plan }) {
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  //   &#x20B9;

  const handleNavigate = () => {
    if (currentUser?.isVerified) {
      navigate(`/checkout/${plan._id}`);
    } else {
      setmessage({
        message: "Verify your email from profile first.",
        success: false,
      });
    }
  };

  return (
    <div>
      <div className="paid-card text-white px-3 py-5">
        <div className="d-flex flex-column justify-content-center">
          <p className="h4 fw-bolder text-orange">Premium</p>
          <p className="display-4 fw-bolder price-main">
            &#x20B9;{plan.price}
            <sub className="price-sub">/year</sub>
          </p>
        </div>
        <div>
          {plan.features.map((feature) => (
            <div className="d-flex gap-3">
              <div className="bg-primary rounded-circle plan-card-img p-1 d-flex flex-column justify-content-center">
                <img src={CheckIcon} />
              </div>
              <p className="small fw-bolder">{feature}</p>
            </div>
          ))}
        </div>

        <button
          className={`btn btn-subscribe loading-button w-100 ${
            paidApiCalled ? "activeLoading" : ""
          }`}
          onClick={handleNavigate}
        >
          <span
            className={`text-uppercase fw-bolder loading-text ${
              paidApiCalled ? "hide" : ""
            }`}
          >
            subscribe
          </span>
          <div className="loading-4 loading">
            <span className="span1"></span>
            <span className="span2"></span>
            <span className="span3"></span>
          </div>
        </button>
      </div>
    </div>
  );
}
