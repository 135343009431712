import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import { createFolder } from "../../api/folder";

export default function AddFolderModal({ open, onCancel, setfolders }) {
  const [folder, setfolder] = useState("");
  const [apiCalled, setapiCalled] = useState(false);

  const handleAddFolder = () => {
    setapiCalled(true);
    createFolder({ folderName: folder })
      .then((res) => {
        setapiCalled(false);
        setfolders((prev) => [...prev, res]);
        onCancel();
      })
      .catch((err) => {
        setapiCalled(false);
      });
  };

  return (
    <Modal centered isOpen={open}>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center w-100 px-2 mt-2 mb-5">
          <p className="h4 fw-bold text-uppercase text-white">Add a folder</p>
          <p className="fw-bold text-white" role="button" onClick={onCancel}>
            x
          </p>
        </div>
        <input
          type="text"
          className="form-control my-3 primary-input modal-input"
          placeholder="Folder..."
          onChange={(e) => {
            setfolder(e.target.value);
          }}
          onKeyDown={(e) => handleEnterKeyPress(e, handleAddFolder)}
        />
      </ModalBody>
      <ModalFooter>
        <button
          className={`position-relative btn secondary-btn w-100`}
          style={{ height: "50px" }}
          onClick={!apiCalled ? handleAddFolder : null}
          disabled={!folder}
        >
          <span className={`fw-bolder loading-text ${apiCalled ? "hide" : ""}`}>
            Create Folder
          </span>
          {apiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>
      </ModalFooter>
    </Modal>
  );
}
