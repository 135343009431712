import React, { useContext, useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { deleteUser, updateProfile as updateProfileApi } from "../../api/user";
import Moment from "react-moment";
import Message from "../Message";
import { sendEmailVerificationRequest as sendEmailVerificationRequestApi } from "../../api/user";
import CheckIcon from "../../assets/images/icons/profile-check.svg";
import CrossIcon from "../../assets/images/icons/email-cross.svg";
import DeleteAccountModal from "../modals/DeleteAccountModal";
import AccountDeletedModal from "../modals/AccountDeletedModal";

export default function EditProfile({ currentUser, setCurrentUser }) {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [scheduledDate, setscheduledDate] = useState("");
  const [message, setmessage] = useState(null);
  const [updateApiCalled, setupdateApiCalled] = useState(false);

  const handleSendVerificationLink = () => {
    sendEmailVerificationRequestApi()
      .then((res) => {
        setmessage({ message: res, success: true });
      })
      .catch((err) => {
        setmessage({
          message: "Something went wrong",
          success: false,
        });
      });
  };

  useEffect(() => {
    if (currentUser) {
      setname(currentUser.name);
      setemail(currentUser.email);
      setscheduledDate(currentUser?.examDate?.split("-").reverse().join("-"));
    }
  }, [currentUser]);

  const handleUploadProfile = () => {
    if (name && scheduledDate) {
      setupdateApiCalled(true);
      updateProfileApi({
        name,
        examDate: scheduledDate.split("-").reverse().join("-"),
      })
        .then((res) => {
          setCurrentUser({
            ...currentUser,
            username: name,
            examDate: scheduledDate.split("-").reverse().join("-"),
          });
          setmessage({
            message: "Changes saved",
            success: true,
          });
          setupdateApiCalled(false);
        })
        .catch((err) => {
          setmessage({ message: err, success: false });
          setupdateApiCalled(false);
        });
    }
  };

  const [deleteModalOpen, setdeleteModalOpen] = useState(false);
  const [accountDeletedModalOpen, setaccountDeletedModalOpen] = useState(false);

  return (
    <div>
      {deleteModalOpen && (
        <DeleteAccountModal
          open={true}
          onCancel={() => setdeleteModalOpen(false)}
          setdeleteModalOpen={setdeleteModalOpen}
          setmessage={setmessage}
          setaccountDeletedModalOpen={setaccountDeletedModalOpen}
        />
      )}
      {accountDeletedModalOpen && <AccountDeletedModal open={true} />}

      <div className="d-flex flex-column gap-4">
        <div>
          <p className="text-start text-white">Name</p>
          <input
            type="text"
            className="form-control primary-input"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <p className="text-start text-white m-0">Email</p>
            {currentUser?.isVerified ? (
              <div className="d-flex gap-1 align-items-center mb-2">
                <img src={CheckIcon} />
                <p className="text-capitalize text-success fw-bold m-0">
                  verified
                </p>
              </div>
            ) : (
              <div className="d-flex gap-2 gap-md-4 align-items-center mb-2 px-3">
                <div className="d-flex gap-2 align-items-center">
                  <p
                    className="text-white bg-danger rounded-circle p-0 m-0 d-flex flex-column justify-content-center p-1"
                    style={{
                      height: "20px",
                      width: "20px",
                      minHeight: "20px",
                      minWidth: "20px",
                    }}
                  >
                    <img src={CrossIcon} className="h-100 w-100" />
                  </p>
                  <p className="text-capitalize text-danger fw-bold m-0 text-nowrap">
                    email not verified
                  </p>
                </div>
                <p
                  className="text-capitalize text-success fw-bold m-0 text-nowrap"
                  onClick={handleSendVerificationLink}
                  role="button"
                >
                  verify now
                </p>
              </div>
            )}
          </div>
          <input
            type="text"
            className="form-control text-white primary-input"
            defaultValue={email}
            readOnly
          />
        </div>
        <div>
          <p className="text-start text-white">Expected Exam Date</p>
          <Flatpickr
            placeholder="Expected exam date"
            className="form-control primary-input"
            options={{
              minDate: new Date(),
              enableTime: false,
              dateFormat: "d-m-Y",
            }}
            value={scheduledDate}
            onChange={([date]) => {
              setscheduledDate(moment(date).format("DD-MM-YYYY"));
            }}
          />
        </div>

        <div>
          <button
            className={`position-relative btn secondary-btn w-100`}
            style={{ height: "50px" }}
            onClick={handleUploadProfile}
          >
            <span
              className={`fw-bolder loading-text ${
                updateApiCalled ? "hide" : ""
              }`}
            >
              Save Changes
            </span>
            {updateApiCalled && (
              <div className="loading-4 loading">
                <span className="span1"></span>
                <span className="span2"></span>
                <span className="span3"></span>
              </div>
            )}
          </button>
          <div className="mt-5 mb-3">
            <button
              className={`position-relative btn acc-delete-btn`}
              onClick={() => setdeleteModalOpen(true)}
            >
              <span className={`fw-bolder loading-text`}>Delete Account</span>
            </button>
            {/* <button
              className="btn acc-delete-btn"
              onClick={() => setdeleteModalOpen(true)}
            >
              <span>Delete Account</span>
            </button> */}
          </div>
          <div
            className="bg-none position-relative bg-none mb-5 pb-5 text-center"
            style={{ cursor: "default" }}
          >
            {message && (
              <div style={{ top: 0 }}>
                <Message message={message} setmessage={setmessage} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
