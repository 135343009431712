import { applyNodeChanges, applyEdgeChanges } from "reactflow";
import create from "zustand";
import { nanoid } from "nanoid/non-secure";

const useStore = create((set, get) => ({
  isChanged: false,
  nodes: [
    {
      id: "root",
      type: "mindmap",
      data: { label: "", alignment: "center" },
      height: 60,
      width: 200,
      position: { x: 0, y: 0 },
      selected: false,
    },
  ],
  edges: [],
  history: {
    index: -1,
    data: [],
  },
  redoHistory: {
    index: -1,
    data: [],
  },
  initialData: (data) => {
    set({
      nodes: data.nodes,
      edges: data.edges,
      history: {
        index: -1,
        data: [],
      },
      redoHistory: {
        index: -1,
        data: [],
      },
    });
  },

  updateChangesMade: (bool) => {
    set({ isChanged: bool });
  },

  onKeyDown: (e) => {
    if (e.ctrlKey) {
      if (e.key == "z") {
        get().updateChangesMade(true);
        get().undo();
      } else if (e.key == "y") {
        get().updateChangesMade(true);
        get().redo();
      }
    } else if (e.key == "Delete") {
      const selected = get().nodes.find((node) => node.selected == true);
      const children = e.target.children;
      if (children.length > 0) {
        get().updateChangesMade(true);
        const child1Disabled = children[0].classList.contains("nodrag");
        if (selected && selected.id != "root" && !child1Disabled) {
          get().deleteNode(selected.id);
        }
      }
    }
  },

  undo: () => {
    if (get().history.index != -1) {
      get().updateChangesMade(true);
      const dataCopy = [...get().history.data];
      dataCopy.splice(get().history.index, 1);
      set({
        nodes: [...get().history.data[get().history.index].nodes],
        edges: [...get().history.data[get().history.index].edges],
        history: {
          ...get().history,
          index: get().history.index - 1,
          data: dataCopy,
        },
        redoHistory: {
          ...get().redoHistory,
          index: get().redoHistory.index + 1,
          data: [
            ...get().redoHistory.data,
            {
              nodes: [...get().nodes],
              edges: [...get().edges],
            },
          ],
        },
      });
    }
  },
  redo: () => {
    const dataCopy = [...get().redoHistory.data];
    dataCopy.splice(get().redoHistory.index, 1);

    if (get().redoHistory.index != -1) {
      get().updateChangesMade(true);
      set({
        nodes: [...get().redoHistory.data[get().redoHistory.index].nodes],
        edges: [...get().redoHistory.data[get().redoHistory.index].edges],
        redoHistory: {
          ...get().redoHistory,
          index: get().redoHistory.index - 1,
          data: dataCopy,
        },
      });
    }
  },
  deleteNode: (nodeId) => {
    let edgesCopy = [...get().edges];
    const deleteNodeAndChildren = (array, id) => {
      const node = array.find((item) => item.id == id);
      if (!node) return;
      const children = array.filter((item) => item.parentNode == id);

      children.forEach((child) => deleteNodeAndChildren(array, child.id));

      const index = array.indexOf(node);
      if (index != -1) {
        array.splice(index, 1);

        edgesCopy = edgesCopy.filter((edge) => {
          return !(edge.source == node.id || edge.target == node.id);
        });
      }
    };
    const nodesCopy = [...get().nodes];
    deleteNodeAndChildren(nodesCopy, nodeId);
    get().updateChangesMade(true);
    set({
      nodes: nodesCopy,
      edges: edgesCopy,
      history: {
        ...get().history,
        index: get().history.index + 1,
        data: [
          ...get().history.data,
          { nodes: [...get().nodes], edges: [...get().edges] },
        ],
      },
    });
  },
  onNodesChange: (changes) => {
    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });
  },
  onEdgesChange: (changes) => {
    if (changes[0].type != "remove")
      set({
        edges: applyEdgeChanges(changes, get().edges),
      });
  },
  addChildNode: (parentNode, position) => {
    const newNode = {
      id: nanoid(),
      type: "mindmap",
      data: { label: "", alignment: "center" },
      position,
      height: 60,
      width: 200,
      parentNode: parentNode.id,
    };

    const newEdge = {
      id: nanoid(),
      source: parentNode.id,
      target: newNode.id,
    };

    set({
      nodes: [...get().nodes, newNode],
      edges: [...get().edges, newEdge],
      history: {
        ...get().history,
        index: get().history.index + 1,
        data: [
          ...get().history.data,
          { nodes: [...get().nodes], edges: [...get().edges] },
        ],
      },
    });
  },
  updateNodeLabel: ({ nodeId, label }) => {
    const nodesCopy = JSON.parse(JSON.stringify(get().nodes));
    const res = nodesCopy.map((node) => {
      if (node.id == nodeId) {
        node.data = { ...node.data, label };
      }
      return node;
    });
    get().updateChangesMade(true);
    set({
      nodes: res,
      history: {
        ...get().history,
        index: get().history.index + 1,
        data: [
          ...get().history.data,
          { nodes: [...get().nodes], edges: [...get().edges] },
        ],
      },
    });
  },
  updateNodeAlignment: ({ nodeId, alignment }) => {
    const nodesCopy = JSON.parse(JSON.stringify(get().nodes));
    const res = nodesCopy.map((node) => {
      if (node.id == nodeId) {
        node.data = { ...node.data, alignment };
      }
      return node;
    });
    get().updateChangesMade(true);
    set({
      nodes: res,
      history: {
        ...get().history,
        index: get().history.index + 1,
        data: [
          ...get().history.data,
          { nodes: [...get().nodes], edges: [...get().edges] },
        ],
      },
    });
  },
  updateNodeHeight: ({ nodeId, height }) => {
    get().updateChangesMade(true);
    // set({
    //   nodes: applyNodeChanges(changes, get().nodes),
    // });
    const nodesCopy = JSON.parse(JSON.stringify(get().nodes));
    const res = nodesCopy.map((node) => {
      if (node.id == nodeId) {
        node.height = height;
        if (node.style) node.style.height = height;
      }
      return node;
    });
    set({
      nodes: res,
    });
  },
}));

export default useStore;
