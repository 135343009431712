import React from "react";
import ArrowIcon from "../assets/images/icons/contact-arrow.svg";
import InstaIcon from "../assets/images/icons/instagram.svg";
import TwitterIcon from "../assets/images/icons/twitter.svg";
import LinkedInIcon from "../assets/images/icons/linkedin.svg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Contact() {
  const email = "hello@finbite.in";

  const handleOpenMail = () => {
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      const uri = `mailto:${email}`;
      window.location.href = uri;
    } else if (/Win/i.test(navigator.userAgent)) {
      const url = `https://mail.google.com/mail/?view=cm&to=${email}`;
      window.open(url, "_blank");
    } else {
      const url = `https://mail.google.com/mail/?view=cm&to=${email}`;
      window.open(url, "_blank");
    }
  };

  return (
    <motion.div
      className="text-white d-flex flex-column justify-content-center contact-wrapper position-relative position-relative"
      initial={{ opacity: 0, top: "20vh" }}
      animate={{
        opacity: 1,
        top: "0vh",
      }}
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <img
        src={ArrowIcon}
        className="d-none d-md-block"
        style={{
          position: "absolute",
          top: "15vh",
          left: "-15vw",
          height: "30vw",
          width: "30vw",
          minHeight: "300px",
          minWidth: "300px",
          zIndex: 1,
        }}
      />
      <div>
        <p className="h4 fw-bolder mb-3">Having an issue or need support?</p>
        <p className="h5 mb-3">Just drop an email at</p>
        <p
          className="h6 fw-bold d-inline-block py-2 px-3 mb-3 contact-email"
          onClick={handleOpenMail}
          role="button"
        >
          hello@finbite.in
        </p>
        <div className="d-flex justify-content-center align-items-center position-relative mt-5">
          <p className="contact-or">or</p>
        </div>
        <p className="mt-5">Connect on Social Media</p>
        <div className="d-flex gap-3 justify-content-center">
          <Link to={"https://www.instagram.com/finbite/"} target="_blank">
            <img src={InstaIcon} className="contact-links" />
          </Link>
          <Link to={"https://twitter.com/thefinbite"} target="_blank">
            <img src={TwitterIcon} className="contact-links" />
          </Link>
          <Link
            to={"https://www.linkedin.com/company/thefinbite"}
            target="_blank"
          >
            <img src={LinkedInIcon} className="contact-links" />
          </Link>
        </div>
      </div>
    </motion.div>
  );
}
