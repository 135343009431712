import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { get as getApi } from "../api/blog";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Moment from "react-moment";
import Card from "../components/updates/Card";
import { useLocation, useNavigate } from "react-router-dom";
import Search from "../components/Search";
import { AuthContext } from "../contexts/UserContext";
import { motion } from "framer-motion";

export default function Updates() {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const location = useLocation();
  const arrayRef = useRef([]);

  const [blogs, setblogs] = useState([]);
  const [blogsCopy, setblogsCopy] = useState([]);
  const [loading, setloading] = useState(false);

  const filterQuestions = (currentSearchValue) => {
    setblogs(
      blogsCopy.filter(
        (blog) =>
          blog.title.toLowerCase().includes(currentSearchValue.toLowerCase()) ||
          blog.description
            .toLowerCase()
            .includes(currentSearchValue.toLowerCase())
      )
    );
  };

  useEffect(() => {
    // console.log(currentUser);
    if (currentUser?.plan.plan != "paid") {
      return navigate("/dashboard");
    }
    let hash = location.hash.slice(1); // remove the "#" from the hash value
    hash = hash.replaceAll("%20", " ");
    const element = arrayRef.current.find((el) => el?.id === hash); // search for the element in the array that has a matching "id" value
    if (element) {
      element.scrollIntoView(); // scroll to the element
    }
  }, [location, blogs]);

  useEffect(() => {
    setloading(true);
    getApi()
      .then((res) => {
        setblogs(res);
        setblogsCopy(res);
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {loading ? (
        ""
      ) : (
        <motion.div
          className="position-relative"
          initial={{ opacity: 0, top: "20vh" }}
          animate={{
            opacity: 1,
            top: "0vh",
          }}
          transition={{ duration: 0.3, delay: 0.1 }}
        >
          <Search
            filterQuestions={filterQuestions}
            placeholder={" SEARCH UPDATES..."}
          />
          {blogs.length > 0 ? (
            <VerticalTimeline layout="1-column-left" className="p-0 mt-5">
              {blogs.map((blog, index) => {
                return (
                  <div
                    ref={(el) => (arrayRef.current[index] = el)}
                    id={blog._id}
                  >
                    <Card blog={blog} />
                  </div>
                );
              })}
            </VerticalTimeline>
          ) : (
            <div className="mt-5">
              <h6 className="text-uppercase text-secondary">
                No updates available
              </h6>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
}
