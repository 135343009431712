import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteUser } from "../../api/user";
import DeletedMainIcon from "../../assets/images/icons/delete-account-modal/deleted-main.svg";
import DeletedCrossIcon from "../../assets/images/icons/delete-account-modal/deleted-cross.svg";
import { useNavigate } from "react-router-dom";

export default function AccountDeletedModal({ open }) {
  const navigate = useNavigate();
  return (
    <Modal centered isOpen={open} className="delete-modal">
      <ModalBody className="mt-0">
        <p
          className="fw-bold text-white w-100 text-end"
          role="button"
          style={{ right: 0, position: "relative", height: "18px" }}
          onClick={() => navigate("/dashboard")}
        >
          <img src={DeletedCrossIcon} className="h-100" />
        </p>

        <div className="m-auto w-100 text-center">
          <img src={DeletedMainIcon} style={{ height: "50px" }} />
        </div>
      </ModalBody>
      <ModalFooter>
        <p className="text-center mx-4">
          Your account has been deleted successfully. Do you want to create a
          new account?
        </p>
        <button
          className={`position-relative btn secondary-btn w-50 m-auto mt-3 mb-2`}
          style={{ height: "50px" }}
          onClick={() => navigate("/dashboard")}
        >
          <span className={`fw-bolder loading-text`}>Create Account</span>
        </button>
      </ModalFooter>
    </Modal>
  );
}
