import React, { useState } from "react";
import AddQuestions from "../components/questionBank/AddQuestions";
import BreadCrumb from "../components/questionBank/ProgressBar";
import SelectSubject from "../components/databaseManagement/SelectSubject";
import SelectChapter from "../components/databaseManagement/SelectChapter";
import SelectSection from "../components/databaseManagement/SelectSection";
import Message from "../components/Message";
import { motion } from "framer-motion";

export default function QuestionBank() {
  const [currStep, setcurrStep] = useState(1);
  const [section, setsection] = useState("");
  const [subject, setsubject] = useState("");
  const [chapter, setchapter] = useState("");
  const [loading, setloading] = useState(true);
  const [message, setmessage] = useState("");
  return (
    <motion.div
      className="container position-relative"
      initial={{ opacity: 0, top: "20vh" }}
      animate={{
        opacity: 1,
        top: "0vh",
      }}
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      <BreadCrumb
        currStep={currStep}
        loading={loading}
        setcurrStep={setcurrStep}
      />
      <div className="mt-5 pt-3">
        {message && <Message message={message} setmessage={setmessage} />}

        {currStep == 1 ? (
          <SelectSubject
            loading={loading}
            setloading={setloading}
            setsubject={setsubject}
            setcurrStep={setcurrStep}
            setmessage={setmessage}
          />
        ) : currStep == 2 ? (
          <SelectChapter
            setcurrStep={setcurrStep}
            loading={loading}
            setloading={setloading}
            subject={subject}
            setchapter={setchapter}
            setmessage={setmessage}
          />
        ) : currStep == 3 ? (
          <SelectSection
            setcurrStep={setcurrStep}
            loading={loading}
            setloading={setloading}
            subject={subject}
            chapter={chapter}
            setsection={setsection}
            setmessage={setmessage}
          />
        ) : (
          <AddQuestions
            chapterName={chapter.name}
            subjectName={subject.name}
            setcurrStep={setcurrStep}
            section={section}
            loading={loading}
            setloading={setloading}
            setmessage={setmessage}
          />
        )}
      </div>
    </motion.div>
  );
}
