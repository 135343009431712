import React from "react";
import DeleteIcon from "../../assets/images/icons/delete-2.svg";
import CopyIcon from "../../assets/images/icons/copy.svg";
import { cloneMindmap } from "../../api/mindmap";

export default function ContextMenu({
  setoptionsVisible,
  optionsPosition,
  getAllFolders,
  currentActiveMenu,
  setconfirmDeleteModalOpen,
  renameEnabledRef,
  contextOptionsId,
}) {
  const handleCloneMindmap = () => {
    cloneMindmap({ mindmapId: contextOptionsId })
      .then((res) => {
        getAllFolders();
      })
      .catch((err) => {});
  };

  return (
    <>
      <div
        role="button"
        className="mindmap-options-menu px-2 py-2"
        style={{
          zIndex: 100,
          position: "absolute",
          top: optionsPosition.y,
          left: optionsPosition.x,
        }}
      >
        <div
          className="d-flex align-items-center gap-1"
          onClick={(e) => {
            renameEnabledRef.current = true;
            e.stopPropagation();
            setoptionsVisible(false);
          }}
        >
          <span className="fw-bold primary-text">Rename</span>
        </div>
        <hr className="bg-light m-0 my-1" />
        <div
          className="d-flex align-items-center gap-1 sidebar-delete"
          onClick={() => {
            setconfirmDeleteModalOpen(true);
          }}
        >
          {/* <img src={DeleteIcon} style={{ height: "16px" }} /> */}
          <span className="fw-bold primary-text sidebar-delete">Delete</span>
        </div>
        {currentActiveMenu == "Mindmap" ? (
          <div>
            <hr className="bg-light m-0 my-1" />
            <div
              className="d-flex align-items-center gap-1"
              onClick={handleCloneMindmap}
            >
              {/* <img src={CopyIcon} style={{ height: "16px" }} /> */}
              <span className="fw-bold primary-text">Duplicate</span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
