import React, { useContext, useState } from "react";
// import styles from "../../assets/css/login.module.css";
import GoogleIcon from "../../assets/images/icons/google-icon.svg";
import { useNavigate } from "react-router-dom";
import { register as registerApi } from "../../api/user";
import Message from "../Message";
import FlatPickr from "react-flatpickr";
import moment from "moment";
import { motion } from "framer-motion";

export default function Register({ rightPanelActive, togglePanel }) {
  const navigate = useNavigate();
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const [name, setname] = useState(null);
  const [scheduledDate, setscheduledDate] = useState(null);
  const [message, setmessage] = useState("");
  const [signupApiCalled, setsignupApiCalled] = useState(false);
  const [isValid, setisValid] = useState(false);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setemail(newEmail);
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setisValid(emailRegex.test(newEmail));
  };

  const handleSubmit = () => {
    if (name && email && password && scheduledDate) {
      setsignupApiCalled(true);
      registerApi({
        name,
        email,
        password,
        examDate: scheduledDate,
      })
        .then((res) => {
          setsignupApiCalled(false);
          setmessage({
            message: "Account created. Please sign in to the account",
            success: true,
          });
        })
        .catch((err) => {
          setsignupApiCalled(false);
          setmessage({ message: err, success: false });
        });
    }
  };
  return (
    <>
      <motion.div
        className="w-75 mx-auto d-flex flex-column justify-content-md-center h-100 login-right position-relative"
        style={{ maxWidth: "500px" }}
        initial={{ opacity: 0, top: "20vh" }}
        animate={{
          opacity: 1,
          top: "0vh",
          transition: { duration: 0.5, delay: 0.5 },
        }}
      >
        {message && (
          <div className="login-error w-100" style={{ left: 0 }}>
            <Message message={message} setmessage={setmessage} />
          </div>
        )}

        <p className="h4 fw-bold">Member Registration</p>
        <p className="text-purple fw-semibold">
          Register to access exam preparation tools
        </p>
        <div className="d-flex flex-column gap-4 mt-3">
          <div className="form-group with-border border border-secondary rounded-3">
            <label className="mail-label text-purple fw-semibold fs-8">
              Full Name
            </label>
            <div className="position-relative d-flex align-items-center px-2 py-1">
              <div className="logo mb-1" style={{ marginLeft: "3px" }}>
                {/* <img src={UserIcon} /> */}
                <svg
                  width="16"
                  height="15"
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.2083 11.3304C15.7078 12.2842 14.7678 14.161 12.9639 14.161H3.03456C1.2329 14.161 0.291779 12.2842 1.7913 11.3304C3.62864 10.1607 5.78974 9.53778 7.99978 9.54097C10.2961 9.54097 12.4312 10.1996 14.2083 11.3304Z"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99995 7.50005C10.238 7.50005 11.497 6.30107 11.497 4.16955C11.497 2.03803 10.238 0.83905 7.99995 0.83905C5.76186 0.83905 4.50293 2.03803 4.50293 4.16955C4.50293 6.30107 5.76186 7.50005 7.99995 7.50005Z"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
                className="form-control no-outline border-0"
                placeholder="Full Name"
                type="text"
              />
            </div>
          </div>
          <div className="form-group with-border border border-secondary rounded-3">
            <label className="mail-label text-purple fw-semibold fs-8">
              Email
            </label>
            <div className="position-relative d-flex align-items-center px-2 py-1">
              <div className="logo mb-1" style={{ marginLeft: "3px" }}>
                {/* <img src={EmailIcon} /> */}
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.75"
                    y="1.25"
                    width="15.5"
                    height="12.5"
                    rx="3.25"
                    stroke="#828282"
                    stroke-width="1.5"
                  />
                  <path
                    d="M1.5 2L6.45865 6.60446C7.60969 7.67328 9.39031 7.67328 10.5414 6.60446L15.5 2"
                    stroke="#828282"
                    stroke-width="1.5"
                  />
                </svg>
              </div>
              <input
                value={email}
                onChange={handleEmailChange}
                style={{ width: "100%" }}
                className="form-control no-outline border-0"
                placeholder="Email"
                type="text"
              />
              <div
                className={`bg-green rounded-circle`}
                style={{
                  width: "24px",
                  height: "24px",
                  opacity: isValid ? 1 : 0,
                  minWidth: "24px",
                  minHeight: "24px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ffffff"
                  viewBox="0 0 512 512"
                  style={{ width: "15px", marginBottom: "3px" }}
                >
                  <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="form-group with-border border border-secondary rounded-3">
            <label className="mail-label text-purple fw-semibold fs-8">
              Password
            </label>
            <div className="position-relative d-flex align-items-center px-2 py-1">
              <div className="logo mb-1" style={{ marginLeft: "3px" }}>
                <svg
                  width="16"
                  height="19"
                  viewBox="0 0 16 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.56422 14.0397C1.66916 14.9188 2.0839 15.7318 2.73405 16.3328C3.3842 16.9338 4.22721 17.2835 5.11188 17.3192C6.03341 17.3519 6.98013 17.3683 7.99986 17.3683C9.01959 17.3683 9.96631 17.3519 10.8878 17.3179C11.7725 17.2826 12.6157 16.9332 13.2661 16.3325C13.9165 15.7317 14.3315 14.9188 14.4368 14.0397C14.5073 13.4102 14.5564 12.7707 14.5564 12.1223C14.5564 11.4753 14.5073 10.8357 14.4368 10.2063C14.3317 9.32671 13.9166 8.51339 13.266 7.91233C12.6153 7.31127 11.7717 6.96183 10.8866 6.92675C9.92472 6.89278 8.96232 6.87599 7.99986 6.87639C6.98013 6.87639 6.03341 6.89402 5.11188 6.92675C4.22721 6.96241 3.3842 7.31211 2.73405 7.91312C2.0839 8.51413 1.66916 9.32712 1.56422 10.2063C1.48789 10.8422 1.44754 11.4819 1.44336 12.1223C1.44336 12.7707 1.49246 13.4102 1.56422 14.0397Z"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.6722 6.87765V5.30399C11.6722 4.33004 11.2853 3.39598 10.5966 2.70729C9.90795 2.0186 8.97388 1.6317 7.99993 1.6317C7.02598 1.6317 6.09192 2.0186 5.40323 2.70729C4.71454 3.39598 4.32764 4.33004 4.32764 5.30399V6.87765"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 11.3884V12.6473"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                value={password}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                className="form-control no-outline border-0"
                placeholder="Password"
                type="password"
              />
            </div>
          </div>
          <div
            className="form-group with-border border border-secondary rounded-3"
            style={{ height: "48px" }}
          >
            <label className="mail-label text-purple fw-semibold fs-8">
              Expected Exam Date
            </label>
            <div className="position-relative d-flex align-items-center px-2 py-1">
              <div className="logo mb-1" style={{ marginLeft: "3px" }}>
                {/* <img src={DateIcon} /> */}
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.63916 11.3809V10.122C4.63916 9.78808 4.7718 9.46786 5.00789 9.23177C5.24399 8.99567 5.5642 8.86304 5.89809 8.86304H7.15702C7.49091 8.86304 7.81112 8.99567 8.04721 9.23177C8.28331 9.46786 8.41595 9.78808 8.41595 10.122V11.3809C8.41595 11.7148 8.28331 12.035 8.04721 12.2711C7.81112 12.5072 7.49091 12.6398 7.15702 12.6398H5.89809C5.5642 12.6398 5.24399 12.5072 5.00789 12.2711C4.7718 12.035 4.63916 11.7148 4.63916 11.3809Z"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.69556 13.9377C1.89824 15.7506 3.37119 17.1883 5.19286 17.2764C6.45179 17.3368 7.73716 17.3683 9.13331 17.3683C10.5295 17.3683 11.8148 17.3368 13.0738 17.2764C14.8954 17.1883 16.3684 15.7493 16.5711 13.9377C16.7083 12.7166 16.8203 11.4664 16.8203 10.1924C16.8203 8.91712 16.707 7.667 16.5711 6.44584C16.3684 4.63298 14.8954 3.19529 13.0738 3.10716C11.8148 3.04673 10.5295 3.01526 9.13331 3.01526C7.73716 3.01526 6.45179 3.04673 5.19286 3.10716C3.37119 3.19529 1.89824 4.63424 1.69556 6.44584C1.55833 7.667 1.44629 8.91712 1.44629 10.1924C1.44629 11.4664 1.55959 12.7178 1.69556 13.9377Z"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5415 1.63171V4.77904"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.4619 1.63171V4.77904"
                    stroke="#828282"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <FlatPickr
                className="w-100 border-0 outline-0 no-outline"
                style={{ paddingLeft: "12px" }}
                role="button  "
                placeholder="Expected Exam Date"
                options={{ minDate: new Date(), enableTime: false }}
                onChange={([date]) => {
                  setscheduledDate(moment(date).format("YYYY-MM-DD"));
                }}
              />
              {/* <input
                value={scheduledDate}
                onChange={(e) => {
                  setscheduledDate(e.target.value);
                }}
                className="form-control no-outline border-0"
                placeholder="Expected Exam Date"
                type="date"
              /> */}
            </div>
          </div>
        </div>
        <button
          className={`position-relative w-100 updated-btn border-0 mt-3`}
          onClick={!signupApiCalled ? handleSubmit : () => {}}
        >
          <span
            className={`fw-bolder loading-text small ${
              signupApiCalled ? "hide" : ""
            }`}
          >
            Register
          </span>
          {signupApiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>
        {/* <button
          className="btn updated-btn w-100 mt-3 "
          onClick={handleSubmit}
          disabled={signupApiCalled}
        >
          Register
        </button> */}
        <div className="d-flex align-items-center gap-3 my-3">
          <hr className="w-50" />
          <p className="m-0">or</p>
          <hr className="w-50" />
        </div>
        <div className={`w-100`}>
          <a
            href={`${process.env.REACT_APP_BASE_URL}/auth/google`}
            className={`position-relative btn w-100 h-48 border border-secondary google-btn`}
          >
            <span className={`fw-bolder loading-text small`}>
              <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                <img src={GoogleIcon} className="h-50 mx-2" />
                <span className="fw-semibold text-gray2 text-nowrap">
                  Continue with Google
                </span>
              </div>
            </span>
          </a>
        </div>
        <p className="mt-3">
          Don’t have an account?{" "}
          <span
            className="text-purple fw-semibold text-decoration-underline"
            style={{ cursor: "pointer" }}
            onClick={togglePanel}
          >
            Login
          </span>
        </p>
      </motion.div>
      {/* <div
      className={`${styles.formContainer} ${
        styles.registerContainer
      } px-5 px-md-0 ${!rightPanelActive ? "d-none" : "d-block"} pb-5`}
    >      
      <div className={`${styles.form} login-wrapper`}>
        <img src={Logo} className="w-25 my-4 login-logo" />
        <div className={styles.buttonInputGroup}>
          <div className="w-100">
            <input
              type="text"
              className="w-100 primary-input form-control mb-3 login-input"
              placeholder="Full Name"
              required
              onChange={(e) => {
                setname(e.target.value);
              }}
            />
          </div>
          <div className="w-100">
            <input
              type="email"
              className="w-100 primary-input form-control mb-3 login-input"
              placeholder="Email"
              required
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
          </div>
          <div className="w-100">
            <input
              className="w-100 primary-input form-control mb-3 login-input"
              type="password"
              placeholder="Password"
              required
              onChange={(e) => setpassword(e.target.value)}
            />
          </div>
          <div className="w-100">
            <Flatpickr
              className="w-100 primary-input login-input"
              style={{ paddingLeft: "12px" }}
              role="button  "
              placeholder="Expected Exam Date"
              options={{ minDate: new Date(), enableTime: false }}
              onChange={([date]) => {
                setScheduledDate(moment(date).format("YYYY-MM-DD"));
              }}
            />
          </div>
          <div className={`mt-4 w-100`}>
            <button
              className={`position-relative btn secondary-btn w-100 login-btn`}
              style={{ height: "50px" }}
              onClick={!signupApiCalled && handleSubmit}
            >
              <span
                className={`fw-bolder loading-text small ${
                  signupApiCalled ? "hide" : ""
                }`}
              >
                Regsiter
              </span>
              {signupApiCalled && (
                <div className="loading-4 loading">
                  <span className="span1"></span>
                  <span className="span2"></span>
                  <span className="span3"></span>
                </div>
              )}
            </button>
          </div>

          <div className={styles.socialsWrapper}>
            <header className="w-50 m-auto my-4">
              <h2 className="text-white">or</h2>
            </header>
          </div>
        </div>
        <div className={`w-100`}>
          <a
            href={`${process.env.REACT_APP_BASE_URL}/auth/google`}
            className={`position-relative btn dark-btn w-100 login-btn`}
          >
            <span className={`fw-bolder loading-text small`}>
              <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                <img src={GoogleIcon} className="h-75 mx-2" />
                <span className="fw-bolder text-nowrap">
                  Continue with Google
                </span>
              </div>
            </span>
          </a>
        </div>
        <div className="d-block d-md-none">
          <p className="text-white mt-4 small">
            Already have an account?{" "}
            <a
              className="text-white"
              onClick={togglePanel}
              href="#"
              style={{ cursor: "pointer", textDecoration: "none" }}
            >
              Login here
            </a>
          </p>
        </div>
        {message && (
          <div
            className="px-3 pb-5 mb-5 mb-md-0 pb-md-0 position-absolute reg-msg"
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Message message={message} setmessage={setmessage} />
          </div>
        )}
      </div>
    </div> */}
    </>
  );
}
