import React, { useState } from "react";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";
import Search from "../Search";

export default function AddQuestions() {
  const [questions, setquestions] = useState([]);
  const [currQuestion, setcurrQuestion] = useState("");
  const [currAnswer, setcurrAnswer] = useState("");
  const [questionsCopy, setquestionsCopy] = useState([]);

  const handleSubmitQuestion = () => {
    if (questions.length < 3 && currQuestion && currAnswer) {
      setcurrQuestion("");
      setcurrAnswer("");
      setquestions([
        ...questions,
        { question: currQuestion, answer: currAnswer },
      ]);
      setquestionsCopy([
        ...questions,
        { question: currQuestion, answer: currAnswer },
      ]);
    }
  };

  const filterQuestions = (currentSearchValue) => {
    setquestions(
      questionsCopy.filter(
        (question) =>
          question.question
            .toLowerCase()
            .includes(currentSearchValue.toLowerCase()) ||
          question.answer
            .toLowerCase()
            .includes(currentSearchValue.toLowerCase())
      )
    );
  };

  return (
    <div>
      <div className="question-bank-wrapper">
        <input
          type="text"
          placeholder="ADD QUESTION..."
          className="form-control mt-4 primary-input modal-input form-control-lg lg-input-placeholder"
          value={currQuestion}
          onChange={(e) => setcurrQuestion(e.target.value)}
        />
        <input
          type="text"
          placeholder="ADD ANSWER..."
          className="form-control mt-4 primary-input modal-input form-control-lg lg-input-placeholder"
          value={currAnswer}
          onChange={(e) => setcurrAnswer(e.target.value)}
          onKeyDown={(e) => handleEnterKeyPress(e, handleSubmitQuestion)}
        />
        <div className="d-flex justify-content-between mt-4 gap-3 gap-lg-4 flex-wrap flex-lg-nowrap">
          <button
            className={`btn secondary-btn fw-bolder text-uppercase loading-button lg-btn`}
            style={{ minHeight: "50px" }}
            onClick={handleSubmitQuestion}
          >
            <span className={`loading-text fw-bolder small`}>Enter</span>
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          </button>

          <button
            className={`position-relative btn btn-import w-100 text-uppercase fw-bolder lg-btn`}
          >
            <div className={`loading-text`}>
              <input
                role="button"
                type="file"
                className="position-absolute h-100 w-100"
                style={{ top: 0, left: 0, opacity: 0 }}
              />
              import excel file
            </div>
          </button>
        </div>
      </div>
      <div className="container">
        <div className="database-search-main-wrapper my-5 mx-md-5 px-4">
          <Search
            placeholder={"SEARCH QUESTION OR ANSWER..."}
            setquestions={setquestions}
            questionsCopy={questionsCopy}
            filterQuestions={filterQuestions}
          />
        </div>

        {questions.length > 0 ? (
          <table
            className={`table table-bordered table-responsive questions-table text-white`}
          >
            <thead>
              <tr>
                <th scope="col">Question</th>
                <th scope="col">Answer</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question, index) => (
                <tr key={index}>
                  <td>
                    <p className="text-start text-wrap text-break">
                      {question.question}
                    </p>
                  </td>
                  <td>
                    <p className="text-start text-wrap text-break">
                      {question.answer}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="mt-5">
            <h6 className="text-uppercase text-secondary">
              ADD QUESTION AND ANSWER ABOVE
            </h6>
          </div>
        )}
      </div>
    </div>
  );
}
