import React from "react";
import { Link } from "react-router-dom";

const Main = () => {
  return (
    <div className="mb-5">
      <Link
        className="nav-logo fw-bolder h4 m-0 p-0 text-decoration-none position-absolute login-logo"
        to="/"
        style={{ zIndex: 2 }}
      >
        <svg
          width="79"
          height="21"
          viewBox="0 0 104 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.575439 31.7999L6.45009 0.106079H19.4426L18.2977 6.28278H11.8384L10.9038 11.325H17.0309L15.8994 17.4296H9.77223L7.10861 31.7999H0.575439ZM15.3766 31.7999L21.2512 0.106079H27.526L21.6514 31.7999H15.3766ZM24.235 31.7999L30.1098 0.106079H36.7906L36.9665 15.2867L39.7803 0.106079H46.0551L40.1805 31.7999H33.832L33.521 15.953L30.5838 31.7999H24.235ZM43.0595 31.7999L48.9341 0.106079H57.7374C60.0996 0.106079 61.7114 0.670326 62.5726 1.79882C63.4484 2.91531 63.647 4.76411 63.1685 7.34524L62.9115 8.73185C62.6356 10.2205 62.1352 11.427 61.4101 12.3514C60.6974 13.2758 59.7546 13.8821 58.5817 14.1702C59.9789 14.5304 60.8001 15.3767 61.0454 16.7093C61.3049 18.0299 61.2579 19.6446 60.9041 21.5535C60.5236 23.6063 59.994 25.401 59.3156 26.9378C58.6369 28.4744 57.7019 29.669 56.5104 30.5214C55.319 31.3737 53.7698 31.7999 51.8627 31.7999H43.0595ZM53.0697 12.0453H54.3985C55.0013 12.0453 55.4314 11.8172 55.6882 11.361C55.945 10.9048 56.1323 10.3586 56.2503 9.72227L56.8409 6.53487C57.0301 5.51444 56.6633 5.00421 55.7407 5.00421H54.3749L53.0697 12.0453ZM51.0888 26.2174C52.7745 26.2174 53.7618 25.4371 54.0512 23.8765L54.7855 19.9147C54.9524 19.0143 54.9421 18.306 54.7548 17.7898C54.582 17.2616 54.1144 16.9975 53.3515 16.9975H52.1519L50.4496 26.1814C50.7158 26.2056 50.9287 26.2174 51.0888 26.2174ZM60.8503 31.7999L66.7251 0.106079H72.9998L67.1251 31.7999H60.8503ZM72.4956 31.7999L77.2422 6.19274H73.3851L74.5131 0.106079H88.7237L87.5955 6.19274H83.7383L78.992 31.7999H72.4956ZM84.6576 31.7999L90.5323 0.106079H103.525L102.39 6.22874H96.0046L94.8595 12.4054H100.987L99.8818 18.366H93.7548L92.4097 25.6233H99.2196L98.0747 31.7999H84.6576Z"
            fill="url(#paint0_linear_811_1508)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_811_1508"
              x1="-70.2194"
              y1="10.4729"
              x2="-42.47"
              y2="74.7668"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#B39FFF" />
              <stop offset="1" stop-color="#6A1ED2" />
            </linearGradient>
          </defs>
        </svg>
      </Link>
      <div className="container d-flex flex-column justify-content-center align-items-center min-vh-100 gap-5 mt-5 pt-5 mt-xxl-0 pt-xxl-0">
        <h2 className="font-jakarta fw-bold">
          Hello, welcome to Finbite. Please make your selection
        </h2>
        <div
          className="d-flex justify-content-xxl-between justify-content-center w-100 flex-wrap flex-lg-nowrap"
          style={{
            gap: "70px",
          }}
        >
          <div className="d-flex flex-column gap-4 main-page-img text-decoration-none text-dark">
            <div>
              <Link
                to={"http://shop.finbite.in"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  borderRadius: "100px",
                  maxWidth: "90vw",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    boxShadow: "0px 4px 96px 0px rgba(0,0,0,0.25)",
                    overflow: "hidden",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={require("../assets/images/main/6.png")}
                    style={{ width: "100%", maxWidth: "560px" }}
                  />
                </div>
                <h4 className="font-jakarta fw-bold">
                  Client Management Portal
                </h4>
              </Link>
            </div>
          </div>
          <div className="d-flex flex-column gap-4 main-page-img text-decoration-none text-dark">
            <div>
              <Link
                to={"/revision-tool"}
                style={{
                  textDecoration: "none",
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  borderRadius: "100px",
                  maxWidth: "90vw",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    boxShadow: "0px 4px 96px 0px rgba(0,0,0,0.25)",
                    overflow: "hidden",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={require("../assets/images/main/2.png")}
                    style={{ width: "100%" }}
                  />
                </div>
                <h4 className="font-jakarta fw-bold">
                  Student Exam Revision Tool
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
