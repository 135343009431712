import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/UserContext";

function RequireAuth({
  children,
  redirectTo,
  noPlanChildren,
  allowNoPlan,
  expiredRedirect,
}) {
  const { currentUser } = useContext(AuthContext);

  return currentUser == null ? (
    <Navigate to={redirectTo} />
  ) : currentUser.plan.plan == "expired" && expiredRedirect ? (
    <Navigate to={"/dashboard"} />
  ) : currentUser.plan.plan == null && !allowNoPlan ? (
    <Navigate to={noPlanChildren} />
  ) : (
    children
  );
}

export default RequireAuth;
