import React, { useState } from "react";
import Message from "../Message";
import { updatePassword as updatePasswordApi } from "../../api/user";

export default function UpdatePassword() {
  const [currentPassword, setcurrentPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [updatePasswordApiCalled, setupdatePasswordApiCalled] = useState(false);
  const [message, setmessage] = useState("");
  const handleUpdatePassword = () => {
    if (currentPassword && newPassword) {
      setupdatePasswordApiCalled(true);
      updatePasswordApi({ currentPassword, newPassword })
        .then((res) => {
          setmessage({
            message: "Password updated successfully",
            success: true,
          });
          setupdatePasswordApiCalled(false);
        })
        .catch((err) => {
          setmessage({ message: err, success: false });
          setupdatePasswordApiCalled(false);
        });
    }
  };

  return (
    <div className="d-flex flex-column gap-4">
      <div>
        <p className="text-start text-white">Current Password</p>
        <input
          type="password"
          className="form-control primary-input"
          value={currentPassword}
          onChange={(e) => setcurrentPassword(e.target.value)}
          placeholder="CURRENT PASSWORD..."
        />
      </div>
      <div className="mb-3">
        <p className="text-start text-white">New Password</p>
        <input
          type="password"
          className="form-control primary-input"
          value={newPassword}
          onChange={(e) => setnewPassword(e.target.value)}
          placeholder="NEW PASSWORD..."
        />
      </div>
      <div>
        <button
          className={`position-relative btn secondary-btn w-100`}
          style={{ height: "50px" }}
          onClick={handleUpdatePassword}
        >
          <span
            className={`fw-bolder loading-text ${
              updatePasswordApiCalled ? "hide" : ""
            }`}
          >
            Update Password
          </span>
          {updatePasswordApiCalled && (
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          )}
        </button>

        {message && (
          <div className="pt-4">
            <Message message={message} setmessage={setmessage} />
          </div>
        )}
      </div>
    </div>
  );
}
