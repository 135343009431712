import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { deleteUser } from "../../api/user";
import DownloadIcon from "../../assets/images/icons/download.svg";
import DeleteCrossIcon from "../../assets/images/icons/delete-account-modal/delete-cross.svg";

export default function ImportFileModal({
  open,
  onCancel,
  onConfirm,
  addQuestionApiCalled,
  setfile,
}) {
  return (
    <Modal centered isOpen={open} className="delete-modal">
      <ModalBody className="mt-0">
        <p
          className="fw-bold text-white w-100 text-end"
          role="button"
          onClick={onCancel}
          style={{ right: 0, position: "relative", height: "18px" }}
        >
          <img src={DeleteCrossIcon} className="h-100" />
        </p>

        <div className="m-auto w-100 text-center d-flex justify-content-center align-items-center gap-2">
          <img src={DownloadIcon} style={{ height: "25px" }} />
          <a href="Import_Template.xlsx" download>
            Download Template
          </a>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="text-center mx-5">
          <input
            role="button"
            type="file"
            className="h-100 w-100 custom-file-input"
            onChange={(e) => setfile(e.target.files[0])}
          />
        </div>
        <div className="d-flex gap-5 w-100 mt-4">
          <button
            className={`position-relative btn secondary-btn`}
            style={{ height: "50px" }}
            onClick={onCancel}
          >
            <span className={`fw-bolder loading-text`}>Cancel</span>
          </button>

          <button
            className={`position-relative btn w-100 btn-import`}
            onClick={onConfirm}
          >
            <span
              className={`fw-bolder loading-text ${
                addQuestionApiCalled ? "hide" : ""
              }`}
            >
              Import File
            </span>
            {addQuestionApiCalled && (
              <div className="loading-4 loading">
                <span className="span1"></span>
                <span className="span2"></span>
                <span className="span3"></span>
              </div>
            )}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
