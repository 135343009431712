import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Message from "../components/Message";
import { resetPassword as resetPasswordApi } from "../api/user";

export default function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();

  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [message, setmessage] = useState("");
  const [apiCalled, setapiCalled] = useState(false);

  useEffect(() => {
    if (!params.resetToken || !params.userId) {
      navigate("/dashboard");
    }
  }, []);

  const handlePasswordReset = () => {
    if (password && confirmPassword) {
      if (password != confirmPassword) {
        setmessage({ message: "passwords donot match" });
      } else {
        setapiCalled(true);
        resetPasswordApi({
          userId: params.userId,
          token: params.resetToken,
          password: password,
        })
          .then((res) => {
            setapiCalled(false);
            navigate("/user/#success");
          })
          .catch((err) => {
            setapiCalled(false);
            navigate("/user/#failed");
          });
      }
    }
  };

  return (
    <div className="container vh-100 d-flex flex-column justify-content-center">
      <div className="w-75 m-auto">
        <input
          type="password"
          className="form-control my-4 primary-input"
          placeholder="NEW PASSWORD..."
          value={password}
          onChange={(e) => setpassword(e.target.value)}
        />
        <input
          type="password"
          className="form-control my-4 primary-input"
          placeholder="CONFIRM NEW PASSWORD..."
          value={confirmPassword}
          onChange={(e) => {
            setconfirmPassword(e.target.value);
          }}
        />
        <button
          className={`btn secondary-btn fw-bolder text-uppercase loading-button lg-btn ${
            apiCalled ? "activeLoading" : ""
          }`}
          style={{ minHeight: "50px" }}
          onClick={() => {
            if (!apiCalled) handlePasswordReset();
          }}
        >
          <span
            className={`loading-text fw-bolder small ${
              apiCalled ? "hide" : ""
            }`}
          >
            Reset Password
          </span>
          <div className="loading-4 loading">
            <span className="span1"></span>
            <span className="span2"></span>
            <span className="span3"></span>
          </div>
        </button>

        {/* <button
          className={`btn secondary-btn fw-bolder btn-lg text-uppercase loading-button lg-btn ${
            apiCalled ? "activeLoading" : ""
          }`}
          onClick={() => {
            if (!apiCalled) handlePasswordReset();
          }}
        >
          <span className={`loading-text ${apiCalled ? "hide" : ""}`}>
            Reset Password
          </span>
          <div className="loading-4 loading">
            <span className="span1"></span>
            <span className="span2"></span>
            <span className="span3"></span>
          </div>
        </button> */}
      </div>
    </div>
  );
}
