import React, { useState } from "react";
import { addTodo as addTodoApi } from "../../api/todos";
import handleEnterKeyPress from "../../utils/handleEnterKeyPress";

export default function AddTodoInput({
  addTaskApiCalled,
  setaddTaskApiCalled,
  setmessage,
  getAllTasks,
  tasks,
  settasks,
  setcompleteTasksArray,
  setincompleteTasksArray,
}) {
  const [taskText, settaskText] = useState("");
  const handleAddTask = () => {
    if (taskText && !addTaskApiCalled) {
      const tasksCopy = [
        ...tasks,
        {
          color: "pink",
          completed: false,
          checked: false,
          subTasks: [],
          text: taskText,
        },
      ];
      setcompleteTasksArray(tasksCopy.filter((tas) => tas.completed == true));
      setincompleteTasksArray(
        tasksCopy.filter((tas) => tas.completed == false)
      );

      settaskText("");
      setaddTaskApiCalled(true);
      addTodoApi({ text: taskText })
        .then((res) => {
          settasks(tasksCopy);
          tasksCopy[tasksCopy.length - 1]._id = res._id;
          setaddTaskApiCalled(false);
        })
        .catch((err) => {
          tasksCopy.pop();
          setcompleteTasksArray(
            tasksCopy.filter((tas) => tas.completed == true)
          );
          setincompleteTasksArray(
            tasksCopy.filter((tas) => tas.completed == false)
          );
          settasks(tasksCopy);
          setmessage({ message: err, success: false });
          settaskText("");
          setaddTaskApiCalled(false);
        });
    }
  };

  return (
    <div className="position-relative d-flex justify-content-end align-items-center">
      <div
        className="position-absolute mx-3"
        role="button"
        onClick={handleAddTask}
      >
        <svg
          className="send-arrow-icon"
          width="25"
          height="25"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.5 33C26.0604 33 33 26.0604 33 17.5C33 8.93959 26.0604 2 17.5 2C8.93959 2 2 8.93959 2 17.5C2 26.0604 8.93959 33 17.5 33Z"
            stroke-width="2.56"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.438 23.3105L25.2496 17.4989L19.438 11.6873"
            stroke-width="2.56"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M25.25 17.5H9.75"
            stroke-width="2.56"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <input
        type="text"
        className={`form-control form-control-lg p-2 main-input px-4`}
        placeholder="Add Tasks..."
        value={taskText}
        onChange={(e) => settaskText(e.target.value)}
        onKeyDown={(e) => {
          handleEnterKeyPress(e, handleAddTask);
        }}
      />
    </div>
  );
}
