import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddSectionModal from "../components/modals/AddSectionModal";
import {
  getSingleSubject as getSingleSubjectApi,
  getSingleChapter as getSingleChapterApi,
  getSections as getSectionsApi,
} from "../api/questionBank";

export default function ViewSections() {
  const params = useParams();
  const navigate = useNavigate();
  const [subject, setsubject] = useState({
    id: 1,
    name: "subject1",
  });
  const [chapter, setchapter] = useState({
    _id: 1,
    subId: 1,
    name: "chap1",
  });
  const [sections, setsections] = useState([]);

  const [addSectionModalOpen, setaddSectionModalOpen] = useState(false);

  const getSubject = () => {
    getSingleSubjectApi({ subjectId: params.subId })
      .then((res) => {
        setsubject(res);
      })
      .catch((err) => {});
  };

  const getChapter = () => {
    getSingleChapterApi({ chapterId: params.chapId })
      .then((res) => {
        setchapter(res);
      })
      .catch((err) => {});
  };

  const getSections = () => {
    getSectionsApi({ chapterId: params.chapId })
      .then((res) => {
        setsections(res);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSubject();
    getChapter();
    getSections();
  }, []);

  return (
    <div className="container">
      {addSectionModalOpen && (
        <AddSectionModal
          subject={subject}
          chapter={chapter}
          open={addSectionModalOpen}
          onCancel={() => {
            setaddSectionModalOpen(false);
          }}
          onConfirm={() => {
            setaddSectionModalOpen(false);
            getSections();
          }}
        />
      )}

      <div>
        {/* <p className="text-start h4">Your subjects</p> */}
        <div className="text-end mb-3">
          <button
            className="btn btn-primary w-25"
            onClick={() => setaddSectionModalOpen(true)}
          >
            Add section
          </button>
        </div>
        <table className="table table-bordered questions-table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            {sections.map((section, index) => (
              <tr>
                <td scope="row">{index + 1}</td>
                <td className="">{section.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
