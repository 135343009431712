import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { upload as uploadApi } from "../api/blog";
import { AuthContext } from "../contexts/UserContext";
import Message from "../components/Message";
import { motion } from "framer-motion";

export default function Blog() {
  const [files, setfiles] = useState([]);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [uploadApiCalled, setuploadApiCalled] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [message, setmessage] = useState("");
  const navigate = useNavigate();

  const handleFileChange = (filesObj) => {
    for (let i = 0; i < filesObj.length; i++) {
      setfiles((file) => [...file, filesObj[i]]);
    }
  };

  const handleBlogUpload = () => {
    if (title) {
      setuploadApiCalled(true);
      uploadApi({ files: files, title, description })
        .then((res) => {
          setuploadApiCalled(false);
          window.location.reload();
        })
        .catch((err) => {
          setuploadApiCalled(false);
          setmessage({ message: err, success: false });
        });
    } else {
      setmessage({ message: "Enter Valid Title", success: false });
    }
  };

  const handleClearAllFiles = () => {
    setfiles([]);
  };

  const handleRemoveSingleFile = (index) => {
    setfiles(files.filter((file, i) => i != index));
  };

  useEffect(() => {
    if (!currentUser?.isAdmin) {
      navigate("/dashboard");
    }
  }, [currentUser]);

  return (
    <div>
      {message && <Message message={message} setmessage={setmessage} />}
      <motion.div
        className="d-flex flex-column gap-3 position-relative"
        initial={{ opacity: 0, top: "20vh" }}
        animate={{
          opacity: 1,
          top: "0vh",
        }}
        transition={{ duration: 0.3, delay: 0.1 }}
      >
        <input
          type="text"
          className="primary-input px-3"
          placeholder="TITLE..."
          style={{ borderRadius: "10px" }}
          value={title}
          onChange={(e) => settitle(e.target.value)}
        />
        <textarea
          className="primary-input px-3 pt-2"
          placeholder="DESCRIPTION..."
          style={{ borderRadius: "10px", height: "150px" }}
          value={description}
          onChange={(e) => setdescription(e.target.value)}
        />
        <div className="text-start text-white">
          <div className="d-flex justify-content-between align-items-center mt-4">
            <p className="h4 fw-bolder">Files ({files.length})</p>
            {files.length > 0 && (
              <p role="button" onClick={handleClearAllFiles}>
                Clear All
              </p>
            )}
          </div>
          {files.map((file, index) => (
            <div className="d-flex justify-content-between px-3 my-2">
              <p className="text-white text-start text-break">{file.name}</p>
              <p role="button" onClick={() => handleRemoveSingleFile(index)}>
                x
              </p>
            </div>
          ))}
        </div>
        <div className="d-flex gap-3">
          <button
            className={`position-relative btn upload-btn w-100 text-uppercase fw-bolder lg-btn`}
          >
            <input
              role="button"
              type="file"
              multiple
              className="position-absolute h-100 w-100"
              onChange={(e) => handleFileChange(e.target.files)}
              style={{ top: 0, left: 0, opacity: 0 }}
            />
            Upload Files
          </button>
          <button
            className={`btn secondary-btn fw-bolder text-uppercase loading-button lg-btn ${
              uploadApiCalled ? "activeLoading" : ""
            }`}
            style={{ minHeight: "50px" }}
            onClick={!uploadApiCalled && handleBlogUpload}
          >
            <span
              className={`loading-text fw-bolder small ${
                uploadApiCalled ? "hide" : ""
              }`}
            >
              POST
            </span>
            <div className="loading-4 loading">
              <span className="span1"></span>
              <span className="span2"></span>
              <span className="span3"></span>
            </div>
          </button>
        </div>
      </motion.div>
    </div>
  );
}
